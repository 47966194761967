<template>
  <component :is="asLink && !disabled ? 'router-link' : 'button'" :to="url" :disabled="disabled" @click="goToOrRun">
    <div
      class="group text-sm flex-shrink-0 rounded flex gap-1 p-2 items-center transition-colors duration-150 font-medium cursor-pointer"
      :class="{
        'text-gray-700': !isRouteActive,
        'text-primary': isRouteActive,
        'opacity-60 cursor-not-allowed': disabled,
        'hover:text-primary': !disabled,
        'w-full': fullWidth,
        'bg-gray-300 bg-opacity-30 rounded-lg': selected,
        'border-b pb-3 mb-2': bordered
      }"
    >
      <span
        v-if="hasIcon"
        class="rounded-xl w-7 flex-shrink-0"
        :class="{
          'text-primary': isRouteActive,
          'text-gray-500 opacity-60': !isRouteActive,
          'group-hover:text-primary group-hover:opacity-90': !disabled
        }"
      >
        <slot name="svg">
          <component :is="'fw-icon-' + icon" class="w-6 h-6" />
        </slot>
      </span>
      <span class="group-opacity-100 text-left flex-1 flex-shrink-0" :class="[`text-${size}`]">
        <v-clamp v-if="truncateLabel" autosize :max-lines="1">{{ label }}</v-clamp>
        <span v-else>{{ label }}</span>
      </span>
      <span
        v-if="counter"
        class="text-xs px-1.5 py-0.5 rounded-full flex-shrink-0 min-w-7 text-center font-bold"
        :class="{
          'bg-primary text-white': counterType === 'primary',
          'bg-gray-500 bg-opacity-20 text-gray-500': counterType === 'light',
          'bg-gray-500 bg-opacity-10 text-gray-500': counterType === 'xlight'
        }"
      >
        {{ counter }}
      </span>
      <span v-if="$slots.suffix" class="flex-shrink-0"><slot name="suffix"></slot></span>
    </div>
  </component>
</template>

<script>
export default {
  props: {
    to: {
      type: [String, Object],
      default: ''
    },
    asLink: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'sm'
    },
    icon: {
      type: String,
      default: null
    },
    exact: {
      type: Boolean,
      default: false
    },
    checkActualRoute: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: true
    },
    counter: {
      //String or number
      type: [String, Number],
      default: null
    },
    counterType: {
      type: String,
      default: 'primary'
    },
    truncateLabel: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    hasIcon() {
      return this.icon || this.$slots.svg
    },

    url() {
      return typeof this.to === 'object' && !Array.isArray(this.to) && this.to !== null
        ? this.$router.resolve(this.to).href
        : this.to
    },

    isRouteActive() {
      return this.active || (this.checkActualRoute && this.$router.currentRoute.path == this.url)
    }
  },

  methods: {
    goToOrRun() {
      if (this.disabled) return
      if (this.url && !this.asLink) this.$router.push(this.url)
    }
  }
}
</script>
