<template>
  <SidebarBase>
    <template #main>
      <div class="flex-1">
        <ButtonSidebar to="/" :label="$t('home')">
          <template #svg>
            <fw-icon-home class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar to="/explore" :label="$t('explore')">
          <template #svg>
            <fw-icon-search class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar to="/communities" :label="$t('my_communities')">
          <template #svg>
            <fw-icon-team class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar to="/documents" :exact="true" :label="$t('documents')">
          <template #svg>
            <fw-icon-file class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar to="/about" :exact="true" :label="$t('about')">
          <template #svg>
            <fw-icon-question class="w-6 h-6" />
          </template>
        </ButtonSidebar>
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'

export default {
  components: {
    ButtonSidebar,
    SidebarBase,
  },
  data() {
    return {
      debugMode: Boolean(localStorage.getItem('fw-debug') || localStorage.getItem('fw-debug')),
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
  },
}
</script>

<style>
a span:last-child {
  @apply text-black;
}
</style>

<i18n>
  {
    "en": {
      "home": "Home",
      "explore": "Explore",
      "my_communities": "My Communities",
      "documents": "Documents",
      "about": "About"
    },
    "pt": {
      "home": "Início",
      "explore": "Explorar",
      "my_communities": "Comunidades",
      "documents": "Documentos",
      "about": "Sobre"
    }
  }
</i18n>
