const LOWER_CHARS = 'abcdefghijklmnopqrstuvwxyz0123456789'
const LOWER_CHARS_LEN = LOWER_CHARS.length

const TRANSLATE_INP = 'àáâãäåæÆçèéêëƒìíîïñðòóôõöšýùúüûž'
const TRANSLATE_OUT = 'aaaaaaaaceeeefiiiinoooooosyyuuuz'
const ASCII_MAP = {}
for (let i = 0; i < TRANSLATE_INP.length; i++) {
  ASCII_MAP[TRANSLATE_INP[i]] = TRANSLATE_OUT[i]
}
const ASCII_REGEX = new RegExp(Object.keys(ASCII_MAP).join('|'), 'g')

const EXTERNAL_VIDEO_DOMAINS = {
  zoom: 'zoom.us',
  meet: 'meet.google.com',
  teams: 'teams.microsoft.com'
}

export const MONTHS = {
  pt: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec']
}

const BYTES_REFERENCES = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

const ERRORS = {
  Required: () => 'Obrigatório',
  MaxStringLength: error => `Defina um texto mais curto (menos de ${error.max_length} caracteres)`,
  InvalidNumber: () => 'Número inválido',
  MinStringLength: error => `Defina um texto mais longo (mais de ${error.min_length} caracteres)`,
  AnonymousNotAllowed: () => 'Esta sala não permite acesso a convidados (sem conta).',
  InvalidCode: () => 'Código inválido',
  CodeAlreadyExists: () => 'Código já definido',
  MaxAttempts: () => 'Foi ultrapassado o limite de tentativas. Por favor, tente mais tarde.',
  InvalidDate: () => `Data inválida`,
  MultipleErrors: () => 'Ocorreram vários erros',
  FormErrors: () => 'Existem erros no formulário',
  NotFound: (error, context = null) => {
    if (context == 'meeting') {
      return 'A sala não existe.'
    } else {
      return 'Não existe.'
    }
  },
  InvalidMimetype: error => 'Tipo de ficheiro inválido. Ficheiros permitidos: ' + error.allowed_mimetype,
  TooManyItems: error => `Demasiados itens. Máximo: ${error.max_items}`,
  InvalidAcademicYear: () => 'Ano letivo inválido',
  AliasAlreadyDefined: () => 'Alias já definido',
  AliasNotAllowed: () => 'Alias não permitido',
  OwnerAlreadyCalled: () => 'O dono já foi chamado',
  AlreadyDefined: () => 'Já definido',
  InvalidNif: () => 'NIF inválido',
  InvalidCC: () => 'CC inválido',
  InvalidPostalCode: () => 'Código postal inválido',
  DomainNotAllowed: () => 'Domínio não permitido',
  NotImplementedError: () => 'Não implementado',
  RequestTimeout: () => 'Tempo de espera excedido',
  InvalidBoolean: () => 'Valor booleano inválido',
  InvalidPhoneNumber: () => 'Número de telefone inválido',
  InvalidProtocol: () => 'Protocolo inválido',
  InvalidPassportNumber: () => 'Número de passaporte inválido',
  ClientDisconnected: () => 'Cliente desconectado',
  InvalidCountry: () => 'País inválido',
  InvalidSum: () => 'Soma inválida',
  UserAlreadyExists: () => 'Utilizador já existe',
  AppVersionNotAllowed: () => 'Versão da aplicação não permitida',
  FileNotComplete: () => 'Ficheiro incompleto',
  FileAlreadyInUse: () => 'Ficheiro já em uso',
  FileTypeDontMatch: () => 'Tipo de ficheiro não corresponde',
  ConnectionAuthAlreadyDefined: () => 'Autenticação já definida',
  InvalidConnectionID: () => 'ID de ligação inválido',
  UserNotFound: () => 'Utilizador não encontrado',
  Error: () => 'Ocorreu um erro',
  Unauthorized: () => 'Não autorizado',
  Forbidden: () => 'Acesso negado',
  NotAllowed: () => 'Não permitido',
  InternalServerError: () => 'Erro interno do servidor',
  ServerUnavailable: () => 'Servidor indisponível',
  ItemNotFound: () => 'Item não encontrado',
  KeysNotFound: () => 'Chaves não encontradas',
  InvalidWSCode: () => 'Código de websockets inválido',
  InvalidKey: () => 'Chave inválida',
  InvalidKeys: () => 'Chaves inválidas',
  KeyAlreadyExists: () => 'Chave já definida',
  InvalidStatus: () => 'Estado inválido',
  InvalidLanguage: () => 'Idioma inválido',
  InvalidJSONPayload: () => 'Payload JSON inválido',
  InvalidJSONDict: () => 'Dicionário JSON inválido',
  InvalidJSONList: () => 'Lista JSON inválida',
  InvalidDict: () => 'Dicionário inválido',
  InvalidList: () => 'Lista inválida',
  InvalidString: () => 'Texto inválido',
  EmptyDictKey: () => 'Chave em falta no dicionário',
  InvalidOption: () => 'Opção inválida',
  InvalidOptions: error => 'Opções inválidas: ' + error.invalid,
  InvalidEmail: () => 'Email inválido',
  InvalidTime: () => 'Hora inválida',
  InvalidType: () => 'Tipo inválido',
  EmptyPayload: () => 'Payload vazia',
  InvalidInt: () => 'Número inteiro inválido',
  MissingDictKeys: () => 'Chaves em falta no dicionário',
  NotActive: () => 'Não ativo',
  MinOptions: error => {
    return `Defina mais opções (mínimo ${error.min})`
  },
  MaxOptions: error => {
    return `Defina menos opções (máximo ${error.max})`
  },
  MinNumber: error => {
    return `O número tem de ser maior ou igual a ${error.min_number}`
  },
  OneNumberRequired: () => {
    return `Defina pelo menos um número`
  },
  ThreeLettersRequired: () => {
    return `Defina pelo menos três letras`
  },
  PasswordNotMatching: () => {
    return `As palavras-passe não coincidem`
  },
  PasswordEqualToEmail: () => {
    return `A palavra-passe não pode ser igual ao email`
  },
  MustBeLower: () => {
    return `A palavra-passe tem de conter letras minúsculas`
  },
  PasswordWithEmailWords: () => {
    return `A palavra-passe não pode conter palavras do seu email`
  },
  MaxNumber: error => {
    return `O número tem de ser menor ou igual a ${error.max_number}`
  },
  MinDate: error => {
    return `A data tem de ser maior ou igual a ${error.min_date}`
  },
  MaxDate: error => {
    return `A data tem de ser menor ou igual a ${error.min_date}`
  },
  MinTime: error => {
    return `O horário tem de ser maior que ${error.min_time}`
  },
  MaxTime: error => {
    return `O horário tem de ser menor que ${error.max_time}`
  },
  DateCannotBe: error => {
    return `A data não pode ser ${error.date}`
  },
  DateCannotBeInRange: error => {
    return `A data não pode estar entre ${error.start_date} e ${error.end_date}`
  }
}

const ERRORS_EN = {
  Required: () => 'Required',
  MaxStringLength: error => `Define a shorter text (less than ${error.max_length} characters)`,
  InvalidNumber: () => 'Invalid number',
  MinStringLength: error => `Define a longer text (more than ${error.min_length} characters)`,
  AnonymousNotAllowed: () => 'This room does not allow guest access (without account).',
  InvalidCode: () => 'Invalid code',
  CodeAlreadyExists: () => 'Code already defined',
  MaxAttempts: () => 'The limit of attempts has been exceeded. Please try again later.',
  InvalidDate: () => `Invalid date`,
  MultipleErrors: () => 'Multiple errors occurred',
  FormErrors: () => 'There are errors in the form',
  NotFound: (error, context = null) => {
    if (context == 'meeting') {
      return 'The room does not exist.'
    } else {
      return 'Not found.'
    }
  },
  InvalidMimetype: error => 'Invalid file type. Allowed files: ' + error.allowed_mimetype,
  TooManyItems: error => `Too many items. Maximum: ${error.max_items}`,
  InvalidAcademicYear: () => 'Invalid academic year',
  AliasAlreadyDefined: () => 'Alias already defined',
  AliasNotAllowed: () => 'Alias not allowed',
  OwnerAlreadyCalled: () => 'The owner has already been called',
  AlreadyDefined: () => 'Already defined',
  InvalidNif: () => 'Invalid NIF',
  InvalidCC: () => 'Invalid CC',
  InvalidPostalCode: () => 'Invalid postal code',
  DomainNotAllowed: () => 'Domain not allowed',
  NotImplementedError: () => 'Not implemented',
  RequestTimeout: () => 'Request timeout',
  InvalidBoolean: () => 'Invalid boolean value',
  InvalidPhoneNumber: () => 'Invalid phone number',
  InvalidProtocol: () => 'Invalid protocol',
  InvalidPassportNumber: () => 'Invalid passport number',
  ClientDisconnected: () => 'Client disconnected',
  InvalidCountry: () => 'Invalid country',
  InvalidSum: () => 'Invalid sum',
  UserAlreadyExists: () => 'User already exists',
  AppVersionNotAllowed: () => 'Application version not allowed',
  FileNotComplete: () => 'File not complete',
  FileAlreadyInUse: () => 'File already in use',
  FileTypeDontMatch: () => 'File type does not match',
  ConnectionAuthAlreadyDefined: () => 'Authentication already defined',
  InvalidConnectionID: () => 'Invalid connection ID',
  UserNotFound: () => 'User not found',
  Error: () => 'An error occurred',
  Unauthorized: () => 'Unauthorized',
  Forbidden: () => 'Access denied',
  NotAllowed: () => 'Not allowed',
  InternalServerError: () => 'Internal server error',
  ServerUnavailable: () => 'Server unavailable',
  ItemNotFound: () => 'Item not found',
  KeysNotFound: () => 'Keys not found',
  InvalidWSCode: () => 'Invalid websockets code',
  InvalidKey: () => 'Invalid key',
  InvalidKeys: () => 'Invalid keys',
  KeyAlreadyExists: () => 'Key already defined',
  InvalidStatus: () => 'Invalid status',
  InvalidLanguage: () => 'Invalid language',
  InvalidJSONPayload: () => 'Invalid JSON payload',
  InvalidJSONDict: () => 'Invalid JSON dictionary',
  InvalidJSONList: () => 'Invalid JSON list',
  InvalidDict: () => 'Invalid dictionary',
  InvalidList: () => 'Invalid list',
  InvalidString: () => 'Invalid text',
  EmptyDictKey: () => 'Key missing in dictionary',
  InvalidOption: () => 'Invalid option',
  InvalidOptions: error => 'Invalid options: ' + error.invalid,
  InvalidEmail: () => 'Invalid email',
  InvalidTime: () => 'Invalid time',
  InvalidType: () => 'Invalid type',
  EmptyPayload: () => 'Empty payload',
  InvalidInt: () => 'Invalid integer',
  MissingDictKeys: () => 'Keys missing in dictionary',
  NotActive: () => 'Not active',
  MinOptions: error => {
    return `Define more options (minimum ${error.min})`
  },
  MaxOptions: error => {
    return `Define fewer options (maximum ${error.max})`
  },
  MinNumber: error => {
    return `The number has to be greater or equal to ${error.min_number}`
  },
  OneNumberRequired: () => {
    return `Define at least one number`
  },
  ThreeLettersRequired: () => {
    return `Define at least three letters`
  },
  PasswordNotMatching: () => {
    return `The passwords do not match`
  },
  PasswordEqualToEmail: () => {
    return `The password cannot be the same as the email`
  },
  MustBeLower: () => {
    return `The password must contain lowercase letters`
  },
  PasswordWithEmailWords: () => {
    return `The password cannot contain words from your email`
  },
  MaxNumber: error => {
    return `The number has to be less or equal to ${error.max_number}`
  },
  MinDate: error => {
    return `The date has to be greater or equal to ${error.min_date}`
  },
  MaxDate: error => {
    return `The date has to be less or equal to ${error.min_date}`
  },
  MinTime: error => {
    return `The time has to be greater than ${error.min_time}`
  },
  MaxTime: error => {
    return `The time has to be less than ${error.max_time}`
  },
  DateCannotBe: error => {
    return `The date cannot be ${error.date}`
  },
  DateCannotBeInRange: error => {
    return `The date cannot be between ${error.start_date} and ${error.end_date}`
  }
}

const FILE_ICONS = {
  mpg4: 'movie',
  mp4: 'movie',
  avi: 'movie',
  mov: 'movie',
  pdf: 'file-pdf',
  ppt: 'file-ppt',
  pptx: 'file-ppt',
  key: 'keynote',
  docx: 'file-word',
  doc: 'file-word',
  xls: 'file-excel',
  xlsx: 'file-excel',
  png: 'image',
  jpeg: 'image',
  jpg: 'image'
}

const COUNTRY_LIST_ALPHA2 = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas (the)',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory (the)',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands (the)',
  CF: 'Central African Republic (the)',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands (the)',
  CO: 'Colombia',
  KM: 'Comoros (the)',
  CD: 'Congo (the Democratic Republic of the)',
  CG: 'Congo (the)',
  CK: 'Cook Islands (the)',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  CI: "Côte d'Ivoire",
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic (the)',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (the) [Malvinas]',
  FO: 'Faroe Islands (the)',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories (the)',
  GA: 'Gabon',
  GM: 'Gambia (the)',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See (the)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea (the Democratic People's Republic of)",
  KR: 'Korea (the Republic of)',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic (the)",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands (the)',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova (the Republic of)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands (the)',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger (the)',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands (the)',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines (the)',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  MK: 'Republic of North Macedonia',
  RO: 'Romania',
  RU: 'Russian Federation (the)',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan (the)',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands (the)',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates (the)',
  GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
  UM: 'United States Minor Outlying Islands (the)',
  US: 'United States of America (the)',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AX: 'Åland Islands'
}

const APP_LABELS = {
  myuc: 'MyUC',
  academic: 'UC Tx St',
  social_support: 'UC SocialSupport',
  analytics: 'UC Analytics',
  tasks: 'UC Tasks',
  forms: 'UC Exams',
  exams: 'UC Exams',
  competitions: 'UC Competitions',
  meetings: 'UC Meetings',
  courses: 'UC Courses',
  apply: 'UC Apply',
  spaces: 'UC Spaces',
  id: 'UC ID',
  buckets: 'Repositórios'
}

export default {
  generateErrorMessage(error, language = 'pt') {
    let errorKey = error.key
    if (language == 'en') {
      return ERRORS_EN[errorKey](error)
    }
    return ERRORS[errorKey](error)
  },
  appLabels() {
    return APP_LABELS
  },
  countryListAlpha2() {
    return COUNTRY_LIST_ALPHA2
  },

  parseXLIFF(xliff) {
    let parser = new DOMParser()
    let doc = parser.parseFromString(xliff, 'application/xml')
    let data = []
    if (doc.getElementsByTagName('xliff').length > 0) {
      let version = doc.getElementsByTagName('xliff')[0].getAttribute('version')
      if (version == '2.0') {
        console.log('xliff 2.0')
        let srcLanguage = doc.getElementsByTagName('xliff')[0].getAttribute('srcLang')
        let trgLanguage = doc.getElementsByTagName('xliff')[0].getAttribute('trgLang')
        //getElementsByTagName file or group
        let files = doc.getElementsByTagName('file')

        for (let i = 0; i < files.length; i++) {
          let segments = []
          let notes = []
          //get file notes
          let fileNotes = files[i].getElementsByTagName('note')
          for (let n = 0; n < fileNotes.length; n++) {
            notes.push(fileNotes[n].textContent)
          }
          //get file id
          let fileId = files[i].getAttribute('id')
          let original = files[i].getAttribute('original') ? files[i].getAttribute('original') : null
          //get units or group
          let transSegments = files[i].getElementsByTagName('segment')
          //check if parent is a group
          for (let s = 0; s < transSegments.length; s++) {
            //process all segments inside file
            let source = transSegments[s].getElementsByTagName('source')[0].textContent
            let target = transSegments[s].getElementsByTagName('target')[0].textContent
            //get segment notes
            let noteEl = transSegments[s].getElementsByTagName('note')
            let segmentNotes = []
            for (let n = 0; n < noteEl.length; n++) {
              segmentNotes.push(noteEl[n].textContent)
            }
            //get unit id
            let parent = transSegments[s].parentElement
            let groupId = null
            let unitId = parent.getAttribute('id')

            //check if parent of the parent is a group
            if (parent.parentElement.nodeName == 'group') {
              groupId = parent.parentElement.getAttribute('id')
            }
            segments.push({
              source: source,
              target: target,
              sourceLanguage: srcLanguage,
              targetLanguage: trgLanguage,
              unitId: unitId,
              groupId: groupId,
              notes: segmentNotes
            })
          }
          data.push({ fileId: fileId, original: original, segments: segments, notes: notes })
        }
      } else if (version == '1.2') {
        console.log('xliff 1.2')
        //getElementsByTagName file or group
        let files = doc.getElementsByTagName('file')

        for (let i = 0; i < files.length; i++) {
          let srcLanguage = files[i].getAttribute('source-language')
          let trgLanguage = files[i].getAttribute('target-language')
          let datatype = files[i].getAttribute('datatype') ? files[i].getAttribute('datatype') : null
          let segments = []
          let notes = []
          //get file notes
          let fileNotes = files[i].getElementsByTagName('note')
          for (let n = 0; n < fileNotes.length; n++) {
            notes.push(fileNotes[n].textContent)
          }
          //get file id
          let fileId = files[i].getAttribute('id')
          let original = files[i].getAttribute('original') ? files[i].getAttribute('original') : null
          //get units or group
          let transSegments = files[i].getElementsByTagName('trans-unit')
          //check if parent is a group
          for (let s = 0; s < transSegments.length; s++) {
            //process all segments inside file
            let source = transSegments[s].getElementsByTagName('source')[0].textContent
            let target = transSegments[s].getElementsByTagName('target')[0].textContent
            let sourceLanguage = transSegments[s].getElementsByTagName('source')[0].getAttribute('xml:lang')
              ? transSegments[s].getElementsByTagName('source')[0].getAttribute('xml:lang')
              : srcLanguage
            let targetLanguage = transSegments[s].getElementsByTagName('target')[0].getAttribute('xml:lang')
              ? transSegments[s].getElementsByTagName('target')[0].getAttribute('xml:lang')
              : trgLanguage
            //get segment notes
            let noteEl = transSegments[s].getElementsByTagName('note')
            let segmentNotes = []
            for (let n = 0; n < noteEl.length; n++) {
              segmentNotes.push(noteEl[n].textContent)
            }
            //get unit id
            let unitId = transSegments[s].getAttribute('id')
            let resource = transSegments[s].getAttribute('resname')
            segments.push({
              source: source,
              target: target,
              sourceLanguage: sourceLanguage,
              targetLanguage: targetLanguage,
              unitId: unitId,
              resource: resource,
              fileId: fileId,
              notes: segmentNotes
            })
          }
          data.push({ fileId: fileId, datatype: datatype, original: original, segments: segments, notes: notes })
        }
      }
    } else {
      console.error('not a valid xliff file')
    }
    return data
  },
  //originalDocument and targetDocument are tip tap documents
  generateXLIFF(originalDocument, targetDocument, version = '2.0') {
    let xliff = ''
    if (version == '2.0') {
      xliff = `<?xml version="1.0" encoding="UTF-8"?>
      <xliff xmlns="urn:oasis:names:tc:xliff:document:2.0" version="2.0">
        <file source-language="${originalDocument.language}" target-language="${targetDocument.language}" datatype="plaintext" original="file.ext">
          <body>`
      for (let i = 0; i < originalDocument.content.length; i++) {
        let source = originalDocument.content[i].content[0].text
        let target = targetDocument.content[i].content[0].text
        let unitId = originalDocument.content[i].attrs.id
        let groupId = originalDocument.content[i].attrs.group
        xliff += `<unit id="${unitId}"${groupId ? ` groupId="${groupId}"` : ''}>
          <segment>
            <source>${source}</source>
            <target>${target}</target>
          </segment>
        </unit>`
      }
      xliff += `</body>
        </file>
      </xliff>`
    } else {
      throw new Error('Only XLIFF 2.0 is supported')
    }
    return xliff
  },

  createTiptapDocumentFromXLIFF(data, type = 'source') {
    let comments = []
    let document = {
      type: 'doc',
      content: []
    }
    for (let d = 0; d < data.length; d++) {
      //file
      if (data[d].notes) {
        //concat notes with comments
        comments.concat(data[d].notes)
      }
      for (let s = 0; s < data[d].segments.length; s++) {
        document.content.push({
          type: 'paragraph',
          attrs: {
            id: data[d].segments[s].unitId,
            groupId: data[d].segments[s].groupId ? data[d].segments[s].groupId : null
          },
          content: [
            {
              type: 'text',
              text: data[d].segments[s][type]
            }
          ]
        })
        //Add block notes
        comments.concat(
          data[d].segments[s].notes.map(note => {
            return {
              blockId: data[d].segments[s].unitId,
              content: note
            }
          })
        )
      }
    }
    return { document, comments }
  },

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  },
  randomLowerString(length) {
    var randomString = ''
    for (var i = 0; i < length; i++) {
      randomString += LOWER_CHARS[Math.floor(Math.random() * LOWER_CHARS_LEN)]
    }
    return randomString
  },
  JSONSafeStringify(obj) {
    let cache = []
    return JSON.stringify(obj, (key, value) =>
      typeof value === 'object' && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value
    )
  },

  isProduction() {
    return process.env.NODE_ENV == 'production'
  },

  isMobile() {
    return window.innerWidth < 640
  },

  getUrlsFromString(content) {
    return content.match(/(https?:\/\/[^ ]*)/)
  },
  isValidEmail(email) {
    return email.match(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  },
  isValidURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // fragment locator
    return !!pattern.test(str)
  },
  getExternalVideoAppFromUrl(url) {
    if (url && this.isValidURL(url)) {
      const hostname = new URL(url).hostname.toLocaleLowerCase()
      for (let [app, domain] of Object.entries(EXTERNAL_VIDEO_DOMAINS)) {
        if (hostname === domain || hostname.endsWith(`.${domain}`)) {
          return app
        }
      }
    }
  },

  equal_set(first, second) {
    if (first.size !== second.size) return false
    for (var i of first) if (!second.has(i)) return false
    return true
  },
  equal_array(first, second) {
    if (first.length !== second.length) return false
    for (var i of first) if (!second.includes(i)) return false
    return true
  },

  escapeForRegex(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
  },
  replaceASCII(value) {
    return value.toLowerCase().replaceAll(ASCII_REGEX, function(matched) {
      return ASCII_MAP[matched] || matched
    })
  },
  existsInSearchFactory(search) {
    const regexItems = []
    const items = this.replaceASCII(search).split(' ')
    for (let item of items) {
      if (item) {
        regexItems.push(this.escapeForRegex(item))
      }
    }

    if (!regexItems.length) {
      return value => {
        return !!value
      }
    } else {
      const regex = new RegExp('.*' + regexItems.join('.*') + '.*')
      return value => {
        return value ? !!this.replaceASCII(value).match(regex) : false
      }
    }
  },

  stripHTML(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent || ''
  },

  async buildMediaDevices() {
    const cameras = []
    const audioInputs = []
    const audioOutputs = []

    const counter = {}
    let devices = await navigator.mediaDevices.enumerateDevices()
    for (let device of devices) {
      if (!device || !device.deviceId) continue

      if (device.kind === 'videoinput') {
        let label = device.label
        if (!label) {
          if (!counter.videoinput) counter.videoinput = 1
          counter.videoinput += 1
          label = `Camera ${counter.videoinput}`
        }

        cameras.push({
          id: device.deviceId,
          label: label
        })
      } else if (device.kind === 'audioinput') {
        let label = device.label
        if (!label) {
          if (!counter.audioinput) counter.audioinput = 1
          counter.audioinput += 1
          label = `Microphone ${counter.audioinput}`
        }

        audioInputs.push({
          id: device.deviceId,
          label: label
        })
      } else if (device.kind === 'audiooutput') {
        let label = device.label
        if (!label) {
          if (!counter.audiooutput) counter.audiooutput = 1
          counter.audiooutput += 1
          label = `Speaker ${counter.audiooutput}`
        }

        audioOutputs.push({
          id: device.deviceId,
          label: label
        })
      }
    }

    return {
      cameras,
      audioInputs,
      audioOutputs
    }
  },

  recordingsPosterURL(recording) {
    try {
      return require(`@/fw-modules/fw-core-vue/ui/legacy/assets/recordings/${recording.app}.png`)
    } catch {
      return require('@/fw-modules/fw-core-vue/ui/legacy/assets/recordings/ucmeetings.png')
    }
  },

  getFileIcon(filename) {
    if (filename) {
      const parts = filename.split('.')
      if (parts.length > 1) {
        const icon = FILE_ICONS[parts[parts.length - 1]]
        if (icon) {
          return icon
        }
      }
    }
    return 'file'
  },

  downloadAuthenticatedFileFromStorage(file) {
    const userToken = file.token ? file.token : this.$store.state.session.user.token
    const fileurl = file.file ? file.file.url_format : file.url_format
    const filekey = file.file ? file.file.key : file.key
    const filename = file.file && file.file.filename ? file.file.filename : file.filename
    const url = fileurl
      .replaceAll('{TOKEN}', userToken)
      .replaceAll('{KEY}', filekey)
      .replaceAll('{FILENAME}', filename)
    this.downloadFile(url, filename)
  },

  downloadFile(url, filename) {
    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  },

  downloadFileFromBlob(blob, filename) {
    const url = window.URL.createObjectURL(blob)
    this.downloadFile(url, filename)
  },

  bytesToString(value, roundTo = 2) {
    if (!value) return '0 B'

    let idx = null
    for (idx = 0; idx < BYTES_REFERENCES.length - 1; idx++) {
      const number = value / 1000
      if (number < 1) {
        break
      } else {
        value = number
      }
    }

    const roundToNumber = 10 ** roundTo
    value = Math.round((value + Number.EPSILON) * roundToNumber) / roundToNumber
    return `${value} ${BYTES_REFERENCES[idx]}`
  },

  errors(obj, clearReference = false) {
    let items = []
    if (obj) {
      let errorObj
      if (obj.__errors__) {
        errorObj = obj
      } else if (obj.data && obj.data.__errors__) {
        errorObj = obj.data
      } else if (obj.response && obj.response.data && obj.response.data.__errors__) {
        errorObj = obj.response.data
      }

      if (errorObj) {
        items = errorObj.__errors__
        if (clearReference) {
          delete errorObj.__errors__
        }
      }
    }

    return {
      items: items,
      context: null,

      setContext(context) {
        this.context = context
        return this
      },

      exists(key) {
        for (let error of this.items) {
          if (error.key === key) {
            return true
          }
        }
        return false
      },

      get() {
        if (this.items.length) {
          return this.items[0]
        }
      },
      getKey() {
        const error = this.get()
        if (error) {
          return error.key
        }
      },
      getTranslated() {
        const error = this.get()
        if (error) {
          return this.translate(error)
        }
      },

      getField(field) {
        for (let error of this.items) {
          if (error.field === field) {
            return error
          }
        }
      },
      getFieldKey(field) {
        const error = this.getField(field)
        if (error) {
          return error.key
        }
      },
      getFieldTranslated(field) {
        const error = this.getField(field)
        if (error) {
          return this.translate(error)
        }
      },

      translate(error) {
        const func = ERRORS[error.key]
        if (func) {
          return func(error, this.context)
        } else {
          return error.key
        }
      },
      extract() {
        const errors = {}
        for (let error of this.items) {
          if (error.field) {
            errors[error.field] = this.translate(error)
          }
        }
        return errors
      }
    }
  },

  isObjectEmpty(obj) {
    // because Object.keys(new Date()).length === 0;
    // we have to do some additional check
    return (
      obj && // null and undefined check
      Object.keys(obj).length === 0 &&
      Object.getPrototypeOf(obj) === Object.prototype
    )
  },

  getFilterText(key, filters) {
    const [filterKey, optionKey] = key.split(':')

    const filterOptions = filters.find(el => el.key.toString() == filterKey.toString())?.options
    if (!filterOptions) return ''

    return filterOptions.find(el => el.key.toString() == optionKey.toString())?.label ?? ''
  },

  setFiltersQuery(appliedFilters, asList = true) {
    const query = {}
    if (appliedFilters.length > 0) {
      appliedFilters.forEach(filter => {
        const filterKey = filter.split(':')[0]
        const filterValue = filter.split(':')[1]
        if (asList) {
          if (filterKey in query) {
            query[filterKey].push(filterValue)
          } else {
            query[filterKey] = [filterValue]
          }
        } else {
          query[filterKey] = filterValue
        }
      })
    }

    return query
  },

  parseFiltersQuery(appliedFilters) {
    const query = {}
    if (appliedFilters.length > 0) {
      appliedFilters.forEach(filter => {
        const filterKey = filter.split(':')[0]
        const filterValue = filter.split(':')[1]
        if (filterKey.endsWith('[]')) {
          if (filterKey in query) {
            query[filterKey].push(filterValue)
          } else {
            query[filterKey] = [filterValue]
          }
        } else {
          query[filterKey] = filterValue
        }
      })
    }

    return query
  },

  // Helpers
  async tryAndCatch(self, code, finallyCode = null, errorCode = null, showErrorDialog = true) {
    try {
      await code()
    } catch (error) {
      if (showErrorDialog) this.errorDialogAlert(self, error)
      if (errorCode) {
        await errorCode(error)
      }
    } finally {
      if (finallyCode) {
        await finallyCode()
      }
    }
  },

  errorDialogAlert(self, error) {
    console.error('errorDialogAlert', error)
    const errorKey = this.errors(error).getKey()

    // Ignore if NotFound or Unauthorized
    if (errorKey === 'NotFound' || errorKey === 'Unauthorized') {
      return
    }

    self.$buefy.dialog.alert({
      title: 'Ocorreu um erro',
      message: `<div class="mb-3">Ocorreu um erro não esperado. Por favor, tente novamente mais tarde.
      Caso o problema persista, por favor, contacte a nossa equipa de suporte.</div>
      <div class="text-sm opacity-80">Error key: ${errorKey}</div>`,
      type: 'is-danger'
    })
  },
  stringToColor(str) {
    // well defined list of colors
    const colors = [
      '#003049',
      '#d62828',
      '#f77f00',
      '#fcbf49',
      '#22577a',
      '#38a3a5',
      '#31572c',
      '#e76f51',
      '#6c584c',
      '#fb6f92',
      '#57cc99',
      '#a7c957',
      '#9e2a2b',
      '#7209b7',
      '#ef476f',
      '#f6bd60',
      '#9e0059',
      '#3a86ff',
      '#6a4c93',
      '#463f3a',
      '#7b2cbf',
      '#ef476f',
      '#26547c',
      '#0d47a1',
      '#ceff1a',
      '#bf4342',
      '#75c8ae',
      '#4ba3c3',
      '#d884d4'
    ]
    // hash code
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash += str.charCodeAt(i)
    }
    // selet color
    return colors[hash % colors.length]
  },
  addOpacityToColor(color, opacity) {
    return color + Math.round(opacity * 255).toString(16)
  },
  lightenColor(color, percent) {
    const num = parseInt(color.slice(1), 16)
    const amt = Math.round(2.55 * percent)
    const R = (num >> 16) + amt
    const G = ((num >> 8) & 0x00ff) + amt
    const B = (num & 0x0000ff) + amt
    const newColor = `#${(
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)}`
    return newColor
  },
  darkenColor(color, percent) {
    const num = parseInt(color.slice(1), 16)
    const amt = Math.round(2.55 * percent)
    const R = (num >> 16) - amt
    const G = ((num >> 8) & 0x00ff) - amt
    const B = (num & 0x0000ff) - amt
    const newColor = `#${(
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)}`
    return newColor
  },
  stringToGreyScaleColor(str) {
    // well defined list of colors
    const colors = [
      '#222222',
      '#333333',
      '#444444',
      '#555555',
      '#666666',
      '#777777',
      '#888888',
      '#999999',
      //'#847577',
      '#a6a2a2',
      //'#cfd2cd',
      '#595959',
      '#7f7f7f',
      '#a5a5a5'
      //
      //'#5f7470',
      //'#889696',
      //'#b8bdb5'
    ]
    // hash code
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash += str.charCodeAt(i)
    }
    // selet color
    return colors[hash % colors.length]
  }
}
