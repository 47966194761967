<template>
  <b-dropdown aria-role="list" position="is-bottom-left" :append-to-body="appendToBody">
    <fw-button-dropdown
      slot="trigger"
      aria-role="listitem"
      type="transparent"
      icon="more"
      :size="size"
      :chevron="false"
    />
    <slot name="default" />
  </b-dropdown>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'sm'
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  }
}
</script>
