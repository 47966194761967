<template>
  <div class="copyToRepoModal flex-1 flex flex-col w-full">
    <div class="flex-1 flex flex-col gap-1" style="min-height: 200px;">
      <div v-if="copying">
        <Copying class="mx-auto mt-10"></Copying>
        <div class="text-center text-xl text-gray-400 p-5">A copiar...</div>
      </div>
      <PanelBucketList
        v-else-if="page == 'bucket_selection'"
        :permissions="['add_items']"
        :dark-scroll-area="true"
        :ignore-bucket="bucketKey"
        :max-scroll-height="maxScrollHeight"
        :list="true"
        :override-with-classes-list="true"
        :selectable-list="true"
        :is-modal="true"
        @selected="selectedBuckets"
      ></PanelBucketList>
      <div v-else class="pl-5 pr-5 pb-5">
        <div class="mb-5 font-semibold">
          Copiar {{ selectedFiles.length }} items para {{ selected.length }} repositório(s)
        </div>
        <div
          class="destination-selector p-5 rounded border-gray-200 border-2 cursor-pointer hover:shadow-lg bg-white mb-5 font-semibold select-none"
          :class="{ 'border-teal-500': option == 'root', 'shadow-lg': option == 'root', selected: option == 'root' }"
          @click="selectDest('root')"
        >
          Raiz do(s) repositório(s)
          <div class="check_box"></div>
        </div>
        <div
          class="destination-selector p-5 rounded border-gray-200 border-2 cursor-pointer hover:shadow-lg bg-white font-semibold select-none"
          :class="{
            'border-teal-500': option == 'new_folder',
            'shadow-lg': option == 'new_folder',
            selected: option == 'new_folder'
          }"
          @click="selectDest('new_folder')"
        >
          <div class="check_box"></div>
          <div class="mb-3">Nova pasta no(s) repositório(s)</div>
          <div class="font-normal mb-1">Indique o nome da nova pasta:</div>
          <input
            ref="new_folder_input"
            v-model="new_folder_name"
            type="text"
            placeholder="e.g. A minha pasta"
            maxlength="150"
            min="2"
            required="required"
            class="input focus:outline-none focus:shadow-none focus:border-0"
          />
        </div>
      </div>
    </div>
    <div v-if="!copying" class="flex-shrink-0 flex flex-reverse items-center gap-1 px-5 pt-5">
      <fw-button
        v-if="page == 'bucket_selection'"
        :type="selected.length > 0 ? 'regular' : 'disabled'"
        @click.native="nextPage"
      >
        Seguinte
      </fw-button>
      <fw-button
        v-else-if="!copying"
        :type="
          ((option == 'new_folder' && new_folder_name.length > 2) || option == 'root') && selected.length > 0
            ? 'regular'
            : 'disabled'
        "
        @click.native="copyToRepo"
      >
        Copiar
      </fw-button>
      <fw-button v-if="page !== 'bucket_selection' && copying === false" class="mr-3" @click.native="backPage"
        >Recomeçar</fw-button
      >
    </div>
  </div>
</template>

<script>
import PanelBucketList from '../panels/PanelBucketList'
import ServiceBuckets from '../../services/ServiceBuckets'
import Copying from '../../../ui/components/animation/Copying'

export default {
  name: 'CopyToRepoModal',
  components: {
    Copying,
    PanelBucketList
  },
  props: {
    bucketKey: {
      type: String,
      required: true
    },
    selectedFiles: {
      type: Array,
      required: true
    }
  },
  data: function() {
    return {
      copying: false,
      selected: [],
      page: 'bucket_selection',
      option: 'root',
      new_folder_name: ''
    }
  },
  computed: {
    maxScrollHeight: function() {
      return 400
    }
  },
  methods: {
    selectDest(option) {
      this.option = option
      if (option === 'new_folder') {
        this.$refs.new_folder_input.focus()
      }
    },
    selectedBuckets(bucketKeys) {
      console.log('SELECTED: ', bucketKeys)
      this.selected = bucketKeys
    },
    closeModal() {
      this.$emit('close', true)
    },
    backPage() {
      this.page = 'bucket_selection'
    },
    nextPage() {
      if (this.selected.length > 0) {
        this.page = 'new_folder'
      }
    },
    async copyToRepo() {
      if (this.selected.length > 0) {
        try {
          this.copying = true
          let folder_name = this.new_folder_name !== '' ? this.new_folder_name : null
          const copyItems = await ServiceBuckets.copyBucketItemToBucket(
            this.bucketKey,
            this.selectedFiles,
            this.selected,
            folder_name
          )
          console.log(copyItems)
          //wait 2 seconds for animation
          await new Promise(r => setTimeout(r, 2000))
          this.copying = false
          /*this.$buefy.toast.open({
            message: 'Ficheiros copiados!',
            type: 'is-success',
          })*/
          this.$buefy.snackbar.open({
            message: 'Ficheiros copiados!',
            type: 'is-success',
            position: 'is-top-right',
            actionText: null
          })
          //close modal
          this.$emit('close', true)
        } catch (e) {
          console.error(e)
          this.copying = false
        }
      }
    }
  }
}
</script>

<style scoped>
.destination-selector {
  position: relative;
}
.destination-selector .check_box {
  position: absolute;
  border-radius: 15px;
  top: 18px;
  right: 18px;
  background: #eff0f1;
  height: 20px;
  width: 20px;
}
.destination-selector.selected .check_box {
  background: #30a898
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center center;
  border-color: #30a898;
  background-size: 70%;
}
</style>
