<template>
  <RouterLink
    :to="{ name: 'community', params: { key: record.key } }"
    class=" bg-white rounded-md relative overflow-hidden"
  >
    <div
      class="h-28 bg-gray-100 relative bg-cover bg-center mb-5"
      :style="{
        backgroundImage: `url(${imageThumbUrl(record.cover, '800')})`
      }"
    ></div>
    <div
      class="h-16 w-16 rounded-2xl absolute top-20 font-semibold left-2.5 border-4 border-white bg-cover bg-center"
      :style="{
        backgroundImage: `url(${imageThumbUrl(record.logo, '200')})`,
        backgroundColor: record.logo == null ? communityColor : '#efefef'
      }"
    >
      <div
        v-if="record.logo == null"
        class="h-full w-full text-2xl bg-white/70 flex flex-col items-center justify-center"
        :style="{
          color: communityColor
        }"
      >
        {{ record.title.charAt(0).toUpperCase() }}
      </div>
    </div>
    <div class="px-4 py-3 flex gap-5 min-w-0 w-full">
      <div class="flex-1 flex flex-col gap-0 min-w-0">
        <div class="text-lg font-bold capitalize overflow-hidden overflow-ellipsis line-clamp-1">
          {{ record.title }}
        </div>
        <div class="text-sm text-gray-500 line-clamp-2 overflow-ellipsis">{{ record.description }}</div>
      </div>
      <div class="text-center flex-shrink-0">
        <div class=" text-lg font-bold">{{ record.stats.users.active }}</div>
        <div class=" text-sm text-gray-500">
          {{ record.stats.users.active == 1 ? $t('member') : $t('members') }}
        </div>
      </div>
      <div v-if="canJoin && showJoin" class="absolute top-3 right-3 text-white">
        <div
          class=" bg-primary rounded-md text-center px-3 h-8 leading-8 hover:bg-primary/80 text-xs cursor-pointer group text-white font-semibold"
          @click.stop.prevent="join()"
        >
          <span style="color: #ffffff;">
            {{ record.auto_accept_join ? $t('join') : $t('ask_to_join') }}
          </span>
        </div>
      </div>
      <div v-else-if="isMember && showJoin" class="absolute top-3 right-3 text-white">
        <div
          class=" bg-primary rounded-md text-center px-3 h-8 leading-8 hover:bg-red-500 text-xs cursor-pointer group text-white font-semibold"
          @click.stop.prevent="join()"
        >
          <fw-icon-check class="h-5 w-5 text-white group-hover:hidden inline-block" />
          <span class="hidden group-hover:inline-block" style="color: #ffffff;">
            {{ $t('leave') }}
          </span>
        </div>
      </div>
      <div v-else-if="isOwner" class="absolute top-3 right-3 text-white">
        <div class="bg-primary rounded-md text-center px-3 h-8 leading-8 text-xs group font-semibold">
          Admin
        </div>
      </div>
    </div>
  </RouterLink>
</template>

<script>
import ServiceContent from '../../services/ServiceContent'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
export default {
  props: {
    record: {
      type: Object,
      required: true
    },
    showJoin: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    isOwner() {
      return this.record.validations.is_owner
    },
    isMember() {
      return this.record.validations.is_member || this.record.validations.is_owner
    },
    canJoin() {
      return this.record.validations.can_join
    },
    communityColor() {
      return utils.stringToColor(this.record.key)
    }
  },
  methods: {
    imageThumbUrl(file, size = 'max2k') {
      if (file == null) {
        return ''
      }
      return file.thumb_url_format
        .replace('{KEY}', file.key)
        .replace('{SIZE}', size)
        .replace('{TOKEN}', file.token)
        .replace('{FILENAME}', file.thumb_filename || file.filename)
    },
    join() {
      if (this.isOwner) {
        //error
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: this.$t('messages.error_admin'),
          type: 'is-danger'
        })
      } else {
        if (this.isMember) {
          this.$buefy.dialog.confirm({
            title: this.$t('leave_title'),
            message: this.$t('leave_message'),
            confirmText: this.$t('leave_button'),
            type: 'is-danger',
            onConfirm: () => {
              ServiceContent.leaveGroup(this.record.key)
                .then(() => {
                  this.$buefy.toast.open({
                    message: this.$t('messages.left'),
                    type: 'is-success'
                  })
                  this.$emit('joined')
                })
                .catch(() => {
                  this.$buefy.dialog.alert({
                    title: 'Erro',
                    message: this.$t('messages.error_leaving'),
                    type: 'is-danger'
                  })
                })
            }
          })
        } else {
          //join
          ServiceContent.joinGroup(this.record.key)
            .then(() => {
              if (this.record.auto_accept_join) {
                this.$buefy.toast.open({
                  message: this.$t('messages.joined'),
                  type: 'is-success'
                })
              } else {
                this.$buefy.toast.open({
                  message: this.$t('messages.asked_to_join'),
                  type: 'is-success'
                })
              }
              this.$emit('joined')
            })
            .catch(() => {
              this.$buefy.dialog.alert({
                title: 'Erro',
                message: this.$t('messages.error_join'),
                type: 'is-danger'
              })
            })
        }
      }
    }
  }
}
</script>

<i18n>
{
  "en": {
    "join": "Join",
    "ask_to_join": "Ask to join",
    "leave": "Leave",
    "messages": {
      "joined": "Joined community",
      "left": "Left community",
      "error_join": "Error joining community",
      "asked_to_join": "Asked to join community",
      "error_admin": "Cannot leave a community you manage",
      "error_leaving": "Error leaving community"
    },
    "leave_title": "Leave community",
    "leave_message": "Are you sure you want to leave the community?",
    "leave_button": "Leave",
    "members": "Members",
    "member": "Member"
  },
  "pt": {
    "join": "Aderir",
    "ask_to_join": "Pedir para aderir",
    "leave": "Sair",
    "messages": {
      "joined": "Aderiu à comunidade",
      "left": "Saiu da comunidade",
      "error_join": "Erro ao aderir à comunidade",
      "asked_to_join": "Pedido de adesão enviado",
      "error_admin": "Não pode sair de uma comunidade que gere",
      "error_leaving": "Erro ao sair da comunidade"
    },
    "leave_title": "Sair da comunidade",
    "leave_message": "Tem a certeza que deseja sair da comunidade?",
    "leave_button": "Sair",
    "members": "Membros",
    "member": "Membro"
  }
}
</i18n>
