<template>
  <div class="relative bulk-import-users">
    <div v-if="processing" class="flex flex-col mx-5 mt-5 mb-2 gap-3 h-96">
      <div>
        <div class="text-lg font-bold">{{ title }}</div>
      </div>
      <div class="text-center text-gray-500 flex-1 flex flex-col justify-center items-center gap-5">
        <fw-icon-user-search class="h-12 text-gray-500"></fw-icon-user-search>
        A processar {{ numberOfLines != 1 ? numberOfLines + ' linhas' : numberOfLines + ' linha' }}...
      </div>
      <div class="flex gap-3 mb-3">
        <div class="flex-1"></div>
        <fw-button :type="'link-muted'" disabled>Anterior</fw-button>
        <fw-button :type="'primary'" wider disabled>A carregar</fw-button>
      </div>
    </div>
    <div v-else-if="page == 0" class="flex flex-col mx-5 mt-5 mb-2 gap-3 h-96">
      <div>
        <div class="text-lg font-bold">{{ title }}</div>
        <div class="text-sm">
          {{ instructions }}
        </div>
      </div>
      <b-input v-model="bulkusers" class="flex-1" type="textarea"></b-input>

      <div class="text-sm text-gray-500 opacity-0" :class="{ 'opacity-100': bulkusers.length > 0 }">
        {{ numberOfLines != 1 ? numberOfLines + ' linhas' : numberOfLines + ' linha' }}
      </div>
      <div class="flex gap-3 mb-3">
        <div class="flex-1"></div>
        <fw-button :type="'link-muted'" @click.native="$emit('close')">Cancelar</fw-button>
        <fw-button :type="'primary'" wider :disabled="bulkusers.length == 0" @click.native="processUsers()"
          >Seguinte</fw-button
        >
      </div>
    </div>
    <div v-else-if="page == 1" class="flex flex-col mx-5 mt-5 mb-2 gap-3 h-96">
      <div>
        <div class="text-lg font-bold">{{ title }}</div>
      </div>
      <fw-panel class="flex-1" :title="seenotfound ? 'Não encontrados' : 'Resultados'" :counter="results.length">
        <template #toolbar>
          <fw-button v-if="notfound && notfound.length > 0" type="light" @click.native="seeNotFoundList()">
            <span v-if="seenotfound"> Ver resultados </span>
            <span v-else>Não encontrados ({{ notfound.length }})</span>
          </fw-button>
        </template>
        <div v-if="seenotfound == false" style="max-height: 400px" class="overflow-y-auto">
          <RecycleScroller
            v-if="results.length"
            v-slot="{ item, index }"
            :items="results"
            :item-size="55"
            :buffer="50"
            key-field="key"
          >
            <Person
              :key="item.key"
              :class="{
                'border-b border-gray-100': index !== results.length - 1
              }"
              :checked="true"
              :person="item"
              :selectable="true"
              :clickable="false"
              @selected="selectPerson(item, $event)"
            >
            </Person>
          </RecycleScroller>
          <div v-else class="text-center py-20 text-gray-500">Nenhum utilizador encontrado</div>
        </div>
        <div v-if="seenotfound" style="max-height: 400px" class="overflow-y-auto">
          <RecycleScroller v-slot="{ item, index }" :items="notfound" :item-size="55" :buffer="50" key-field="key">
            <div>{{ item }}</div>
          </RecycleScroller>
        </div>
      </fw-panel>
      <div class="flex gap-3 mb-3">
        <div class="flex-1"></div>
        <fw-button :type="'link-muted'" @click.native="page = 0">Anterior</fw-button>
        <fw-button :type="'primary'" wider :disabled="selected.length == 0" @click.native="confirmSelection"
          >Importar <span v-if="selected.length" class="text-xs ml-1">({{ selected.length }})</span></fw-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import { RecycleScroller } from 'vue-virtual-scroller'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'

export default {
  name: 'ModalImportBulkUsers',
  components: { RecycleScroller, Person },
  props: {
    context: Object,
    searchEngine: {
      type: Function,
      default: null
    },
    title: {
      type: String,
      default: 'Importar utilizadores'
    },
    instructions: {
      type: String,
      default: 'Insira um número de estudante / funcionário ou email institucional / conta externa por linha:'
    }
  },
  data() {
    return {
      bulkusers: '',
      processing: false,
      page: 0,
      results: [],
      notfound: [],
      selected: [],
      timer: null,
      seenotfound: false
    }
  },
  computed: {
    numberOfLines() {
      return this.bulkusers.split(/\r\n|\r|\n/).length
    }
  },
  methods: {
    seeNotFoundList() {
      this.seenotfound = !this.seenotfound
    },
    selectPerson(person, selectedValue) {
      console.log(person, selectedValue)
      let i = this.selected.findIndex(el => el.key == person.key)
      if (i >= 0 && !selectedValue) {
        this.selected.splice(i, 1)
      }
      if (selectedValue && i == -1) {
        this.selected.push(person)
      }
    },
    confirmSelection() {
      if (this.selected.length > 0) {
        this.$emit('selected', this.selected)
      }
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    async processUsers() {
      if (this.bulkusers.length == 0) {
        return
      }
      let query = this.bulkusers.split(/\r\n|\r|\n/).join(',')
      this.processing = true
      try {
        let result
        if (this.searchEngine != null) {
          result = await this.searchEngine(query)
        } else {
          result = await FormServices.bulkSearch(this.context.key, query)
        }
        console.log(result)
        if (this.timer != null) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
          if (result.data.data) {
            this.results = result.data.data
            this.selected = result.data.data
            this.notfound = result.data.not_found
          } else {
            this.results = result.data
            this.selected = result.data
            this.notfound = result.not_found
          }
          this.page = 1
          this.processing = false
        }, 1000)
      } catch (e) {
        console.error(e)
        this.processing = false
        this.showError('Ocorreu um erro ao encontrar os utilizadores')
      }
    }
  }
}
</script>

<style>
.bulk-import-users .textarea {
  @apply h-full;
}
</style>
