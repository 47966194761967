import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import querystring from 'querystring'
import Api from '@/fw-modules/fw-core-vue/api/Api'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import store from '@/store'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
//import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlChat
    api.defaults.quietly = false
    return api
  },

  async allUnread() {
    const response = await this.base().get('/v1/unread')

    for (let message of response.data) {
      message.created_date_obj = Dates.build(message.created_date)
    }
    response.data.sort((a, b) => {
      if (a.created_date_obj < b.created_date_obj) return 1
      else if (a.created_date_obj > b.created_date_obj) return -1
      else return a.key.localeCompare(b.key)
    })
    return response.data
  },
  async getChannels(type = null, limit = null) {
    let data = null
    if (type || limit) {
      const dataParams = {}
      if (type) dataParams.type = type
      if (limit) dataParams.limit = limit

      data = {
        params: dataParams,
        paramsSerializer: params => {
          return querystring.stringify(params)
        }
      }
    }

    const response = await this.base().get('/v1/channels', data)

    for (let channel of response.data.channels) {
      if (channel.last) channel.last_date_obj = Dates.build(channel.last.date)
      else channel.last_date_obj = null
    }
    response.data.channels.sort((a, b) => {
      if (a.last_date_obj < b.last_date_obj) return 1
      else if (a.last_date_obj > b.last_date_obj) return -1
      else return a.title.localeCompare(b.title)
    })
    return response.data
  },
  async getChannel(key) {
    const response = await this.base().get(`/v1/channel/${key}`)
    return response.data
  },

  async getMessages(chatKey, quietly = false, afterKey = null, beforeKey = null, subscribe = false) {
    const orgArgs = Array.from(arguments)
    const callback = this.getMessages

    const data = {
      quietly: quietly,
      params: {}
    }

    if (subscribe) data.params.subscribe_connection_id = store.state.socket.connectionId

    if (afterKey) data.params.after_key = afterKey
    else if (beforeKey) data.params.before_key = beforeKey

    const config = { ignoreResponseLog: true, ignoreInvalidConnectionID: true }

    try {
      const response = await this.base(config).get(`/v1/channel/${chatKey}/messages`, data)
      if (subscribe) console.debug('Chat subscribe on get messages', chatKey)
      return response.data
    } catch (error) {
      //retry!
      if (utils.errors(error).exists('InvalidConnectionID')) {
        store.commit('checkWebsocket', {
          retryCallback: callback,
          retryCallbackArgs: orgArgs,
          retryCallbackWait: 100,
          retryCallbackExpectReturn: true
        })
        return store.state.callbackReturnData
      } else {
        throw error
      }
    }
  },
  getMessagesAndSubscribe(chatKey, quietly = false, afterKey = null) {
    return this.getMessages(chatKey, quietly, afterKey, null, true)
  },
  async getChatUsers(chatKey, userKeys = null) {
    const data = {
      paramsSerializer: params => {
        return querystring.stringify(params)
      }
    }
    if (userKeys) {
      data.params = { key: userKeys }
    }

    const response = await this.base().get(`/v1/channel/${chatKey}/users`, data)
    return response.data
  },

  async addUser(email) {
    const response = await this.base().post('/v1/channels', { email: email })
    return response.data
  },

  addMessage(chatKey, messageText, files = []) {
    const config = { ignoreDataLog: true, ignoreResponseLog: true }
    return this.base(config).post(`/v1/channel/${chatKey}/messages`, {
      message: messageText,
      files: files,
      ignore_connection_id: store.state.socket.connectionId
    })
  },
  deleteMessage(chatKey, messageKey) {
    return this.base().delete(`/v1/channel/${chatKey}/message/${messageKey}`, {
      data: {
        ignore_connection_id: store.state.socket.connectionId
      }
    })
  },

  async subscribe(key) {
    store.commit('sendWSMessage', { application: 'chat', code: 'subscribe', key: key })
    console.debug('Chat subscribe', key)
  },
  async unsubscribe(key) {
    store.commit('sendWSMessage', { application: 'chat', code: 'unsubscribe', key: key })
    console.debug('Chat unsubscribe', key)
  }
}
