<template>
  <div class="bg-white shadow-md hover:shadow-xl rounded-xl p-4 h-full">
    <div class="flex gap-2 items-center">
      <Avatar :user="user" />
      <div class="flex-1">
        <fw-heading size="h4">{{ user.name }}</fw-heading>
        <div v-if="user.email" class="has-text-muted has-text-small content">
          {{ user.email }}
        </div>
      </div>
    </div>
    <div v-if="loggedUser.key != user.key" class="flex-1 my-5">
      <fw-label>Permissões</fw-label>
      <form class="text-sm p-2 flex flex-col gap-2" @submit.prevent="updateUser">
        <b-checkbox v-model="isManager" size="is-small">Gestor do canal</b-checkbox>
        <b-checkbox v-if="!isManager" v-model="canAddUsers" size="is-small">Adicionar utilizadores</b-checkbox>
        <b-checkbox v-model="seeChatHistory" size="is-small">Visualizar histórico do canal</b-checkbox>
        <b-field v-if="errorMessage" class="notification is-danger has-padding">{{ errorMessage }}</b-field>
      </form>
    </div>
    <div v-if="loggedUser.key != user.key" class="-mb-2 flex justify-end">
      <fw-button type="link-muted" outlined size="sm" @click.native="deleteUser(user.key)">
        Remover
      </fw-button>
    </div>
  </div>
</template>

<script>
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import Avatar from '@/fw-modules/fw-core-vue/ui/components/users/Avatar'

export default {
  components: {
    Avatar
  },

  props: {
    meetingKey: String,
    user: {
      type: Object,
      deep: true
    }
  },

  data() {
    return {
      isManager: this.user.permissions.includes('is_manager'),
      canAddUsers: this.user.permissions.includes('add_users'),
      seeChatHistory: this.user.permissions.includes('see_chat_history'),
      errorMessage: '',
      errorCount: 0
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    }
  },

  watch: {
    isManager(value) {
      this.updatePermission('is_manager', 'isManager', value)
    },
    canAddUsers(value) {
      this.updatePermission('add_users', 'canAddUsers', value)
    },
    seeChatHistory(value) {
      this.updatePermission('see_chat_history', 'seeChatHistory', value)
    }
  },

  methods: {
    async updatePermission(permissionKey, thisKey, value) {
      this.errorMessage = ''
      if (value !== this.user.permissions.includes(permissionKey)) {
        try {
          const data = {}
          data[permissionKey] = value

          const users = await ServiceMeetings.updatePermission(this.meetingKey, this.user.key, data)
          this.$emit('set-users', users)
        } catch (error) {
          console.error(
            `Failed to update user ${this.user.key} "${permissionKey}" permission in meeting ${this.meetingKey}`,
            error
          )
          this.setError('Ocorreu um erro')
          this.$nextTick(() => {
            this[thisKey] = this.user.permissions.includes(permissionKey)
          })
        }
      }
    },

    setError(message) {
      this.errorMessage = message
      this.errorCount += 1

      let errorCount = this.errorCount
      setTimeout(() => {
        if (errorCount === this.errorCount) {
          this.errorMessage = ''
        }
      }, 3000)
    },
    async deleteUser(key) {
      const users = await ServiceMeetings.deletePermission(this.meetingKey, key)
      this.$emit('set-users', users)
    }
  }
}
</script>
