<template>
  <div
    class="flex gap-5 p-3 items-center cursor-pointer page-record hover:bg-gray-100"
    :class="{ 'border-b  border-gray-100': !last }"
  >
    <!-- Title -->
    <div class="flex-1">
      <div class="flex gap-2 text-left items-center">
        <fw-icon-arrow-right class="text-gray-400 h-5 w-5 item-arrow" />
        <div>
          <v-clamp v-if="title != null && title.length > 0" autoresize :max-lines="1" class="text-black font-semibold">
            {{ title }}
          </v-clamp>
          <div v-else class="text-gray-400">{{ $t('no_title') }}</div>
        </div>
      </div>
      <div class="text-xs text-gray-500 flex gap-2 ml-7">
        <div>{{ $t('created_at') }} {{ instance.created_date | formatDateTime }}</div>
      </div>
    </div>
    <!-- Versions and language -->
    <div class="hidden lg:flex w-48 gap-2 justify-end">
      <fw-tag v-for="(lang, l) in languages" :key="'language_' + l" type="light-border-box" size="xs">
        {{ lang }}
      </fw-tag>
      <fw-tag v-if="extra > 0" type="light-border-box" size="xs" class="flex items-center gap-0.5 justify-center">
        +{{ extra }}
      </fw-tag>
    </div>
    <!-- Status -->
    <div class="flex gap-1">
      <fw-tag v-if="instance.state == 'closed'" type="xlight">
        {{ $t('label.closed') }}
      </fw-tag>
      <fw-tag v-else-if="instance.state == 'draft'" type="orange">
        {{ $t('label.draft') }}
      </fw-tag>
      <fw-tag v-else-if="instance.state == 'published'" type="primary">
        {{ $t('label.published') }}
      </fw-tag>
      <fw-tag v-else-if="instance.state == 'review'" type="primary">
        {{ $t('label.review') }}
      </fw-tag>
      <fw-tag v-if="instance.state == 'ended'" type="xlight" class="flex gap-1 items-center flex-shrink-0">
        {{ $t('label.locked') }}
      </fw-tag>
    </div>
    <!-- Owner -->
    <div class="hidden lg:flex w-10 items-center justify-center">
      <fw-avatar :user="users[instance.user_key]" size="xs" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageRecord',
  props: {
    users: {
      type: Object
    },
    language: {
      type: String,
      default: 'pt'
    },
    instance: {
      type: Object
    },
    last: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title() {
      return this.instance.versions.find(v => v.is_original)?.title
    },
    languages() {
      return this.instance.versions.map(v => v.language).slice(0, 3)
    },
    extra() {
      return this.instance.versions.length - 3
    }
  }
}
</script>
<style scoped>
.page-record .item-arrow {
  @apply transition-all;
}
.page-record:hover .item-arrow {
  transform: translateX(3px);
}
</style>

<i18n>
  {
    "pt": {
      "no_title": "Sem título",
      "created_at": "Criado em",
      "label.closed": "Fechado",
      "label.draft": "Rascunho",
      "label.published": "Publicado",
      "label.review": "Revisão",
      "label.locked": "Bloqueado"
    },
    "en": {
      "no_title": "No title",
      "created_at": "Created at",
      "label.closed": "Closed",
      "label.draft": "Draft",
      "label.published": "Published",
      "label.review": "Review",
      "label.locked": "Locked"
    }
  }
</i18n>
