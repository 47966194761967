<template>
  <div>
    <svg
      class="h-5 fill-current"
      width="124"
      height="18"
      viewBox="0 0 124 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.264 4.4C15.512 4.4 16.488 4.784 17.192 5.552C17.896 6.32 18.248 7.4 18.248 8.792V16.712C18.248 16.792 18.216 16.864 18.152 16.928C18.104 16.976 18.04 17 17.96 17H14.576C14.496 17 14.424 16.976 14.36 16.928C14.312 16.864 14.288 16.792 14.288 16.712V9.656C14.288 9.112 14.144 8.672 13.856 8.336C13.584 7.984 13.216 7.808 12.752 7.808C12.288 7.808 11.904 7.976 11.6 8.312C11.312 8.648 11.168 9.096 11.168 9.656V16.712C11.168 16.792 11.136 16.864 11.072 16.928C11.024 16.976 10.96 17 10.88 17H7.472C7.392 17 7.32 16.976 7.256 16.928C7.208 16.864 7.184 16.792 7.184 16.712V9.656C7.184 9.112 7.032 8.672 6.728 8.336C6.44 7.984 6.064 7.808 5.6 7.808C5.168 7.808 4.808 7.944 4.52 8.216C4.232 8.488 4.056 8.848 3.992 9.296V16.712C3.992 16.792 3.96 16.864 3.896 16.928C3.848 16.976 3.784 17 3.704 17H0.295996C0.215996 17 0.143996 16.976 0.0799956 16.928C0.0319956 16.864 0.00799561 16.792 0.00799561 16.712V4.88C0.00799561 4.8 0.0319956 4.736 0.0799956 4.688C0.143996 4.624 0.215996 4.592 0.295996 4.592H3.704C3.784 4.592 3.848 4.624 3.896 4.688C3.96 4.736 3.992 4.8 3.992 4.88V5.96C3.992 6.024 4.008 6.064 4.04 6.08C4.072 6.08 4.104 6.048 4.136 5.984C4.76 4.928 5.784 4.4 7.208 4.4C8.008 4.4 8.712 4.576 9.32 4.928C9.928 5.28 10.392 5.792 10.712 6.464C10.76 6.56 10.808 6.552 10.856 6.44C11.192 5.752 11.648 5.24 12.224 4.904C12.816 4.568 13.496 4.4 14.264 4.4Z"
        fill="black"
      />
      <path
        d="M25.8288 17.192C24.3888 17.192 23.1648 16.808 22.1568 16.04C21.1488 15.272 20.4768 14.232 20.1408 12.92C19.9488 12.264 19.8528 11.544 19.8528 10.76C19.8528 9.88 19.9568 9.104 20.1648 8.432C20.5328 7.168 21.2128 6.184 22.2048 5.48C23.2128 4.76 24.4288 4.4 25.8528 4.4C27.2608 4.4 28.4528 4.76 29.4288 5.48C30.4048 6.184 31.0848 7.16 31.4688 8.408C31.6928 9.128 31.8048 9.896 31.8048 10.712C31.8048 11.448 31.7168 12.152 31.5408 12.824C31.2048 14.168 30.5328 15.232 29.5248 16.016C28.5168 16.8 27.2848 17.192 25.8288 17.192ZM25.8288 13.784C26.2928 13.784 26.6768 13.64 26.9808 13.352C27.2848 13.048 27.5088 12.64 27.6528 12.128C27.7488 11.712 27.7968 11.256 27.7968 10.76C27.7968 10.28 27.7408 9.816 27.6288 9.368C27.5008 8.872 27.2848 8.488 26.9808 8.216C26.6768 7.944 26.2928 7.808 25.8288 7.808C24.9008 7.808 24.2928 8.328 24.0048 9.368C23.9088 9.752 23.8608 10.216 23.8608 10.76C23.8608 11.256 23.9088 11.712 24.0048 12.128C24.1328 12.64 24.3488 13.048 24.6528 13.352C24.9728 13.64 25.3648 13.784 25.8288 13.784Z"
        fill="black"
      />
      <path
        d="M40.5076 4.4C41.7556 4.4 42.7796 4.8 43.5796 5.6C44.3796 6.384 44.7796 7.456 44.7796 8.816V16.712C44.7796 16.792 44.7476 16.864 44.6836 16.928C44.6356 16.976 44.5716 17 44.4916 17H41.0836C41.0036 17 40.9316 16.976 40.8676 16.928C40.8196 16.864 40.7956 16.792 40.7956 16.712V9.68C40.7956 9.136 40.6436 8.688 40.3396 8.336C40.0356 7.984 39.6436 7.808 39.1636 7.808C38.6676 7.808 38.2676 7.984 37.9636 8.336C37.6596 8.672 37.5076 9.12 37.5076 9.68V16.712C37.5076 16.792 37.4756 16.864 37.4116 16.928C37.3636 16.976 37.2996 17 37.2196 17H33.8116C33.7316 17 33.6596 16.976 33.5956 16.928C33.5476 16.864 33.5236 16.792 33.5236 16.712V4.88C33.5236 4.8 33.5476 4.736 33.5956 4.688C33.6596 4.624 33.7316 4.592 33.8116 4.592H37.2196C37.2996 4.592 37.3636 4.624 37.4116 4.688C37.4756 4.736 37.5076 4.8 37.5076 4.88V5.768C37.5076 5.832 37.5156 5.872 37.5316 5.888C37.5636 5.888 37.5956 5.864 37.6276 5.816C38.2356 4.872 39.1956 4.4 40.5076 4.4Z"
        fill="black"
      />
      <path
        d="M54.3755 0.487998C54.3755 0.407998 54.3995 0.343997 54.4475 0.295997C54.5115 0.231997 54.5835 0.199997 54.6635 0.199997H58.0715C58.1515 0.199997 58.2155 0.231997 58.2635 0.295997C58.3275 0.343997 58.3595 0.407998 58.3595 0.487998V16.712C58.3595 16.792 58.3275 16.864 58.2635 16.928C58.2155 16.976 58.1515 17 58.0715 17H54.6635C54.5835 17 54.5115 16.976 54.4475 16.928C54.3995 16.864 54.3755 16.792 54.3755 16.712V15.92C54.3755 15.872 54.3595 15.848 54.3275 15.848C54.2955 15.832 54.2635 15.84 54.2315 15.872C53.5915 16.752 52.6715 17.192 51.4715 17.192C50.3835 17.192 49.4155 16.864 48.5675 16.208C47.7355 15.552 47.1435 14.656 46.7915 13.52C46.5355 12.656 46.4075 11.728 46.4075 10.736C46.4075 9.616 46.5755 8.608 46.9115 7.712C47.2795 6.72 47.8635 5.92 48.6635 5.312C49.4795 4.704 50.4635 4.4 51.6155 4.4C52.7675 4.4 53.6395 4.776 54.2315 5.528C54.2635 5.576 54.2955 5.592 54.3275 5.576C54.3595 5.56 54.3755 5.528 54.3755 5.48V0.487998ZM53.9675 12.848C54.2395 12.336 54.3755 11.656 54.3755 10.808C54.3755 9.88 54.2235 9.16 53.9195 8.648C53.5835 8.088 53.1115 7.808 52.5035 7.808C51.8475 7.808 51.3595 8.096 51.0395 8.672C50.7195 9.216 50.5595 9.928 50.5595 10.808C50.5595 11.656 50.7035 12.344 50.9915 12.872C51.3275 13.48 51.8315 13.784 52.5035 13.784C53.1435 13.784 53.6315 13.472 53.9675 12.848Z"
        fill="black"
      />
      <path
        d="M65.6373 4.4C66.7093 4.4 67.6773 4.584 68.5413 4.952C69.4053 5.304 70.0853 5.8 70.5813 6.44C71.0773 7.064 71.3253 7.76 71.3253 8.528V16.712C71.3253 16.792 71.2933 16.864 71.2293 16.928C71.1813 16.976 71.1173 17 71.0373 17H67.6293C67.5493 17 67.4773 16.976 67.4133 16.928C67.3653 16.864 67.3413 16.792 67.3413 16.712V15.872C67.3413 15.808 67.3253 15.776 67.2933 15.776C67.2613 15.76 67.2293 15.784 67.1973 15.848C66.5893 16.744 65.5493 17.192 64.0773 17.192C62.7973 17.192 61.7733 16.896 61.0053 16.304C60.2533 15.696 59.8773 14.808 59.8773 13.64C59.8773 12.408 60.3013 11.456 61.1493 10.784C62.0133 10.112 63.2613 9.776 64.8933 9.776H67.2213C67.3013 9.776 67.3413 9.736 67.3413 9.656V9.2C67.3413 8.768 67.1973 8.432 66.9093 8.192C66.6213 7.936 66.2213 7.808 65.7093 7.808C65.3253 7.808 64.9893 7.88 64.7013 8.024C64.4293 8.168 64.2533 8.352 64.1733 8.576C64.0933 8.752 63.9813 8.832 63.8373 8.816L60.3813 8.36C60.1893 8.328 60.0933 8.256 60.0933 8.144C60.1573 7.44 60.4293 6.808 60.9093 6.248C61.4053 5.672 62.0613 5.224 62.8773 4.904C63.7093 4.568 64.6293 4.4 65.6373 4.4ZM65.2533 14.336C65.8613 14.336 66.3573 14.184 66.7413 13.88C67.1413 13.56 67.3413 13.144 67.3413 12.632V12.056C67.3413 11.976 67.3013 11.936 67.2213 11.936H65.7813C65.1733 11.936 64.7013 12.04 64.3653 12.248C64.0453 12.456 63.8853 12.768 63.8853 13.184C63.8853 13.536 64.0053 13.816 64.2453 14.024C64.4853 14.232 64.8213 14.336 65.2533 14.336Z"
        fill="black"
      />
      <path
        d="M84.7931 9.704C84.8731 10.184 84.8971 10.816 84.8651 11.6C84.8491 11.792 84.7451 11.888 84.5531 11.888H77.2331C77.2011 11.888 77.1691 11.904 77.1371 11.936C77.1051 11.952 77.0971 11.976 77.1131 12.008C77.1451 12.184 77.2251 12.416 77.3531 12.704C77.5451 13.04 77.8331 13.312 78.2171 13.52C78.6011 13.728 79.0731 13.832 79.6331 13.832C80.6411 13.832 81.4331 13.504 82.0091 12.848C82.0731 12.768 82.1451 12.728 82.2251 12.728C82.3051 12.728 82.3691 12.76 82.4171 12.824L84.2651 14.936C84.3291 14.984 84.3611 15.048 84.3611 15.128C84.3611 15.192 84.3291 15.256 84.2651 15.32C83.7051 15.928 83.0091 16.392 82.1771 16.712C81.3611 17.032 80.4651 17.192 79.4891 17.192C78.0331 17.192 76.7931 16.88 75.7691 16.256C74.7611 15.616 74.0331 14.728 73.5851 13.592C73.2491 12.792 73.0811 11.792 73.0811 10.592C73.0811 9.76 73.2091 8.96 73.4651 8.192C73.8811 7.008 74.5611 6.08 75.5051 5.408C76.4651 4.72 77.6011 4.376 78.9131 4.376C79.9691 4.376 80.9131 4.608 81.7451 5.072C82.5771 5.52 83.2491 6.152 83.7611 6.968C84.2891 7.768 84.6331 8.68 84.7931 9.704ZM78.9131 7.808C78.0811 7.808 77.5291 8.2 77.2571 8.984C77.1931 9.16 77.1531 9.328 77.1371 9.488C77.1051 9.568 77.1371 9.608 77.2331 9.608H80.6411C80.7051 9.608 80.7371 9.576 80.7371 9.512C80.7371 9.384 80.7051 9.224 80.6411 9.032C80.5291 8.632 80.3211 8.328 80.0171 8.12C79.7291 7.912 79.3611 7.808 78.9131 7.808Z"
        fill="black"
      />
      <path
        d="M92.2044 17.192C90.8924 17.192 89.7404 16.864 88.7484 16.208C87.7724 15.552 87.0764 14.648 86.6604 13.496C86.3564 12.728 86.2044 11.808 86.2044 10.736C86.2044 9.664 86.3564 8.744 86.6604 7.976C87.0604 6.856 87.7564 5.984 88.7484 5.36C89.7404 4.72 90.8924 4.4 92.2044 4.4C93.5324 4.4 94.7004 4.728 95.7084 5.384C96.7164 6.04 97.3964 6.872 97.7484 7.88C97.8604 8.168 97.9324 8.48 97.9644 8.816C97.9644 8.992 97.8764 9.096 97.7004 9.128L94.3644 9.632H94.3164C94.1724 9.632 94.0764 9.552 94.0284 9.392L93.9804 9.176C93.9484 9.048 93.9164 8.936 93.8844 8.84C93.7404 8.536 93.5164 8.288 93.2124 8.096C92.9244 7.904 92.5804 7.808 92.1804 7.808C91.3324 7.808 90.7724 8.184 90.5004 8.936C90.3244 9.384 90.2364 9.992 90.2364 10.76C90.2364 11.448 90.3164 12.048 90.4764 12.56C90.7804 13.376 91.3484 13.784 92.1804 13.784C92.5964 13.784 92.9564 13.68 93.2604 13.472C93.5644 13.248 93.7804 12.936 93.9084 12.536C93.9244 12.504 93.9324 12.456 93.9324 12.392C93.9484 12.376 93.9564 12.344 93.9564 12.296C94.0044 12.104 94.1164 12.024 94.2924 12.056L97.6524 12.584C97.8124 12.616 97.8924 12.704 97.8924 12.848C97.8924 13.088 97.8444 13.352 97.7484 13.64C97.3644 14.76 96.6684 15.632 95.6604 16.256C94.6684 16.88 93.5164 17.192 92.2044 17.192Z"
        fill="black"
      />
      <path
        d="M106.723 4.88C106.723 4.8 106.747 4.736 106.795 4.688C106.859 4.624 106.931 4.592 107.011 4.592H110.419C110.499 4.592 110.563 4.624 110.611 4.688C110.675 4.736 110.707 4.8 110.707 4.88V16.712C110.707 16.792 110.675 16.864 110.611 16.928C110.563 16.976 110.499 17 110.419 17H107.011C106.931 17 106.859 16.976 106.795 16.928C106.747 16.864 106.723 16.792 106.723 16.712V15.872C106.723 15.824 106.707 15.792 106.675 15.776C106.643 15.76 106.611 15.776 106.579 15.824C105.939 16.736 104.931 17.192 103.555 17.192C102.339 17.192 101.355 16.808 100.603 16.04C99.8511 15.272 99.4751 14.2 99.4751 12.824V4.88C99.4751 4.8 99.4991 4.736 99.5471 4.688C99.6111 4.624 99.6831 4.592 99.7631 4.592H103.171C103.251 4.592 103.315 4.624 103.363 4.688C103.427 4.736 103.459 4.8 103.459 4.88V11.912C103.459 12.472 103.603 12.92 103.891 13.256C104.179 13.592 104.571 13.76 105.067 13.76C105.515 13.76 105.883 13.624 106.171 13.352C106.475 13.08 106.659 12.72 106.723 12.272V4.88Z"
        fill="black"
      />
      <path
        d="M117.999 17.192C116.863 17.192 115.871 17.04 115.023 16.736C114.175 16.416 113.519 15.976 113.055 15.416C112.607 14.84 112.383 14.184 112.383 13.448V13.184C112.383 13.104 112.407 13.04 112.455 12.992C112.519 12.928 112.591 12.896 112.671 12.896H115.911C115.991 12.896 116.055 12.928 116.103 12.992C116.167 13.04 116.199 13.104 116.199 13.184C116.199 13.488 116.367 13.744 116.703 13.952C117.039 14.144 117.463 14.24 117.975 14.24C118.423 14.24 118.775 14.168 119.031 14.024C119.303 13.864 119.439 13.656 119.439 13.4C119.439 13.144 119.295 12.96 119.007 12.848C118.735 12.72 118.287 12.6 117.663 12.488C116.655 12.296 115.839 12.064 115.215 11.792C114.399 11.456 113.751 11.04 113.271 10.544C112.791 10.032 112.551 9.336 112.551 8.456C112.551 7.208 113.031 6.224 113.991 5.504C114.967 4.768 116.247 4.4 117.831 4.4C118.903 4.4 119.839 4.576 120.639 4.928C121.455 5.264 122.087 5.744 122.535 6.368C122.983 6.992 123.207 7.712 123.207 8.528C123.207 8.608 123.175 8.68 123.111 8.744C123.063 8.792 122.999 8.816 122.919 8.816H119.775C119.695 8.816 119.623 8.792 119.559 8.744C119.511 8.68 119.487 8.608 119.487 8.528C119.487 8.224 119.343 7.976 119.055 7.784C118.767 7.592 118.375 7.496 117.879 7.496C117.431 7.496 117.063 7.576 116.775 7.736C116.503 7.88 116.367 8.088 116.367 8.36C116.367 8.632 116.519 8.832 116.823 8.96C117.127 9.072 117.639 9.2 118.359 9.344C119.287 9.536 119.935 9.688 120.303 9.8C121.295 10.12 122.063 10.552 122.607 11.096C123.167 11.64 123.447 12.368 123.447 13.28C123.447 14.528 122.951 15.496 121.959 16.184C120.983 16.856 119.663 17.192 117.999 17.192Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      default: 'sm',
      type: String,
    },
  },
}
</script>
