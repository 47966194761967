<template>
  <div class="overflow-hidden rounded-lg" @dragover="dragover">
    <div class="pb-12 relative">
      <div
        class=" h-52 bg-gray-200 bg-cover bg-center"
        :style="{
          backgroundImage: `url(${coverImageUrl})`
        }"
      >
        <Uploader
          reference-id="cover_file"
          allowed="images"
          bucket-type="image"
          :is-docked="true"
          :is-custom="true"
          :clear-after="true"
          :limit="1"
          @upload="updateImage('cover_file', $event)"
        >
          <div slot="navbar">
            <div class="w-full h-52 flex flex-col justify-end items-end p-3">
              <fw-button type="xlight">
                {{ $t('change_cover') }}
              </fw-button>
            </div>
          </div>
        </Uploader>
      </div>
      <div
        class="h-24 w-24 border-4 border-white rounded-2xl bg-cover bg-center bg-gray-200 absolute left-5 bottom-0"
        :style="{
          backgroundImage: `url(${logoImageUrl})`
        }"
      >
        <Uploader
          reference-id="logo_file"
          allowed="images"
          bucket-type="image"
          :is-docked="true"
          :is-custom="true"
          :clear-after="true"
          :limit="1"
          @upload="updateImage('logo_file', $event)"
        >
          <div slot="navbar">
            <div class="w-full h-20 flex flex-col justify-end">
              <fw-button type="xlight" class="-mb-2">
                {{ $t('change_image') }}
              </fw-button>
            </div>
          </div>
        </Uploader>
      </div>
    </div>

    <div class="flex flex-col p-5">
      <b-field :label="$t('title')">
        <b-input v-model="title"></b-input>
      </b-field>
      <b-field :label="$t('description')">
        <b-input v-model="description" maxlength="200" type="textarea"></b-input>
      </b-field>
      <b-field :label="$t('auto_accept_join')">
        <b-switch v-model="auto_accept_join"></b-switch>
      </b-field>
    </div>
    <div class="flex pb-5 px-5 gap-5">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="cancel()">
        {{ $t('cancel') }}
      </fw-button>
      <fw-button
        :type="!allowSave || loading ? 'disabled' : 'primary'"
        :disable="!allowSave || loading"
        :loading="loading"
        class="w-32"
        @click.native="save()"
      >
        {{ $t('save') }}
      </fw-button>
    </div>
  </div>
</template>

<script>
import ServiceContent from '../../services/ServiceContent'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
export default {
  name: 'ModalEditCommunity',
  components: {
    Uploader
  },
  props: {
    community: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      title: '',
      description: '',
      cover_file: null,
      logo_file: null,
      auto_accept_join: false,
      loading: false,
      showFullDragZoneUploader: false,
      uploadfiles: []
    }
  },
  computed: {
    allowSave() {
      return this.title.length > 0 && this.description.length > 0
    },
    coverImageUrl() {
      return this.cover_file ? this.imageThumbUrl(this.cover_file) : null
    },
    logoImageUrl() {
      return this.logo_file ? this.imageThumbUrl(this.logo_file) : null
    }
  },
  created() {
    console.log(this.community)
    //create copies of the object
    let community = JSON.parse(JSON.stringify(this.community))
    this.title = community.title
    this.description = community.description
    this.cover_file = community.cover
    this.logo_file = community.logo
    this.auto_accept_join = community.auto_accept_join
  },
  methods: {
    imageThumbUrl(file, size = 'max2k') {
      if (file == null) {
        return ''
      }
      return file.thumb_url_format
        .replace('{KEY}', file.key)
        .replace('{SIZE}', size)
        .replace('{TOKEN}', file.token)
        .replace('{FILENAME}', file.thumb_filename || file.filename)
    },
    updateImage(type, files) {
      console.log(type, files)
      if (files.length > 0) {
        this[type] = files[0].response.data.file
      }
      this.showFullDragZoneUploader = false
      this.saveImages()
    },
    // Handle uploads
    dragover(event) {
      event.preventDefault()
      if (!this.showFullDragZoneUploader) {
        this.showFullDragZoneUploader = true
      }
    },
    dragleave() {
      this.showFullDragZoneUploader = false
    },
    async save() {
      //verify name
      if (!this.allowSave) {
        return
      }
      this.loading = true
      try {
        let result = await ServiceContent.updateGroup(this.community.key, {
          title: this.title,
          description: this.description,
          cover_file: this.cover_file,
          logo_file: this.logo_file,
          auto_accept_join: this.auto_accept_join
        })
        console.log(result)
        this.$emit('reload')
        this.$emit('close')
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async saveImages() {
      try {
        let result = await ServiceContent.updateGroup(this.community.key, {
          cover_file: this.cover_file,
          logo_file: this.logo_file
        })
        console.log('saveImages', result)
        this.cover_file = result.data.group.cover
        this.logo_file = result.data.group.logo
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>
<i18n>
  {
    "pt": {
      "title": "Título",
      "description": "Descrição",
      "auto_accept_join": "Aceitar automaticamente pedidos de adesão",
      "cancel": "Cancelar",
      "save": "Guardar",
      "change_cover": "Alterar imagem de capa",
      "change_image": "Alterar"
    },
    "en": {
      "title": "Title",
      "description": "Description",
      "auto_accept_join": "Automatically accept join requests",
      "cancel": "Cancel",
      "save": "Save",
      "change_cover": "Change cover image",
      "change_image": "Change"
    }
  }
</i18n>
