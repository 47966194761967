<template>
  <fw-layout @back="backTo">
    <template #header-toolbar>
      <HeaderLanguagePanel />
    </template>
    <template #main-sidebar>
      <SidebarMain />
    </template>
    <template #main-content>
      <fw-panel :title="$t('communities')" featured class="mb-5">
        <template v-if="canCreateCommunity" #toolbar>
          <fw-button type="link" @click.native="newCommunity()">{{ $t('new_community') }}</fw-button>
        </template>
        <ContextualSearch
          :loading="loading"
          :filter-options="[]"
          :applied-filters="appliedFilters"
          :start-value="searchInput"
          @search="search"
        >
          <template #tags>
            <FilterTag
              v-for="(filter, f) in appliedFilters"
              :key="'filter_' + f"
              :text="getFilterText(filter)"
              :show-close-button="true"
              @close="deleteFilter(f)"
            ></FilterTag>
          </template>
        </ContextualSearch>
      </fw-panel>
      <fw-panel-info type="orange" boxed icon class="mb-5">
        <div>
          {{ $t('experimental_warning1') }}
          <span class="font-bold">{{ $t('experimental_warning2') }}</span
          >{{ $t('experimental_warning3') }}
          <a class="underline" href="mailto:support@ucframework.pt">support@ucframework.pt</a>.
        </div>
      </fw-panel-info>

      <LoadingPlaceholder v-if="loading" />
      <div v-else-if="groups.length > 0" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 mb-5">
        <CommunityRecord
          v-for="(instance, i) in groups"
          :key="'community_' + i"
          :show-join="false"
          :record="instance"
          @click.native="goToDocument(instance)"
        ></CommunityRecord>
      </div>
      <div v-else-if="!loading" class="bg-white rounded-md py-16 text-center text-gray-400">
        {{ $t('no_results') }}
      </div>
      <BlockPagination
        v-if="pagination && pagination.total_pages > 1"
        :total="pagination.total_items"
        :total-pages="pagination.total_pages"
        :per-page="pagination.active_limit"
        :current.sync="page"
        @page-changed="pageChanged"
      />
    </template>
    <template #modals>
      <fw-modal
        v-if="showNewCommunityModal"
        :active.sync="showNewCommunityModal"
        :can-cancel="true"
        size="min"
        @close="close"
      >
        <template #default>
          <ModalNewCommunity
            context="mondaecus"
            context-key="mondaecus"
            @close="close"
            @relaod="loadGroups"
          ></ModalNewCommunity>
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import ModalNewCommunity from '@/fw-modules/fw-core-vue/content/components/modals/ModalNewCommunity'
import SidebarMain from '@/components/sidebars/SidebarMain'
import ServiceContent from '@/fw-modules/fw-core-vue/content/services/ServiceContent'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/ui/legacy/components/header/HeaderLanguagePanel'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import CommunityRecord from '@/fw-modules/fw-core-vue/content/components/records/CommunityRecord'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag'
export default {
  components: {
    SidebarMain,
    ModalNewCommunity,
    LoadingPlaceholder,
    BlockPagination,
    CommunityRecord,
    HeaderLanguagePanel,
    ContextualSearch,
    FilterTag,
  },
  data() {
    return {
      groups: [],
      users: {},
      loading: true,
      showNewCommunityModal: false,
      searchInput: '',
      selectedUser: null,
      appliedFilters: [],
      filters: [
        {
          key: 'state',
          label: 'Estado',
          options: [
            {
              key: 'reset',
              label: 'Todos',
            },
            {
              key: 'draft',
              label: 'Em edição',
            },
            {
              key: 'running',
              label: 'Em curso',
            },
            {
              key: 'waiting',
              label: 'Publicados',
            },
            {
              key: 'scheduled',
              label: 'Agendados',
            },
            {
              key: 'ended',
              label: 'Terminados',
            },
          ],
        },
      ],
      sortBy: null,
      sortDirection: 'asc',
      page: 1,
      pagination: {
        active_limit: 50,
        current_page: 1,
        total_items: 0,
        total_pages: 1,
      },
    }
  },
  computed: {
    canCreateCommunity() {
      return true
    },
  },
  created() {
    this.loadGroups()
  },
  methods: {
    close() {
      this.showNewCommunityModal = false
    },
    newCommunity() {
      this.showNewCommunityModal = true
    },
    search(value) {
      this.searchInput = value.term
      this.page = 1
      this.loadGroups()
    },
    async loadGroups() {
      this.loading = true
      try {
        let response = await ServiceContent.getGroups({
          query: this.searchInput,
          page: this.page,
          limit: this.pagination.active_limit,
          sort_by: this.sortBy,
          sort_direction: this.sortDirection,
          filters: this.appliedFilters,
        })
        console.log('loadGroups')
        console.log(response.data)
        this.groups = response.data.groups
        this.pagination = response.data.pagination
        this.users = { ...this.users, ...response.data.users }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    backTo() {
      this.$router.go(-1)
    },
    setChat(chat) {
      this.chatActive = chat
    },
  },
}
</script>

<i18n>
    {
      "en": {
        "communities": "My Communities",
        "new_community": "New community",
        "experimental_warning1": "The Communities feature is in",
        "experimental_warning2": "testing phase",
        "experimental_warning3": ". If you encounter any errors or identify aspects that can be improved, please contact us at",
        "no_results": "No communities to show"
      },
      "pt": {
        "communities": "As minhas comunidades",
        "new_community": "Nova comunidade",
        "experimental_warning1": "A funcionalidade de Comunidades está em",
        "experimental_warning2": "fase de testes",
        "experimental_warning3": ". Caso se depare com algum erro ou identifique aspetos passíveis",
        "no_results": "Não existem comunidades para mostrar"
      }
    }
  </i18n>
