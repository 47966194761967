import store from '@/store'

import ViewDocuments from '../views/ViewDocuments.vue'
import ViewHome from '../views/ViewHome.vue'
import ViewCommunities from '../views/ViewCommunities.vue'
import ViewExploreCommunities from '../views/ViewExploreCommunities.vue'
import ViewCommunity from '../views/ViewCommunity.vue'
import ViewAbout from '../views/ViewAbout.vue'

import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'

export default [
  {
    path: '/',
    name: 'home',
    component: ViewHome,
    meta: { requiresRole: 'user' },
  },
  {
    path: '/about',
    name: 'about',
    component: ViewAbout,
    meta: { requiresRole: 'user' },
  },
  {
    path: '/explore',
    name: 'explore',
    component: ViewExploreCommunities,
    meta: { requiresRole: 'user' },
  },
  {
    path: '/documents',
    name: 'documents',
    component: ViewDocuments,
    meta: { requiresRole: 'user' },
  },
  {
    path: '/communities',
    name: 'communities',
    component: ViewCommunities,
    meta: { requiresRole: 'user' },
  },
  {
    path: '/community/:key/:view?',
    name: 'community',
    component: ViewCommunity,
    meta: { requiresRole: 'user' },
  },
  {
    path: '/t/:token',
    name: 'nonio_auth',
    meta: {
      requiresNoAuth: true,
      allowPlugin: true,
      redirect: async function(route, isLoggedIn) {
        let data
        try {
          data = await ServiceAuth.checkToken(route.params.token)
        } catch (error) {
          console.error(`Failed to check nonio token ${route.params.token}`, error)
        }

        if (!data) {
          return { name: isLoggedIn ? 'home' : 'login' }
        }

        if (data.token) {
          store.dispatch('setNewLogin', data)
        }

        if (data.context == 'units') {
          return { name: 'units' }
        } else {
          return { name: 'home' }
        }
      },
    },
  },
]
