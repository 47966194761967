<template>
  <div class="player-container flex justify-center items-center bg-black aspect-w-16 aspect-h-9 lg:aspect-none">
    <video controls width="250" muted class="aspect-w-16 aspect-h-9 lg:aspect-none">
      <source :src="src" :type="type" />
    </video>
  </div>
</template>

<script>
export default {
  name: 'VideoPlayer',
  props: {
    src: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'video/mp4'
    },
    poster: {
      type: String,
      default: null
    }
  }
}
</script>
