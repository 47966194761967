<template>
  <fw-layout :back-to-enable="false" :footer="false">
    <template #main-sidebar>
      <SidebarMain />
    </template>
    <template #header-toolbar>
      <HeaderLanguagePanel />
    </template>
    <template #main-content>
      <PanelUCIdHero :user="user" class="mt-5 mb-10" version="v2" />
      <fw-panel :title="$t('quick_access')">
        <div class="grid grid-cols-2 md:grid-cols-4 gap-3 justify-center mb-3 items-stretch">
          <button-quick-access
            :label="$t('groups')"
            :title="$t('communities')"
            @clicked="$router.push({ name: 'communities' })"
          />
          <button-quick-access
            :label="$t('files')"
            :title="$t('documents')"
            @clicked="$router.push({ name: 'documents' })"
          />
        </div>
      </fw-panel>
      <fw-panel v-if="communities?.length" :title="$t('myCommunities')" class="my-5">
        <ContentLoader v-if="loading" />
        <div v-else>
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-5">
            <CommunityRecord
              v-for="(instance, i) in communities"
              :key="'community_' + i"
              :show-join="false"
              :record="instance"
            ></CommunityRecord>
          </div>
        </div>
      </fw-panel>
    </template>
    <template #modals>
      <fw-modal
        v-if="showAboutModal"
        :active.sync="showAboutModal"
        :can-cancel="true"
        size="2xl"
        :width="null"
        paddingless
        @close="closeAboutModal"
      >
        <template #default>
          <PanelAboutMondaecus is-modal @close="closeAboutModal" />
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import ButtonQuickAccess from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccess'
import PanelUCIdHero from '@/fw-modules/fw-core-vue/id/components/panels/PanelUCIdHero'
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'
import CommunityRecord from '@/fw-modules/fw-core-vue/content/components/records/CommunityRecord'
import ServiceContent from '../fw-modules/fw-core-vue/content/services/ServiceContent'
import SidebarMain from '@/components/sidebars/SidebarMain'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/ui/legacy/components/header/HeaderLanguagePanel'
import PanelAboutMondaecus from '@/components/panel/PanelAboutMondaecus'
export default {
  components: {
    PanelUCIdHero,
    ButtonQuickAccess,
    ContentLoader,
    CommunityRecord,
    SidebarMain,
    HeaderLanguagePanel,
    PanelAboutMondaecus,
  },

  data() {
    return {
      loading: true,
      communities: [],
      showAboutModal: !localStorage.getItem('aboutMondaecus'),
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    meeting() {
      if (this.user && this.user.meeting) return this.user.meeting
      else return null
    },
  },

  mounted() {
    this.loadCommunities()
  },

  methods: {
    closeAboutModal() {
      //save on localsotrage
      localStorage.setItem('aboutMondaecus', 'true')
      this.showAboutModal = false
    },
    async loadCommunities() {
      this.loading = true
      try {
        const response = await ServiceContent.getGroups()
        this.communities = response.data.groups
      } catch (error) {
        console.error(error)
        this.loading = false
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<i18n>
  {
    "en": {
      "myCommunities": "My communities",
      "quick_access": "Quick access",
      "groups": "Groups",
      "communities": "Communities",
      "files": "Files",
      "documents": "Documents",
      "support": "Support",
      "do_you_need_help": "Do you need help?"
    },
    "pt": {
      "myCommunities": "Minhas comunidades",
      "quick_access": "Acesso rápido",
      "groups": "Grupos",
      "communities": "Comunidades",
      "files": "Arquivos",
      "documents": "Documentos",
      "support": "Suporte",
      "do_you_need_help": "Precisa de ajuda?"
    }
  }
</i18n>
