var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyToRepoModal flex-1 flex flex-col w-full"},[_c('div',{staticClass:"flex-1 flex flex-col gap-1",staticStyle:{"min-height":"200px"}},[(_vm.copying)?_c('div',[_c('Copying',{staticClass:"mx-auto mt-10"}),_c('div',{staticClass:"text-center text-xl text-gray-400 p-5"},[_vm._v("A copiar...")])],1):(_vm.page == 'bucket_selection')?_c('PanelBucketList',{attrs:{"permissions":['add_items'],"dark-scroll-area":true,"ignore-bucket":_vm.bucketKey,"max-scroll-height":_vm.maxScrollHeight,"list":true,"override-with-classes-list":true,"selectable-list":true,"is-modal":true},on:{"selected":_vm.selectedBuckets}}):_c('div',{staticClass:"pl-5 pr-5 pb-5"},[_c('div',{staticClass:"mb-5 font-semibold"},[_vm._v(" Copiar "+_vm._s(_vm.selectedFiles.length)+" items para "+_vm._s(_vm.selected.length)+" repositório(s) ")]),_c('div',{staticClass:"destination-selector p-5 rounded border-gray-200 border-2 cursor-pointer hover:shadow-lg bg-white mb-5 font-semibold select-none",class:{ 'border-teal-500': _vm.option == 'root', 'shadow-lg': _vm.option == 'root', selected: _vm.option == 'root' },on:{"click":function($event){return _vm.selectDest('root')}}},[_vm._v(" Raiz do(s) repositório(s) "),_c('div',{staticClass:"check_box"})]),_c('div',{staticClass:"destination-selector p-5 rounded border-gray-200 border-2 cursor-pointer hover:shadow-lg bg-white font-semibold select-none",class:{
          'border-teal-500': _vm.option == 'new_folder',
          'shadow-lg': _vm.option == 'new_folder',
          selected: _vm.option == 'new_folder'
        },on:{"click":function($event){return _vm.selectDest('new_folder')}}},[_c('div',{staticClass:"check_box"}),_c('div',{staticClass:"mb-3"},[_vm._v("Nova pasta no(s) repositório(s)")]),_c('div',{staticClass:"font-normal mb-1"},[_vm._v("Indique o nome da nova pasta:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_folder_name),expression:"new_folder_name"}],ref:"new_folder_input",staticClass:"input focus:outline-none focus:shadow-none focus:border-0",attrs:{"type":"text","placeholder":"e.g. A minha pasta","maxlength":"150","min":"2","required":"required"},domProps:{"value":(_vm.new_folder_name)},on:{"input":function($event){if($event.target.composing)return;_vm.new_folder_name=$event.target.value}}})])])],1),(!_vm.copying)?_c('div',{staticClass:"flex-shrink-0 flex flex-reverse items-center gap-1 px-5 pt-5"},[(_vm.page == 'bucket_selection')?_c('fw-button',{attrs:{"type":_vm.selected.length > 0 ? 'regular' : 'disabled'},nativeOn:{"click":function($event){return _vm.nextPage.apply(null, arguments)}}},[_vm._v(" Seguinte ")]):(!_vm.copying)?_c('fw-button',{attrs:{"type":((_vm.option == 'new_folder' && _vm.new_folder_name.length > 2) || _vm.option == 'root') && _vm.selected.length > 0
          ? 'regular'
          : 'disabled'},nativeOn:{"click":function($event){return _vm.copyToRepo.apply(null, arguments)}}},[_vm._v(" Copiar ")]):_vm._e(),(_vm.page !== 'bucket_selection' && _vm.copying === false)?_c('fw-button',{staticClass:"mr-3",nativeOn:{"click":function($event){return _vm.backPage.apply(null, arguments)}}},[_vm._v("Recomeçar")]):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }