<template>
  <div>
    <fw-panel boxed :title="$t('community_owner')" class="mb-5">
      <template #toolbar>
        <fw-button v-if="isOwner" type="link" @click.native="changeOwner">
          {{ $t('change_owner') }}
        </fw-button>
      </template>
      <div class="bg-white rounded-xl p-3">
        <Person :person="communityUsers[community.user_key]" :selectable="false" :clickable="false" :options="false">
          <template #options>
            <div
              v-if="connectedUsers[community.user_key]"
              class="py-1.5 pl-2.5 pr-3 gap-2 rounded-full text-sm font-semibold flex bg-primary/20 items-center text-black/60"
            >
              <div class="h-3 w-3 rounded-full bg-primary animate-pulse"></div>
              Online
            </div>
          </template>
        </Person>
      </div>
    </fw-panel>
    <fw-panel boxed :title="$t('members')">
      <template #toolbar>
        <fw-button v-if="isOwner" type="link" @click.native="choosePeople('members')">
          {{ $t('add_member') }}
        </fw-button>
      </template>
      <div class="bg-white rounded-xl">
        <div v-if="isOwner" class="flex h-12">
          <fw-button :type="tab == 'members' ? 'tab-active' : 'tab'" @click.native="openUsers()">
            {{ $t('active') }}
          </fw-button>
          <fw-button :type="tab == 'pending' ? 'tab-active' : 'tab'" @click.native="openPendingUsers()">
            {{ $t('pending') }}
            <div
              v-if="community.stats.users.pending > 0 && isOwner"
              class="block-inline bg-primary w-5 h-5 rounded-full text-white leading-5 ml-2"
            >
              {{ community.stats.users.pending }}
            </div>
          </fw-button>
        </div>
        <div class="p-3 flex flex-col gap-3">
          <loading-placeholder v-if="loading.members || loading.pending" />
          <div v-else-if="tab == 'members' && members.length > 0">
            <Person
              v-for="(member, e) in members"
              :key="'member' + e"
              :person="users[member.user_key]"
              :selectable="false"
              :clickable="false"
            >
              <template #options>
                <div class="flex gap-3 items-center">
                  <div
                    v-if="connectedUsers[member.user_key]"
                    class="py-1.5 pl-2.5 pr-3 gap-2 rounded-full text-sm font-semibold flex bg-primary/20 items-center text-black/60"
                  >
                    <div class="h-3 w-3 rounded-full bg-primary animate-pulse"></div>
                    Online
                  </div>
                  <b-dropdown v-if="isOwner" aria-role="list" position="is-bottom-left">
                    <template #trigger="{}">
                      <fw-button type="transparent" size="sm" class="text-gray-400 text-sm">
                        <fw-icon-more class="h-5 w-5" />
                      </fw-button>
                    </template>

                    <b-dropdown-item aria-role="listitem">
                      <div
                        class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                        @click="removeUser(member.user_key)"
                      >
                        {{ $t('remove') }}
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </Person>
          </div>
          <div v-else-if="tab == 'pending' && pending.length > 0">
            <Person
              v-for="(member, e) in pending"
              :key="'member' + e"
              :person="users[member.user_key]"
              :selectable="false"
              :clickable="false"
            >
              <template #options>
                <b-dropdown aria-role="list" position="is-bottom-left">
                  <template #trigger="{}">
                    <fw-button type="transparent" size="sm" class="text-gray-400 text-sm">
                      <fw-icon-more class="h-5 w-5" />
                    </fw-button>
                  </template>

                  <b-dropdown-item aria-role="listitem">
                    <div
                      class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                      @click="approveUser(member.user_key)"
                    >
                      {{ $t('approve') }}
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="listitem">
                    <div
                      class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                      @click="disaproveUser(member.user_key)"
                    >
                      {{ $t('reject') }}
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </Person>
          </div>
          <div v-else class="py-20 text-center text-gray-500">{{ $t('no_members') }}</div>
          <BlockPagination
            v-if="currentPagination.total_pages > 1 && !(loading.members || loading.pending)"
            :per-page="currentPagination.total_pages"
            :total="currentPagination.total_items"
            :total-pages="currentPagination.total_pages"
            :current.sync="currentPagination.current_page"
            @page-changed="pageChanged($event)"
          />
        </div>
      </div>
    </fw-panel>
    <b-modal
      :active="choosingPeopleType != null"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ChoosePeopleModal
        :title="selectPeopleTitle"
        :instructions="selectPeopleInstructions"
        :multiselect="multiselectPeople"
        :not-alowed-users="notAlowedUsers"
        :invite-people="false"
        :endpoint="searchEndpoint"
        @selected="selectedPeople"
        @close="closeModal"
      ></ChoosePeopleModal>
    </b-modal>
  </div>
</template>

<script>
import LoadingPlaceholder from '../../../ui/components/animation/LoadingPlaceholder.vue'
import FwButton from '../../../ui/components/buttons/FwButton.vue'
import FwPanel from '../../../ui/components/panels/FwPanel.vue'
import ServiceContent from '../../services/ServiceContent'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
export default {
  components: { FwPanel, FwButton, LoadingPlaceholder, Person, ChoosePeopleModal },
  props: {
    community: {
      type: Object,
      required: true
    },
    communityUsers: {
      type: Object,
      required: true
    },
    connectedUsers: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: {
        members: false,
        pending: false
      },
      members: [],
      pending: [],
      tab: 'members',
      pagination: {
        members: {
          current_page: 1,
          total_pages: 1,
          active_limit: 30,
          total_items: 0
        },
        pending: {
          current_page: 1,
          total_pages: 1,
          active_limit: 30,
          total_items: 0
        }
      },
      choosingPeopleType: null,
      users: {}
    }
  },
  computed: {
    notAlowedUsers() {
      let userKeys = this.members.map(el => el.key)
      if (this.community.user_key != null) {
        userKeys.push(this.community.user_key)
      }
      return userKeys
    },
    selectPeopleTitle() {
      let titlesMap = {
        members: this.$t('add_member_to_community'),
        owner: this.$t('change_community_owner')
      }
      return titlesMap[this.choosingPeopleType] ? titlesMap[this.choosingPeopleType] : 'Escolher pessoa'
    },

    selectPeopleInstructions() {
      let instructionsMap = {
        members: this.$t('select_members_to_community'),
        owner: this.$t('select_new_owner')
      }
      return instructionsMap[this.choosingPeopleType]
        ? instructionsMap[this.choosingPeopleType]
        : 'Seleccione um utilizador'
    },

    multiselectPeople() {
      return this.choosingPeopleType != 'owner'
    },
    currentPagination() {
      return this.tab == 'members' ? this.pagination.members : this.pagination.pending
    },
    isOwner() {
      return this.community.validations.is_owner
    }
  },
  created() {
    this.loadUsers()
  },
  methods: {
    removeUser(user_key) {
      //modal asking for confirmation
      this.$buefy.dialog.confirm({
        cancelText: this.$t('cancel'),
        confirmText: this.$t('remove'),
        title: this.$t('remove_member'),
        message: this.$t('remove_member_message'),
        onConfirm: async () => {
          try {
            let result = await ServiceContent.removeUserFromGroup(this.community.key, user_key)
            console.log(result)
            this.loadUsers()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    async approveUser(user_key) {
      try {
        let result = await ServiceContent.approveUserInGroup(this.community.key, [user_key])
        console.log(result)
        this.loadPendingUsers()
      } catch (e) {
        console.error(e)
      }
    },
    async disaproveUser(user_key) {
      try {
        let result = await ServiceContent.disapproveUserInGroup(this.community.key, [user_key])
        console.log(result)
        this.loadPendingUsers()
      } catch (e) {
        console.error(e)
      }
    },
    closeModal() {
      this.choosingPeopleType = null
    },
    choosePeople(type) {
      this.choosingPeopleType = type
    },
    changeOwner() {
      this.$buefy.dialog.confirm({
        cancelText: this.$t('cancel'),
        confirmText: this.$t('confirm'),
        title: this.$t('change_owner'),
        message: this.$t('change_owner_message'),
        onConfirm: async () => {
          this.choosePeople('owner')
        }
      })
    },
    async searchEndpoint(params) {
      return await ServiceContent.searchGroupUsers(this.community.key, params)
    },
    selectedPeople(selection) {
      //, extra = {}
      console.log(`selectedPeople type:${this.choosingPeopleType}`, selection)
      switch (this.choosingPeopleType) {
        case 'members':
          this.updateMembers(selection)
          break
        case 'owner':
          this.updateOwner(selection)
          break
        default:
          console.log('not a type')
      }
    },
    async updateOwner(selection) {
      console.log('updateOwner', selection)
      try {
        let result = await ServiceContent.changeGroupOwner(this.community.key, selection[0].key)
        console.log('updateOwner', result)
        this.$emit('reload')
      } catch (e) {
        console.error(e)
      }
    },
    async updateMembers(selection) {
      console.log('updateMembers', selection)
      try {
        let keys = selection.map(el => el.key)
        let result = await ServiceContent.addUsersToGroup(this.community.key, keys)
        console.log('updateMembers', result)
        this.loadUsers()
      } catch (e) {
        console.error(e)
      }
    },
    pageChanged(page) {
      if (this.tab == 'members') {
        this.pagination.members.current_page = page
        this.loadUsers()
      } else {
        this.pagination.pending.current_page = page
        this.loadPendingUsers()
      }
    },
    async loadUsers() {
      try {
        this.loading.members = true
        let result = await ServiceContent.getGroupUsers(this.community.key, this.pagination.members.current_page)
        console.log(result)
        this.pagination.members = result.data.pagination
        this.members = result.data.group_users
        this.users = { ...this.users, ...result.data.users }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading.members = false
      }
    },
    openPendingUsers() {
      this.loadPendingUsers()
      this.tab = 'pending'
    },
    openUsers() {
      this.loadUsers()
      this.tab = 'members'
    },
    async loadPendingUsers() {
      try {
        this.loading.pending = true
        let result = await ServiceContent.getPendingUsers(this.community.key, this.pagination.pending.current_page)
        this.pagination.pending = result.data.pagination
        this.pending = result.data.group_users
        this.users = { ...this.users, ...result.data.users }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading.pending = false
      }
    }
  }
}
</script>

<i18n>
  {
    "pt": {
      "community_owner": "Responsável da comunidade",
      "change_owner": "Alterar responsável",
      "add_member": "Adicionar membro",
      "active": "Ativos",
      "pending": "Pendentes",
      "remove": "Remover",
      "no_members": "Sem membros",
      "approve": "Aprovar",
      "reject": "Rejeitar",
      "members": "Membros",
      "change_owner_message": "Atenção que vai perder o acesso à comunidade. Tem a certeza que deseja alterar o responsável da comunidade?",
      "remove_member": "Remover membro",
      "remove_member_message": "Tem a certeza que deseja remover este membro da comunidade?",
      "cancel": "Cancelar",
      "confirm": "Confirmar",
      "add_member_to_community": "Adicionar membro à comunidade",
      "select_members_to_community": "Selecione os membros que deseja adicionar à comunidade",
      "change_community_owner": "Alterar responsável da comunidade",
      "select_new_owner": "Selecione o novo responsável da comunidade"
    },
    "en": {
      "community_owner": "Community owner",
      "change_owner": "Change owner",
      "add_member": "Add member",
      "active": "Active",
      "pending": "Pending",
      "remove": "Remove",
      "no_members": "No members",
      "approve": "Approve",
      "reject": "Reject",
      "members": "Members",
      "change_owner_message": "Attention you will lose access to the community. Are you sure you want to change the community owner?",
      "remove_member": "Remove member",
      "remove_member_message": "Are you sure you want to remove this member from the community?",
      "cancel": "Cancel",
      "confirm": "Confirm",
      "add_member_to_community": "Add member to community",
      "select_members_to_community": "Select the members you want to add to the community",
      "change_community_owner": "Change community owner",
      "select_new_owner": "Select the new community owner"

    }
  }
</i18n>
