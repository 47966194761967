<template>
  <div class="h-full">
    <panel-bucket
      v-if="community.bucket"
      :key="widgetKey"
      :prop-bucket-key="community.bucket"
      :folder-key="folderKey"
    />
    <div v-else class="py-20 text-center text-lg text-gray-500">
      {{ $t('file_not_available') }}
    </div>
  </div>
</template>

<script>
import PanelBucket from '../../../buckets/components/panels/PanelBucketv2.vue'
export default {
  components: { PanelBucket },
  props: {
    community: {
      type: Object,
      required: true
    },
    users: {
      type: Object,
      required: true
    }
  },
  computed: {
    folderKey() {
      return this.$route.query.folderKey || null
    },
    widgetKey() {
      return this.$route.query.folderKey || 'root'
    }
  }
}
</script>

<i18n>
  {
    "pt": {
      "file_not_available": "Ficheiros não disponíveis"
    },
    "en": {
      "file_not_available": "Files not available"
    }
  }
</i18n>
