<template>
  <fw-layout full mobile-ready back-to-listen-event @back="backTo">
    <template v-if="!loading" #main-content>
      <PanelGroupsEmpty v-if="!chats.length" class="w-full rounded-none" @set-chat="setChat" />
      <PanelChats
        v-else
        ref="chat"
        :chats="chats"
        :chat-users="chatUsers"
        :all-chat-users="allChatUsers"
        :chat-active="chatActive"
        :panel-for-groups="true"
        :view-chats-list="viewChatsList"
        :empty-title="'Não existem grupos para apresentar.'"
        :load-chat-on-create="loadChatOnCreate"
        @load-chat="loadChatAndSetNav"
        @load-main-chat="loadMainChat"
        @unload-chat="unloadChatAndSetNav"
        @set-chat="setChat"
        @set-chat-messages="setChatMessages"
        @update-chat="updateChat"
        @delete-chat-message="deleteChatMessage"
        @remove-chat="removeChat"
      />
    </template>
  </fw-layout>
</template>

<script>
import PanelChats from '@/fw-modules/fw-core-vue/chats/components/panels/PanelChats'
import ChatLive from '@/fw-modules/fw-core-vue/chats/mixins/ChatLive'
import ServiceChat from '@/fw-modules/fw-core-vue/chats/services/ServiceChat'
import PanelGroupsEmpty from '@/fw-modules/fw-core-vue/groups/components/panels/PanelGroupsEmpty'

export default {
  components: {
    PanelChats,
    PanelGroupsEmpty
  },

  mixins: [ChatLive],

  data() {
    this.loadGroups()
    const data = this.getChatDefaultData()
    data.loading = true
    data.loadChatOnCreate = true
    data.destroying = false
    data.viewChatsList = true
    return data
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    }
  },

  beforeDestroy() {
    this.destroying = true
    this.unregisterChat()
  },

  created() {
    this.registerChat()
  },

  methods: {
    backTo() {
      if (this.viewChatsList && !this.isMobile) this.$router.back()
      else this.viewChatsList = true
    },
    async loadGroups() {
      let hash = this.$route.hash
      hash = hash && hash.length > 2 ? hash.substr(1) : null

      const response = await ServiceChat.getChannels('group')
      for (let channel of response.channels) {
        let loadAfter = channel.meeting.key == hash
        this.setChat(channel, loadAfter)
        if (loadAfter) this.loadChatOnCreate = false
      }
      this.setAllChatUnread()
      this.loading = false
    },
    isInChatView() {
      return !this.destroying
    },
    loadChatAndSetNav($data) {
      this.viewChatsList = false
      this.loadChat($data)
    },
    unloadChatAndSetNav($data) {
      this.viewChatsList = true
      this.unloadChat($data)
    }
  }
}
</script>
