<template>
  <fw-layout :back-to-enable="false">
    <template #main-sidebar>
      <SidebarMain />
    </template>
    <template #header-toolbar>
      <HeaderLanguagePanel />
    </template>
    <template #main-content>
      <PanelAboutMondaecus />
    </template>
  </fw-layout>
</template>

<script>
import ServiceContent from '../fw-modules/fw-core-vue/content/services/ServiceContent'
import SidebarMain from '@/components/sidebars/SidebarMain'
import PanelAboutMondaecus from '@/components/panel/PanelAboutMondaecus'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/ui/legacy/components/header/HeaderLanguagePanel'
export default {
  components: {
    PanelAboutMondaecus,
    SidebarMain,
    HeaderLanguagePanel,
  },

  data() {
    return {
      loading: true,
      communities: [],
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    meeting() {
      if (this.user && this.user.meeting) return this.user.meeting
      else return null
    },
  },

  mounted() {
    this.loadCommunities()
  },

  methods: {
    async loadCommunities() {
      this.loading = true
      try {
        const response = await ServiceContent.getGroups()
        this.communities = response.data.groups
      } catch (error) {
        console.error(error)
        this.loading = false
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<i18n>
  {
    "en": {
      "myCommunities": "My communities"
    },
    "pt": {
      "myCommunities": "Minhas comunidades"
    }
  }
</i18n>
