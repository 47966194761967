<template>
  <div class="relative">
    <div
      class="h-52 bg-gray-100 relative bg-cover bg-center mb-5 rounded-2xl"
      :style="{
        backgroundImage: `url(${imageThumbUrl(community.cover, '800')})`
      }"
    ></div>
    <div
      class="h-20 w-20 rounded-2xl absolute top-[10.5rem] font-semibold left-5 border-4 border-gray-50 bg-cover bg-center"
      :style="{
        backgroundImage: `url(${imageThumbUrl(community.logo, '200')})`,
        backgroundColor: community.logo == null ? communityColor : '#efefef'
      }"
    >
      <div
        v-if="community.logo == null"
        class="h-full w-full text-3xl bg-white/70 flex flex-col items-center justify-center"
        :style="{
          color: communityColor
        }"
      >
        {{ community.title.charAt(0).toUpperCase() }}
      </div>
    </div>
    <div class="flex py-5 pl-5 pr-4 items-center">
      <div class=" flex-1">
        <fw-heading size="3xl">
          {{ community.title }}
        </fw-heading>
      </div>
      <div v-if="canJoin" class=" text-right text-sm text-gray-600 font-medium leading-4 px-4">
        {{ $t('join') }}<br />{{ community.stats.users.active }}
        {{ community.stats.users.active == 1 ? $t('member') : $t('members') }}
      </div>
      <fw-button v-if="!isMember && canJoin" :loading="loading" type="light-primary" size="md" @click.native="join">
        {{ $t('adere') }}
      </fw-button>
      <fw-button v-else-if="isMember && !isOwner" :loading="loading" type="xlight" size="md" @click.native="leave">
        {{ $t('exit') }}
      </fw-button>
    </div>
    <div class="px-5 pb-5 text-lg text-gray-500 font-semibold">
      {{ community.description }}
    </div>
  </div>
</template>

<script>
import FwHeading from '../../../ui/components/text/FwHeading.vue'
import ServiceContent from '../../services/ServiceContent'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
export default {
  components: { FwHeading },
  props: {
    community: {
      type: Object,
      required: true
    },
    users: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    communityColor() {
      return utils.stringToColor(this.community.key)
    },
    isOwner() {
      return this.community.validations.is_owner
    },
    isMember() {
      return this.community.validations.is_member || this.community.validations.is_owner
    },
    canJoin() {
      return this.community && this.community.validations.can_join && !this.isMember
    }
  },
  methods: {
    imageThumbUrl(file, size = 'max2k') {
      if (file == null) {
        return ''
      }
      return file.thumb_url_format
        .replace('{KEY}', file.key)
        .replace('{SIZE}', size)
        .replace('{TOKEN}', file.token)
        .replace('{FILENAME}', file.thumb_filename || file.filename)
    },
    join() {
      ServiceContent.joinGroup(this.community.key)
        .then(() => {
          if (this.community.auto_accept_join) {
            this.$buefy.toast.open({
              message: 'Aderiu à comunidade',
              type: 'is-success'
            })
          } else {
            this.$buefy.toast.open({
              message: 'Pedido de adesão enviado!',
              type: 'is-success'
            })
          }
          this.$emit('reload')
        })
        .catch(() => {
          this.$buefy.dialog.alert({
            title: 'Erro',
            message: 'Ocorreu um erro ao aderir à comunidade',
            type: 'is-danger'
          })
        })
    },
    leave() {
      ServiceContent.leaveGroup(this.community.key)
        .then(() => {
          this.$buefy.toast.open({
            message: 'Saiu da comunidade',
            type: 'is-success'
          })
          //go to homepage
          this.$router.push({ name: 'home' })
        })
        .catch(() => {
          this.$buefy.dialog.alert({
            title: 'Erro',
            message: 'Ocorreu um erro ao sair da comunidade',
            type: 'is-danger'
          })
        })
    }
  }
}
</script>

<i18n>
  {
    "pt": {
      "join": "Junta-te a outros",
      "member": "membro",
      "members": "membros",
      "adere": "Aderir",
      "exit": "Sair da comunidade"
    },
    "en": {
      "join": "Join others",
      "member": "member",
      "members": "members",
      "adere": "Join",
      "exit": "Leave community"
    }
  }
</i18n>
