import Api from '@/fw-modules/fw-core-vue/api/Api'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import store from '@/store'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlApply
    return api
  },

  baseUrl() {
    return FwEnvConfig.apiUrlApply
  },

  async getSupportInfo(params) {
    const response = await this.base().get(`/v1/space`, { params: params })
    return response.data
  },

  async getUserSupportTasks(params) {
    const response = await this.base().get(`/v1/tasks`, { params: params })
    return response.data
  },

  async getUserSupportTask(taskId, params) {
    const response = await this.base().get(`/v1/tasks/${taskId}`, { params: params })
    return response.data
  },

  async createUserSupportTasks(payload) {
    const response = await this.base().post(`/v1/tasks`, payload)
    return response.data
  },

  async getUserDetails(key, data = {}) {
    const response = await this.base().get(`/v1/user/${key}`, { params: data })
    return response.data
  },

  async updateUser(key, data) {
    const response = await this.base().post(`/v1/user/${key}`, data)
    return response.data
  },

  async deleteInviteUser(key) {
    const response = await this.base().delete(`/v1/user/${key}`)
    return response.data
  },

  async getUserInfo() {
    const response = await this.base().get('/v1/user/me')
    return response.data
  },

  async getOrganicUnits() {
    const response = await this.base().get('/v1/organic-units')
    return response.data
  },

  async getCountries() {
    const response = await this.base().get('/v1/countries')
    return response.data
  },

  async getPhoneCountries() {
    const response = await this.base().get('/v1/phone-countries')
    return response.data
  },

  async getProcedureTypes() {
    const response = await this.base().get('/v1/procedure-types')
    return response.data
  },

  async getSettings() {
    const response = await this.base().get('/v1/global-settings')
    return response.data
  },

  async getOption(key) {
    const response = await this.base().get(`/v1/options/${key}`)
    return response.data
  },

  async setOption(key, value) {
    const response = await this.base().post(`/v1/options/${key}`, { value })
    return response.data
  },

  async getProcedures(data) {
    const response = await this.base().get('/v1/procedures', { params: data })
    return response.data
  },

  async searchPublicProcedures(params) {
    const response = await this.base().get('/v1/procedures/search', { params })
    return response.data
  },

  async searchProceduresByCode(params) {
    const response = await this.base().get('/v1/procedures/search/code', { params })
    return response.data
  },

  async getProceduresWithPermissions(data) {
    const response = await this.base().get('/v1/manage/procedures', { params: data })
    return response.data
  },

  async getCandidates(data) {
    const response = await this.base().get('/v1/applications/all', { params: data })
    return response.data
  },

  async getProcedure(code, params) {
    const response = await this.base().get(`/v1/procedures/${code}`, { params })
    return response.data
  },

  async getProcedureByKey(key, sections = []) {
    const response = await this.base().get(`/v1/manage/procedures/${key}`, { params: { sections } })
    return response.data
  },

  async getProceduresStats() {
    const response = await this.base().get('/v1/procedures/stats')
    return response.data
  },

  async getApplications() {
    const response = await this.base().get('/v1/applications')
    return response.data
  },

  async updateUsersPermissions(userKey, roles) {
    const response = await this.base().post(`/v1/user/${userKey}/permission/update`, { roles: roles })
    return response.data
  },

  async startApplication(procedureKey) {
    const response = await this.base().post(`/v1/application/${procedureKey}/start`)
    return response.data
  },

  async getApplication(procedureKey) {
    const response = await this.base().get(`/v1/application/${procedureKey}`)
    return response.data
  },

  async updateApplication(application) {
    const response = await this.base().post(`/v1/application/${application.code}`, application)
    return response.data
  },

  async addListEntry(applicationCode, listKey, entry) {
    const response = await this.base().post(`/v1/application/${applicationCode}/list/${listKey}`, entry)
    return response.data
  },

  async updateListEntry(applicationCode, listKey, entry) {
    const response = await this.base().post(`/v1/application/${applicationCode}/list/${listKey}/${entry.key}`, entry)
    return response.data
  },

  async deleteListEntry(applicationCode, listKey, entryKey) {
    const response = await this.base().delete(`/v1/application/${applicationCode}/list/${listKey}/${entryKey}`)
    return response.data
  },

  async deleteFile(fileKey) {
    const response = await this.base().delete(`/v1/application/file/${fileKey}`)
    return response.data
  },

  async relateFile(applicationCode, fileType, fileKey) {
    const response = await this.base().post(`/v1/application/${applicationCode}/relate-file/${fileType}/${fileKey}`)
    return response.data
  },

  async updateFile(file) {
    const response = await this.base().delete(`/v1/application/file/${file.key}`, {
      title: file.title,
      reserved: file.reserved
    })
    return response.data
  },

  async getCandidatePresenceFile(candidateKey, fileName) {
    const response = await this.base().get(`/f/procedure-candidates-presence/${candidateKey}/${fileName}`, {
      responseType: 'blob'
    })
    return response.data
  },

  async getCandidatesZip(procedureKey, fileName, option) {
    const response = await this.base().get(`/f/procedure-candidates-zip/${procedureKey}/${fileName}`, {
      responseType: 'blob',
      params: { option }
    })
    return response.data
  },

  async getCandidateApplication(candidateKey, withEvaluations = false, withComplaints = false) {
    const response = await this.base().get(`/v1/candidate/${candidateKey}`, {
      params: { evaluations: withEvaluations, complaints: withComplaints }
    })
    return response.data
  },

  async getProcedureCandidates(procedureKey) {
    const response = await this.base().get(`/v1/candidates/${procedureKey}`)
    return response.data
  },

  async createProcedure(requestData, type) {
    const response = await this.base().post(`/v1/procedures/${type}/new`, requestData)
    return response.data
  },

  async deleteProcedure(procedureKey) {
    const response = await this.base().delete(`/v1/procedures/${procedureKey}/remove`)
    return response.data
  },

  async updateProcedure(key, requestData, sections) {
    const response = await this.base().post(`/v1/procedures/${key}/update`, requestData, { params: { sections } })
    return response.data
  },

  async publishProcedure(key) {
    const response = await this.base().post(`/v1/procedures/${key}/publish`)
    return response.data
  },

  // Open procedure and send email to jury
  async openProcedure(procedureKey, payload) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/open`, payload)
    return response.data
  },

  async startProcedure(procedureKey, requestData) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/start`, requestData)
    return response.data
  },

  async endProcedure(procedureKey) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/end`)
    return response.data
  },

  async closeProcedure(procedureKey) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/close`)
    return response.data
  },

  async createProcedureUpdate(procedureKey, requestData) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/update/new`, requestData)
    return response.data
  },

  async deleteFileUpdateProcedure(procedureKey, updateKey, file) {
    const response = await this.base().delete(`/v1/procedures/${procedureKey}/update/${updateKey}/file/${file.key}`, {
      title: file.title,
      reserved: file.reserved
    })
    return response.data
  },

  async getFiles(data) {
    const response = await this.base().get(`/v1/files`, { params: data })
    return response.data
  },

  async getUsers(data) {
    const response = await this.base().get(`/v1/users`, { params: data })
    return response.data
  },

  async getUsersFromEndpoint(endpoint, data) {
    const response = await this.base().get(endpoint, { params: data })
    return response.data
  },

  async getInvitedUsers(data) {
    const response = await this.base().get(`/v1/invite/users`, { params: data })
    return response.data
  },

  async addUser(requestData) {
    // {
    //   "key": "gku7rz",
    //   "professional_category": "full_professor",
    //   "professional_category_custom": "professional_category_custom",
    //   "university": "university"
    // }
    const response = await this.base().post(`/v1/users/new`, requestData)
    return response.data
  },

  async inviteUser(requestData) {
    const response = await this.base().post(`/v1/invite/users`, requestData)
    return response.data
  },

  async sendInviteEmail(key) {
    const response = await this.base().post(`/v1/invite/users/${key}/send`)
    return response.data
  },

  async searchIdUsers(data) {
    const response = await this.base().get(`/v1/id/users`, { params: data })
    return response.data
  },

  async getProcedureJuryInfo(procedureKey) {
    const response = await this.base().get(`/v1/procedures/${procedureKey}/jury`)
    return response.data
  },

  async getProcedureUpdates(key) {
    const response = await this.base().get(`/v1/procedures/${key}/updates`)
    return response.data
  },

  async getProcedureUpdate(key, updateKey) {
    const response = await this.base().get(`/v1/procedures/${key}/update/${updateKey}`)
    return response.data
  },

  async editProcedureUpdate(key, updateKey, requestData) {
    const response = await this.base().post(`/v1/procedures/${key}/update/${updateKey}/update`, requestData)
    return response.data
  },

  async relateUpdateFile(key, updateKey, fileKey, locale) {
    //api/v1/procedures/:key/update/:updateKey/relate-file
    const response = await this.base().post(`/v1/procedures/${key}/update/${updateKey}/relate-file/${fileKey}`, {
      locale
    })
    return response.data
  },

  async deleteProcedureUpdate(key, updateKey) {
    const response = await this.base().delete(`/v1/procedures/${key}/update/${updateKey}/delete`)
    return response.data
  },

  async updateManager(key, managerKey, transferData) {
    const response = await this.base().post(`/v1/procedures/${key}/manager`, {
      manager_key: managerKey,
      transfer_message: transferData && transferData.message ? transferData.message : null
    })
    return response.data
  },

  async updatePresidents(key, presidentData, presidentSubtituteData) {
    const requestData = {
      president: presidentData,
      president_substitute: presidentSubtituteData
    }
    const response = await this.base().post(`/v1/procedures/${key}/presidents`, requestData)
    return response.data
  },

  async updateMembers(key, membersData) {
    const response = await this.base().post(`/v1/procedures/${key}/members`, { vogals: membersData })
    return response.data
  },

  async setCandidatesAdmission(key, candidateKey, selected = null, rejectedReason = null) {
    let requestData = {
      candidate: candidateKey,
      admission_status: selected === true ? 'selected' : 'rejected',
      rejected_reason: rejectedReason
    }
    const response = await this.base().post(`/v1/candidates/${key}`, requestData)
    return response.data
  },

  async setBulkCandidatesAdmission(key, candidates, selected = null) {
    const response = await this.base().post(`/v1/candidates/${key}/admission`, {
      candidates,
      admission_status: selected === true ? 'selected' : 'rejected'
    })
    return response.data
  },

  async setPresidenteVote(procedureKey, type, vote) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/${type}/president-vote`, {
      vote: vote
    })
    return response.data
  },

  async startMeeting(procedureKey, type) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/${type}/start/save`)
    return response.data
  },

  async endMeeting(procedureKey, type) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/${type}/end/save`)
    return response.data
  },

  async undoEndMeeting(procedureKey, type) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/${type}/undo/save`)
    return response.data
  },

  async closeMeeting(procedureKey, type) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/${type}/close`)
    return response.data
  },

  async setMeetingPresences(procedureKey, userKey, type, value) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/${type}/meeting-presences/${userKey}`, {
      value: value
    })
    return response.data
  },

  async setInterviewDecision(procedureKey, with_interview, interview_by_unanimity, interview_description = null) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/admission/interview`, {
      with_interview: with_interview,
      interview_by_unanimity: interview_by_unanimity,
      interview_description: interview_description
    })
    return response.data
  },

  async setCandidatesAttendance(procedureKey, requestData) {
    const response = await this.base().post(`/v1/candidates/${procedureKey}`, requestData)
    return response.data
  },

  async endCandidatesAttendance(procedureKey) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/interview/endAttendance`)
    return response.data
  },

  async setCandidatesEvaluation(key, juryKey, requestData) {
    const response = await this.base().post(`/v1/procedures/${key}/evaluations/${juryKey}`, requestData)
    return response.data
  },

  async getJuryEvaluations(key, juryKey, step) {
    const response = await this.base().get(`/v1/procedures/${key}/evaluations/${juryKey}`, { params: { type: step } })
    return response.data
  },

  async editJury(juryKey, payload) {
    const response = await this.base().post(`/v1/jury/edit/${juryKey}`, payload)
    return response.data
  },

  async sealJuryEvaluations(juryKey, type) {
    const response = await this.base().post(`/v1/jury/${type}/${juryKey}/seal`)
    return response.data
  },

  async undoSealJuryEvaluations(juryKey, type) {
    const response = await this.base().post(`/v1/jury/${type}/${juryKey}/undo`)
    return response.data
  },

  async setMeritInterview(juryKey, requestData) {
    // requestData example:
    // {
    //     "admitted": true,
    //     "forInterview": true,
    //     "setMerit": "a14ece80aefa",
    //     "merit": true,
    //     "merit_reason": "Considero, tendo em conta os critérios de seleção e os parâmetros de avaliação indicados no ponto IV.2. do Edital, não ponderados quantitativamente, que todos os candidatos a concurso reúnem os requisitos para serem aprovados em mérito absoluto."
    // }
    const response = await this.base().post(`/v1/jury/interview/${juryKey}`, requestData)
    return response.data
  },

  async sealMeritInterview(juryKey) {
    const response = await this.base().post(`/v1/jury/merit-interview/${juryKey}/seal`)
    return response.data
  },

  async undoSealMeritInterview(juryKey) {
    const response = await this.base().post(`/v1/jury/merit-interview/${juryKey}/undo`)
    return response.data
  },

  async endMeritInterview(juryKey) {
    const response = await this.base().post(`/v1/jury/interview/${juryKey}/endMerit`)
    return response.data
  },

  async getFinalResults(procedureKey) {
    const response = await this.base().get(`/v1/procedures/${procedureKey}/results`)
    return response.data
  },

  async getStepsInfo(procedureKey, type) {
    const response = await this.base().get(`/v1/procedures/${procedureKey}/seriation/${type}`)
    return response.data
  },

  async getSeriation(procedureKey, type, returnHtml = true) {
    const response = await this.base().get(`/v1/seriation-${type}/${procedureKey}`, { returnHtml: returnHtml })
    return response.data
  },

  async runSeriation(procedureKey, type) {
    const response = await this.base().post(`/v1/seriation-${type}/${procedureKey}/run`)
    return response.data
  },

  async cancelSeriation(procedureKey, type) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/${type}/cancel`)
    return response.data
  },

  async rejectCandidates(procedureKey, type, candidates) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/candidates`, {
      step: type,
      candidates: candidates
    })
    return response.data
  },

  async getComplaints(procedureCode) {
    const response = await this.base().get(`/v1/application/${procedureCode}/complaints`)
    return response.data
  },

  async getCurrentUserComplaints(procedureCode) {
    const response = await this.base().get(`/v1/application/${procedureCode}/candidate/complaints`)
    return response.data
  },

  async createComplaint(procedureCode, description) {
    const response = await this.base().post(`/v1/application/${procedureCode}/complaint`, { description: description })
    return response.data
  },

  async createComplaintMessage(complaintKey, description) {
    const response = await this.base().post(`/v1/complaints/${complaintKey}/message`, { description: description })
    return response.data
  },

  async updateComplaintStatus(complaintKey, status) {
    const response = await this.base().post(`/v1/complaints/${complaintKey}/status`, { status: status })
    return response.data
  },

  async getProcedureUpdateFile(procedureFileKey, fileName) {
    const response = await this.base().get(`/f/procedure/update/${procedureFileKey}/${fileName}`, {
      responseType: 'blob'
    })
    return response.data
  },

  async getStepProcedureDocument(procedureKey, fileName, type, locale = 'pt') {
    const response = await this.base().get(`/f/procedure/document/${type}/${procedureKey}/${fileName}`, {
      params: { locale },
      responseType: 'blob'
    })
    return response.data
  },

  async sendInterviewEmails(procedureKey, candidate = null) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/interview-notifications`, { candidate })
    return response.data
  },

  async sendPublishedEmails(procedureKey) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/published-notifications`)
    return response.data
  },

  async sendNotifyCalendar(procedureKey, meeting, message) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/notify/calendar`, { meeting, message })
    return response.data
  },

  async sendResultsNotifications(procedureKey, type = 'final') {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/notify/results`, { code: type })
    return response.data
  },

  async requestEvaluationMapsControl(juryKey, step) {
    const response = await this.base().post(`/v1/evaluations-control/${juryKey}/request`, null, {
      params: { type: step }
    })
    return response.data
  },

  async giveEvaluationMapsControl(juryKey, step) {
    const response = await this.base().post(`/v1/evaluations-control/${juryKey}/get`, null, { params: { type: step } })
    return response.data
  },

  async updateEvaluationMapsTimestamp(juryKey, type, step) {
    const response = await this.base().post(`/v1/evaluations-control/${juryKey}/update/${type}`, null, {
      params: { type: step }
    })
    return response.data
  },

  async getEvaluationMapsControlInfo(juryKey, step) {
    const response = await this.base().get(`/v1/evaluations-control/${juryKey}`, { type: step })
    return response.data
  },

  async relateComplaintFile(complaintKey, fileKey) {
    const response = await this.base().post(`/v1/complaints/${complaintKey}/relate-file/${fileKey}`)
    return response.data
  },

  async relateComplaintMessageFile(complaintKey, fileKey) {
    const response = await this.base().post(`/v1/complaints/message/${complaintKey}/relate-file/${fileKey}`)
    return response.data
  },

  async getUserWeekEvents(weekNumber, year, payload = {}) {
    const response = await this.base().get(`/v1/user/events/week/${weekNumber}/${year}`, { params: payload })
    return response.data
  },

  async getUserUpNext(payload = {}) {
    const response = await this.base().get('/v1/user/upnext', { params: payload })
    return response.data
  },

  // ActivityAPI handler
  async getActivityLogs(payload) {
    // PAYLOAD ===
    // "service": "apply",
    // "page": 1,
    // "start_date": "2022-01-01",
    // "end_date": "2022-12-31",
    // "reference_keys": {
    // },
    // "code": "",
    // "context": "",
    // "context_key": "",
    // "by_user_key": "",
    // "by_token_key": ""
    const response = await this.base().get('/v1/activity', { params: payload })
    return response.data
  },

  async getNotifications(payload) {
    const response = await this.base().get('/v1/notifications', { params: payload })
    return response.data
  },

  async getNotification(key) {
    const response = await this.base().get(`/v1/notifications/${key}`)
    return response.data
  },

  async saveManagerReview(candidateKey, payload) {
    const response = await this.base().post(`/v1/candidate/${candidateKey}/manager-review`, payload)
    return response.data
  },

  async deleteManagerReview(candidateKey) {
    const response = await this.base().delete(`/v1/candidate/${candidateKey}/manager-review`)
    return response.data
  },

  async setCandidatesInterview(procedureKey, payload) {
    const response = await this.base().post(`/v1/procedures/${procedureKey}/candidates-interview`, payload)
    return response.data
  },

  createProcSubscription(procKey, pageName) {
    const subscriptionName = `procedure-${procKey}-${pageName}`
    const cls = {
      authUserKey: store.getters.getUser.key,
      page: pageName,
      key: procKey,

      subscribe() {
        const data = {
          application: 'apply',
          code: 'subscribe',
          key: cls.key,
          page: cls.page
        }

        store.commit('sendWSMessage', data)
        console.debug('Procedure subscribe', procKey)
      },

      unsubscribe() {
        const data = {
          application: 'apply',
          code: 'unsubscribe',
          key: cls.key,
          page: cls.page
        }

        store.commit('sendWSMessage', data)
        console.debug('Procedure unsubscribe', procKey)
      },

      reconnect() {
        cls.subscribe()
      },

      WSMessages(message) {
        console.debug('Received WSMessages', message)
        if (message.user_connected) message = message.user_connected
        else if (message.user_disconnected) message = message.user_disconnected
        else if (message.subscribe) message = message.subscribe[0]
        if (message.key && cls.key == message.key && message.page && cls.page == message.page) {
          store.dispatch('setConnectedUsers', { page: message.page, users: message.users })
        }
      },

      destroy() {
        cls.unsubscribe()
        store.commit('unsubscribeWS', { code: 'ws-reconnect', name: subscriptionName })
        store.commit('unsubscribeWS', { code: 'apply', name: subscriptionName })
      }
    }

    store.commit('subscribeWS', { code: 'ws-reconnect', name: subscriptionName, callback: cls.reconnect })
    store.commit('subscribeWS', { code: 'apply', name: subscriptionName, callback: cls.WSMessages })
    cls.subscribe()
    return cls
  }
}
