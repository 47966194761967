var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"h-52 bg-gray-100 relative bg-cover bg-center mb-5 rounded-2xl",style:({
      backgroundImage: `url(${_vm.imageThumbUrl(_vm.community.cover, '800')})`
    })}),_c('div',{staticClass:"h-20 w-20 rounded-2xl absolute top-[10.5rem] font-semibold left-5 border-4 border-gray-50 bg-cover bg-center",style:({
      backgroundImage: `url(${_vm.imageThumbUrl(_vm.community.logo, '200')})`,
      backgroundColor: _vm.community.logo == null ? _vm.communityColor : '#efefef'
    })},[(_vm.community.logo == null)?_c('div',{staticClass:"h-full w-full text-3xl bg-white/70 flex flex-col items-center justify-center",style:({
        color: _vm.communityColor
      })},[_vm._v(" "+_vm._s(_vm.community.title.charAt(0).toUpperCase())+" ")]):_vm._e()]),_c('div',{staticClass:"flex py-5 pl-5 pr-4 items-center"},[_c('div',{staticClass:"flex-1"},[_c('fw-heading',{attrs:{"size":"3xl"}},[_vm._v(" "+_vm._s(_vm.community.title)+" ")])],1),(_vm.canJoin)?_c('div',{staticClass:"text-right text-sm text-gray-600 font-medium leading-4 px-4"},[_vm._v(" "+_vm._s(_vm.$t('join'))),_c('br'),_vm._v(_vm._s(_vm.community.stats.users.active)+" "+_vm._s(_vm.community.stats.users.active == 1 ? _vm.$t('member') : _vm.$t('members'))+" ")]):_vm._e(),(!_vm.isMember && _vm.canJoin)?_c('fw-button',{attrs:{"loading":_vm.loading,"type":"light-primary","size":"md"},nativeOn:{"click":function($event){return _vm.join.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('adere'))+" ")]):(_vm.isMember && !_vm.isOwner)?_c('fw-button',{attrs:{"loading":_vm.loading,"type":"xlight","size":"md"},nativeOn:{"click":function($event){return _vm.leave.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('exit'))+" ")]):_vm._e()],1),_c('div',{staticClass:"px-5 pb-5 text-lg text-gray-500 font-semibold"},[_vm._v(" "+_vm._s(_vm.community.description)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }