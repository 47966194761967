<template>
  <fw-panel :title="$t('new_community')">
    <div class="flex flex-col">
      <b-field :label="$t('title')">
        <b-input v-model="title"></b-input>
      </b-field>
      <b-field :label="$t('description')">
        <b-input v-model="description" maxlength="200" type="textarea"></b-input>
      </b-field>
    </div>
    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="cancel()">
        {{ $t('cancel') }}
      </fw-button>
      <fw-button
        :type="!allowCreate || loading ? 'disabled' : 'primary'"
        :disable="!allowCreate || loading"
        class="w-32"
        @click.native="create()"
      >
        {{ $t('create') }}
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
import ServiceContent from '@/fw-modules/fw-core-vue/content/services/ServiceContent'
export default {
  name: 'ModalNewCommunity',
  data() {
    return {
      title: '',
      description: '',
      auto_accept_join: false,
      loading: false
    }
  },
  computed: {
    allowCreate() {
      return this.title.length > 0 && this.description.length > 0
    }
  },
  methods: {
    async create() {
      //verify name
      if (this.title.length == 0 || this.description.length == 0) {
        return
      }
      this.loading = true
      try {
        let result = await ServiceContent.createGroup({
          title: this.title,
          description: this.description,
          public: true,
          auto_accept_join: this.auto_accept_join
        })
        console.log(result)
        this.$router.push({ name: 'community', params: { key: result.data.key } })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    cancel() {
      if (!this.loading) {
        this.$emit('close')
      }
    }
  }
}
</script>

<i18n>
{
  "en": {
    "new_community": "New community",
    "see_all_languages": "See all languages",
    "title": "Title",
    "description": "Description",
    "new_document_translation": "New document translation",
    "select_an_option": "Select a language",
    "search_language_example": "e.g. English - American",
    "cancel": "Cancel",
    "create": "Create"
  },
  "pt": {
    "new_community": "Nova comunidade",
    "title": "Título",
    "description": "Descrição",
    "see_all_languages": "Ver todos os idiomas",
    "new_document_translation": "Nova tradução de documento",
    "select_an_option": "Selecione um idioma",
    "search_language_example": "exemplo, English - American",
    "cancel": "Cancelar",
    "create": "Criar"
  }
}
</i18n>
