var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative w-5 h-6 block rounded-md",class:[_vm.inactiveColor]},[_c('svg',{staticClass:"absolute fill-current",class:{
      'text-primary': _vm.order === 'ASC',
      'opacity-90': _vm.order === 'ASC',
      '-top-1.5 -left-1': _vm.size === 'md',
      '-top-1 -left-0.5 h-6 w-6': _vm.size === 'sm'
    },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M12 11.828l-2.828 2.829-1.415-1.414L12 9l4.243 4.243-1.415 1.414L12 11.828z"}})]),_c('svg',{staticClass:"absolute fill-current",class:{
      'text-primary': _vm.order === 'DESC',
      'opacity-90': _vm.order === 'DESC',
      '-bottom-1.5 -left-1': _vm.size === 'md',
      '-bottom-1 -left-0.5 h-6 w-6': _vm.size === 'sm'
    },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }