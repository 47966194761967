<template>
  <div v-if="bucket" class="panel-bucket w-full h-full flex justify-between">
    <div class="flex-1 h-full bg-white">
      <div class="flex flex-col h-full relative">
        <!-- Bucket header -->
        <div
          :id="'item-' + bucketDragInfo.key"
          class="flex flex-col gap-4 pl-5 pr-3 py-1 shadow"
          @drop="dropToFolder(bucketDragInfo.key)"
          @dragenter="dragEnter(bucketDragInfo, $event)"
          @dragleave="dragLeave(bucketDragInfo)"
          @dragover="eventPreventDefault"
        >
          <div v-if="showHeader" class="w-full flex gap-4 items-center pt-5">
            <div class="hidden md:flex relative">
              <div
                class="rounded-full p-2 bg-gray-50 text-teal-600"
                :class="{
                  'cursor-pointer': folder || breadcrumbs.length
                }"
              >
                <button
                  v-if="folder || breadcrumbs.length"
                  aria-label="Voltar ao início do repositório"
                  @click="goToBucket"
                >
                  <fw-icon-box-open class="w-14 h-14 opacity-80" />
                </button>
                <fw-icon-box-open v-else class="w-14 h-14 opacity-80" />
              </div>
              <!-- shared/unit_edition badge -->
              <div
                v-if="
                  (bucket.shared || bucket.type == 'unit_edition' || bucket.type == 'unit') &&
                    bucket.type !== 'class_edition'
                "
                class="absolute shadow bottom-1 right-0.5 bg-teal-500 rounded-full h-6 w-6 px-1 py-1 text-center"
              >
                <svg
                  v-if="bucket.type == 'unit_edition' || bucket.type == 'unit'"
                  title="Repositório da disciplina"
                  class="shared-bucket text-white fill-current w-full h-full"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M2 19h20v2H2v-2zM2 5l5 3 5-6 5 6 5-3v12H2V5z" />
                </svg>
                <svg
                  v-else
                  title="Repositório partilhado"
                  class="shared-bucket text-white fill-current w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z"
                  />
                </svg>
              </div>
            </div>
            <div class="flex-1">
              <fw-heading
                :size="isMobile ? 'h2' : 'h1'"
                class="group gap-1 items-center rounded-xl inline-flex px-2 py-1 -mx-2 -my-1 w-full"
                :class="{
                  'cursor-pointer hover:bg-gray-50': canEdit && bucket.type !== 'unit_edition' && bucket.type !== 'unit'
                }"
                @click.native="editBucketTitle"
              >
                <v-clamp autoresize :max-lines="1">
                  {{ bucket ? bucket.title : $t('no_title_bucket') }}
                </v-clamp>
                <fw-button
                  v-if="canEdit && !isMobile && bucket.type !== 'unit_edition' && bucket.type !== 'unit'"
                  type="light"
                  size="sm"
                  class="ml-2 flex items-center gap-1 group-hover:bg-opacity-0 opacity-30 group-hover:opacity-70"
                  ><fw-icon-edit class="w-4 h-4"
                /></fw-button>
              </fw-heading>
              <div
                v-if="
                  bucket.context !== null &&
                    (bucket.type == 'unit_edition' || bucket.type == 'unit' || bucket.type == 'class_edition')
                "
                class="md:hidden text-gray-500 text-sm mt"
              >
                <div class="flex">
                  <span
                    v-if="bucket.context.profile_initial"
                    class="bg-gray-100 rounded px-1 py-0.5 -mt-0.5 font-semibold mr-1"
                  >
                    {{ bucket.context.profile_initial }}
                  </span>
                  <span
                    v-else-if="bucket.type === 'unit'"
                    class="bg-gray-100 rounded px-1 py-0.5 font-semibold mr-1 text-primary text-xs"
                  >
                    {{ $t('general') }}
                  </span>
                  <v-clamp autoresize :max-lines="1">
                    {{ bucket.context.unit_title }}
                  </v-clamp>
                  <span>&nbsp;-&nbsp;</span>
                  <span
                    v-if="bucket.context.regime && bucket.context.regime === 'semiannual'"
                    class="whitespace-nowrap flex-shrink-0"
                  >
                    {{ bucket.context.regime_occurrence }}º&nbsp;{{ $t('semester') }}&nbsp;
                  </span>
                  <span>{{ bucket.context.academic_year }}</span>
                </div>
              </div>
              <div class="gap-5 flex-wrap hidden md:flex">
                <div class="text-gray-500 text-sm flex items-center gap-5 mt-2">
                  <div>
                    <fw-label size="xs">{{ $t('bucket_id') }}</fw-label>
                    <div class="lowercase font-mono">{{ bucket.key }}</div>
                  </div>
                  <div
                    v-if="
                      (bucket.type == 'unit_edition' || bucket.type == 'class_edition' || bucket.type == 'unit') &&
                        bucket.context !== null
                    "
                  >
                    <fw-label v-if="bucket.type == 'unit_edition' || bucket.type == 'unit'" size="xs">{{
                      $t('subject_bucket')
                    }}</fw-label>
                    <fw-label v-else size="xs">{{ $t('subject') }}</fw-label>
                    <div class="flex">
                      <span
                        v-if="bucket.context.profile_initial"
                        class="bg-gray-100 rounded px-1 py-0.5 -mt-0.5 font-semibold mr-1"
                      >
                        {{ bucket.context.profile_initial }}
                      </span>
                      <span
                        v-else-if="bucket.type === 'unit'"
                        class="bg-gray-100 rounded px-1 py-0.5 font-semibold mr-1 text-primary text-xs"
                      >
                        {{ $t('general') }}
                      </span>
                      <v-clamp autoresize :max-lines="1">
                        {{ bucket.context.unit_title }}
                      </v-clamp>
                      <span>&nbsp;-&nbsp;</span>
                      <span
                        v-if="bucket.context.regime && bucket.context.regime === 'semiannual'"
                        class="whitespace-nowrap flex-shrink-0"
                      >
                        {{ bucket.context.regime_occurrence }}º {{ $t('semester') }}&nbsp;
                      </span>
                      <span>{{ bucket.context.academic_year }}</span>
                    </div>
                  </div>
                  <div>
                    <fw-label size="xs">{{ $t('total_size') }}</fw-label>
                    <div>{{ bucket.stats.size | bytesToString }}</div>
                  </div>
                  <div>
                    <fw-label size="xs">{{ $t('resources') }}</fw-label>
                    <div class="flex gap-2">
                      <div class="flex items-center gap-1">
                        <fw-icon-folder class="w-4 h-4" /> {{ bucket.stats.folders }}
                      </div>
                      <div class="flex items-center gap-1">
                        <fw-icon-file class="w-4 h-4" /> {{ bucket.stats.files }}
                      </div>
                    </div>
                  </div>
                  <div v-if="!extendedMetadataHeader">
                    <fw-button type="link" size="xs" @click.native="extendedMetadataHeader = true"
                      >{{ $t('more') }}...</fw-button
                    >
                  </div>
                </div>
                <div v-if="extendedMetadataHeader" class="text-gray-500 text-sm flex items-center gap-5 mt-2">
                  <!-- <div v-if="bucket.type == 'class_edition'">
                    <fw-label size="xs">Disciplina (contexto)</fw-label>
                    <v-clamp autoresize :max-lines="1">
                      {{ bucket.context.academic_year }} - {{ bucket.context.unit_title }}
                    </v-clamp>
                  </div> -->
                  <div v-if="!bucket.is_owner">
                    <fw-label size="xs" marginless>{{ $t('type') }}</fw-label>
                    <div>{{ $t('shared') }}</div>
                  </div>
                  <div>
                    <fw-label size="xs">{{ $t('creation_date') }}</fw-label>
                    <div>{{ bucket.created_date | formatDateTime }}</div>
                  </div>
                  <div>
                    <fw-label size="xs">{{ $t('updated_date') }}</fw-label>
                    <div>{{ bucket.updated_date | formatDateTime }}</div>
                  </div>
                  <div v-if="false && extendedMetadataHeader" class="flex items-end">
                    <fw-button type="link" size="xs" @click.native="extendedMetadataHeader = false">{{
                      $t('close')
                    }}</fw-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Bucket main nav -->
            <div v-if="showMainNav" class="flex gap-2 items-center">
              <fw-button
                aria-label="Ficheiros"
                type="basic"
                class="rounded-full w-10 h-10 md:w-12 md:h-12 items-center flex justify-center"
                :class="{
                  'bg-teal-100 bg-opacity-70 text-primary': view == 'bucket'
                }"
                @click.native="view = 'bucket'"
              >
                <fw-icon-box-open class="w-7 h-7" />
              </fw-button>
              <fw-button
                v-if="canEditPermissions"
                type="basic"
                aria-label="Utilizadores"
                class="rounded-full w-10 h-10 md:w-12 md:h-12 items-center flex justify-center"
                :class="{
                  'bg-teal-100 bg-opacity-70 text-primary': view == 'permissions'
                }"
                @click.native="view = 'permissions'"
              >
                <fw-icon-people class="w-6 h-6" />
              </fw-button>
              <fw-button
                v-if="!folder && (canEdit || canDelete)"
                type="basic"
                aria-label="Configurações"
                class="rounded-full w-10 h-10 md:w-12 md:h-12 items-center flex justify-center"
                :class="{
                  'bg-teal-100 bg-opacity-70 text-primary': view == 'settings'
                }"
                @click.native="view = 'settings'"
              >
                <fw-icon-list-settings class="w-6 h-6" />
              </fw-button>
            </div>
          </div>
          <div v-if="view == 'bucket'" class="flex gap-1 items-center justify-between">
            <div class="h-12 pt-4">
              <b-checkbox v-model="allSelected" size="is-small">
                <span class="hidden md:flex">{{ $t('select_all') }}</span>
              </b-checkbox>
            </div>
            <div class="flex items-center md:gap-1 justify-end">
              <b-dropdown
                v-if="selectedItems.size === 0"
                aria-role="list"
                :position="isMobile ? 'is-bottom-left' : 'is-bottom-right'"
              >
                <template #trigger="{ active }">
                  <fw-button size="sm" :class="{ 'opacity-100': active }" class="font-semibold">
                    <OrderToggle :order="orderDirection"></OrderToggle>
                    <span v-if="orderItemsBy === 'created_date'" class="hidden md:flex text-gray-800 ml-1 w-8"
                      >Data</span
                    >
                    <span v-else-if="orderItemsBy === 'title'" class="hidden md:flex text-gray-800 ml-1 w-8">{{
                      $t('name')
                    }}</span>
                    <span v-else-if="orderItemsBy === 'size'" class="hidden md:flex text-gray-800 ml-1">{{
                      $t('size')
                    }}</span>
                    <span v-else-if="orderItemsBy === 'type'" class="hidden md:flex text-gray-800 ml-1">{{
                      $t('type')
                    }}</span>
                  </fw-button>
                </template>
                <fw-label class="ml-4">{{ $t('order_by') }}</fw-label>
                <b-dropdown-item paddingless aria-role="listitem" custom class="w-full hover:bg-gray-100">
                  <fw-button
                    type="simple"
                    size="sm"
                    class="w-full px-4 flex items-center gap-2 h-9"
                    :class="{ 'font-semibold': orderItemsBy !== 'title' }"
                    label="Ordenar por nome"
                    @click.native="toogleOrderItems('title')"
                  >
                    <div class="w-5">
                      <OrderToggle
                        v-if="orderItemsBy === 'title'"
                        class="bg-white"
                        :order="orderDirection"
                      ></OrderToggle>
                      <div v-else class="text-gray-900 h-5 w-5 text-center text-base -mt-1">Az</div>
                    </div>
                    {{ $t('name') }}
                  </fw-button>
                </b-dropdown-item>
                <b-dropdown-item paddingless aria-role="listitem" custom class="w-full hover:bg-gray-100">
                  <fw-button
                    type="simple"
                    size="sm"
                    class="w-full px-4 flex items-center gap-2 h-9"
                    :class="{
                      'font-semibold': orderItemsBy !== 'created_date'
                    }"
                    label="Ordenar por data"
                    @click.native="toogleOrderItems('created_date')"
                  >
                    <div class="w-5">
                      <OrderToggle
                        v-if="orderItemsBy === 'created_date'"
                        class="bg-white"
                        :order="orderDirection"
                      ></OrderToggle>
                      <svg
                        v-else
                        class="fill-current h-5 w-5 text-gray-900"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 8H4v8h16v-8zm-5-6H9v2H7V5H4v4h16V5h-3v2h-2V5zm-9 8h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z"
                        />
                      </svg>
                    </div>
                    {{ $t('date') }}
                  </fw-button>
                </b-dropdown-item>
              </b-dropdown>
              <span v-if="selectedItems.size === 0" class="mx-2 w-1 h-4 border-l border-gray-500 opacity-50"></span>
              <fw-button
                v-if="selectedItems.size === 0"
                :class="{ 'text-primary': listViewKey == 'row' }"
                label="Ver como lista normal"
                @click.native="setFileListViewType('row')"
              >
                <svg
                  class="w-5 h-5 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" />
                </svg>
              </fw-button>
              <fw-button
                v-if="selectedItems.size === 0"
                :class="{ 'text-primary': listViewKey == 'row_expanded' }"
                label="Ver lista com descrição"
                @click.native="setFileListViewType('row_expanded')"
              >
                <fw-icon-list-row class="w-5 h-5" />
              </fw-button>
              <fw-button
                v-if="selectedItems.size === 0"
                :class="{ 'text-primary': listViewKey == 'card' }"
                label="Ver em cartões"
                @click.native="setFileListViewType('card')"
              >
                <fw-icon-list-card class="w-5 h-5" />
              </fw-button>
              <span
                v-if="canAddItems && selectedItems.size === 0"
                class="mx-2 w-1 h-4 border-l border-gray-500 opacity-50"
              ></span>
              <fw-button
                v-if="canAddItems && selectedItems.size === 0"
                :label="$t('new_page')"
                class="flex items-center gap-1"
                @click.native="createNewPage"
              >
                <fw-icon-document-add class="w-5 h-5" />
                <span v-if="!selectedItems.size" class="hidden md:flex">{{ $t('new_page') }}</span>
              </fw-button>
              <fw-button
                v-if="canAddItems && selectedItems.size === 0"
                :label="$t('new_folder')"
                class="flex items-center gap-1"
                @click.native="createFolder"
              >
                <fw-icon-folder-add class="w-5 h-5" />
                <span v-if="!selectedItems.size" class="hidden md:flex">{{ $t('new_folder') }}</span>
              </fw-button>
              <Uploader
                v-if="canAddItems && selectedItems.size === 0"
                :label="!selectedItems.size ? $t('upload') : ''"
                :is-docked="true"
                :layout="showFullDragZoneUploader ? 'full-dragzone' : 'simple'"
                reference-id="upload"
                allowed="any"
                :clear-after="true"
                bucket-code="files"
                bucket-type="file"
                input-id="upload"
                :files.sync="filesToUpload"
                :size="0"
                :new-file-context="newFileContext"
                class="cursor-pointer"
                @upload="addFiles"
                @dragleave="filesOnDragLeave"
              />
              <fw-button
                v-if="false && canAddItems && appName === 'ucteacher' && userIsTeacher && selectedItems.size === 0"
                label="Importar"
                class="flex items-center gap-1"
                @click.native="toggleNonioImport"
              >
                Importar<span v-if="!selectedItems.size" class="hidden md:flex"> de NONIO</span>
              </fw-button>
              <DownloadButton
                v-if="view == 'bucket' && selectedItems.size >= 1 && showDownloadButton"
                :download-total-size="downloadTotalSize"
                @download="downloadZip"
              />
              <!-- <fw-button
                v-if="view == 'bucket' && selectedItems.size >= 1"
                class="flex items-center gap-1 text-white bg-primary mr-2 pl-2 pr-2 py-1.5 rounded-full"
                label="Download"
                @click.native="downloadZip"
              >
                <fw-icon-download class="w-5 h-5 mr-0.5" />
                <span class="hidden md:flex">Transferir</span>
                <span class="bg-black bg-opacity-10 rounded px-1 font-semibold ml-0.5 text-xs mt-0.5">{{
                  downloadTotalSize | bytesToString
                }}</span>
              </fw-button> -->
              <fw-button
                v-if="view == 'bucket' && showMarkReadButton"
                class="flex items-center gap-1 text-primary mr-1"
                :label="$t('mark_as_read')"
                @click.native="markFilesAsRead"
              >
                <fw-icon-check-all class="h-6"></fw-icon-check-all>
                <span class="hidden md:flex">{{ $t('mark_as_read') }}</span>
              </fw-button>
              <fw-button
                v-if="view == 'bucket' && canMoveItems && selectedItems.size >= 1 && !showMarkReadButton"
                class="flex items-center gap-1 text-primary mr-1"
                :label="$t('copy_to')"
                @click.native="toggleCopyToRepo"
              >
                <fw-icon-copy class="w-5 h-5" />
                <span class="hidden md:flex">{{ $t('copy_to') }}</span>
              </fw-button>
              <fw-button
                v-if="view == 'bucket' && canDeleteItems && selectedItems.size >= 1 && !showMarkReadButton"
                class="flex items-center gap-1 text-primary"
                :label="$t('delete')"
                @click.native="deleteSelectedItems"
              >
                <fw-icon-trash class="w-5 h-5" />
                <span class="hidden md:flex">{{ $t('delete') }}</span>
              </fw-button>
              <fw-button
                v-if="false && view == 'bucket' && selectedItems.size > 1"
                class="flex items-center gap-1 text-primary"
                label="Mover"
              >
                {{ $t('move') }}
              </fw-button>
              <b-dropdown
                v-if="
                  view === 'bucket' && (canMoveItems || canDeleteItems) && selectedItems.size >= 1 && showMarkReadButton
                "
                aria-role="list"
                :position="'is-bottom-left'"
              >
                <template #trigger="{ active }">
                  <fw-icon-more class="text-gray-600"></fw-icon-more>
                </template>
                <b-dropdown-item
                  v-if="canMoveItems"
                  paddingless
                  aria-role="listitem"
                  custom
                  class="w-full hover:bg-gray-100"
                >
                  <fw-button
                    class="flex items-center gap-1 w-full"
                    :label="$t('copy_to')"
                    @click.native="toggleCopyToRepo"
                  >
                    <fw-icon-copy class="w-5 h-5" />
                    <span class="hidden md:flex">{{ $t('copy_to') }}</span>
                  </fw-button>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canDeleteItems"
                  paddingless
                  aria-role="listitem"
                  custom
                  class="w-full hover:bg-gray-100"
                >
                  <fw-button class="flex items-center gap-1 w-full" label="Apagar" @click.native="deleteSelectedItems">
                    <fw-icon-trash class="w-5 h-5" />
                    <span class="hidden md:flex">{{ $t('delete') }}</span>
                  </fw-button>
                </b-dropdown-item>
              </b-dropdown>
              <fw-button type="xlight" size="xs" @click.native="showHeader = !showHeader">
                <fw-icon-skip-up-line v-if="showHeader" class="w-5 h-5" />
                <fw-icon-skip-down-line v-else class="w-5 h-5" />
              </fw-button>
            </div>
          </div>
        </div>

        <!-- Bucket permissions -->
        <PanelBucketPermissions
          v-if="bucket && view == 'permissions'"
          :bucket="bucket"
          class="w-full flex-1 overflow-y-auto bg-white bg-opacity-50 relative p-5 lg:p-10"
        />

        <!-- Bucket settings -->
        <fw-panel
          v-else-if="bucket && view == 'settings'"
          featured
          class="w-full flex-1 overflow-y-auto h-full max-w-5xl bg-white bg-opacity-50 relative p-5 lg:p-10"
          title="Configurações"
        >
          <div v-if="canEdit">
            <form
              v-if="bucket.type !== 'unit' && bucket.type !== 'unit_edition'"
              @submit.prevent="doSaveBucket(['title'])"
              @keyup.enter="doSaveBucket(['title'])"
            >
              <b-field label="Título do repositório">
                <b-input v-model="bucket.title" autofocus></b-input>
              </b-field>
              <div>
                <fw-button :type="bucket.title ? 'regular' : 'disabled'" @click.native="doSaveBucket(['title'])"
                  >Guardar</fw-button
                >
              </div>
            </form>

            <b-field label="Descarregar ficheiros" class="mt-5">
              <b-checkbox v-model="bucket.lock_download" @change.native="doSaveBucket(['lock_download'])">
                Esconder a opção de descarregamento de vídeos e .pdfs.
              </b-checkbox>
            </b-field>
            <div
              v-if="bucket.lock_download"
              class="rounded p-2 text-sm text-gray-700 bg-gray-100 items-center align-middle flex"
            >
              <svg
                class="text-gray-800 fill-current inline h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"
                />
              </svg>
              <span class="flex-1 ml-2">Os gestores continuarão a ter esta opção disponível.</span>
            </div>
          </div>
          <div v-if="canDelete" class="mt-10 pt-5 border-t border-gray-300">
            <fw-heading size="h4">Operações</fw-heading>
            <div class="mt-2">
              <fw-button type="link-muted" @click.native="doDelete"> Apagar repositório </fw-button>
            </div>
          </div>
        </fw-panel>

        <!-- Bucket view -->
        <div v-else class="flex-1 overflow-y-auto h-full relative flex flex-col">
          <div
            v-if="folder || breadcrumbs.length"
            class="overflow-x-auto w-full relative bg-primary bg-opacity-10 gap-2 p-2 md:px-4 flex items-center group max-w-full -mb-3 min-h-14"
          >
            <div class="min-w-max whitespace-nowrap flex items-center">
              <fw-button @click.native="goToPrevious">
                <fw-icon-reverse class="w-6 h-6 bg-white bg-opacity-50 rounded-md p-1" />
              </fw-button>
              <div
                v-for="breadcrumb in breadcrumbs"
                :id="'item-' + breadcrumb.key"
                :key="breadcrumb.key"
                @drop="dropToFolder(breadcrumb.key)"
                @dragenter="dragEnter(breadcrumb, $event)"
                @dragleave="dragLeave(breadcrumb)"
                @dragover="eventPreventDefault"
              >
                <fw-button
                  size="sm"
                  class="flex items-center gap-1 opacity-70"
                  @click.native="goToFolder(breadcrumb.key)"
                >
                  <fw-icon-folder-open class="w-5 h-5" />
                  <v-clamp autoresize :max-lines="1">
                    {{ breadcrumb.title }}
                  </v-clamp>
                </fw-button>
              </div>
              <fw-button v-if="folder" size="sm" class="flex items-center gap-1" @click.native="goToPrevious">
                <fw-icon-folder-open class="w-5 h-5" />
                <v-clamp autoresize :max-lines="1">
                  {{ folder.title }}
                </v-clamp>
              </fw-button>
            </div>
          </div>

          <!-- Buckets objects list -->
          <div
            class="h-full"
            :class="{ 'flex flex-col items-center justify-center': !items.length }"
            @dragover="filesOnDragOver"
          >
            <div
              v-if="items.length"
              class="px-3 py-2 md:p-5"
              :class="{
                'grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8 gap-2 md:gap-4':
                  listViewKey == 'card'
              }"
            >
              <div
                v-for="item in orderedItems"
                :id="'item-' + item.key"
                :key="item.key"
                @drop="dropToItem(item)"
                @dragstart="dragItemStart(item, $event)"
                @dragenter="dragItemEnter(item)"
                @dragleave="dragItemLeave(item)"
                @dragend="dragItemEnd"
              >
                <CardFile
                  v-if="item.type == 'file' || item.type == 'folder'"
                  ref="bucketItem"
                  :type="listViewKey"
                  :draggable="canEditItems"
                  :bucket="bucket"
                  :item="item"
                  :is-preview-open="sidePreviewItem && sidePreviewItem.key == item.key"
                  :can-edit-items="canEditItems"
                  :can-delete-items="canDeleteItems"
                  :can-move-items="canMoveItems"
                  :init-selected="selectedItems.has(item.key)"
                  :read="isFileRead(item.key)"
                  @preview="preview"
                  @set-selected="setSelected"
                  @delete="deleteItem"
                  @copy-to-repo="copyToRepoItem"
                  @download="downloadFile"
                  @go-to-folder="goToFolder"
                />
                <CardPage
                  v-else-if="item.type == 'page'"
                  ref="bucketItem"
                  :type="listViewKey"
                  :draggable="canEditItems"
                  :bucket="bucket"
                  :item="item"
                  :is-preview-open="sidePreviewItem && sidePreviewItem.key == item.key"
                  :can-edit-items="canEditItems"
                  :can-delete-items="canDeleteItems"
                  :can-move-items="canMoveItems"
                  :init-selected="selectedItems.has(item.key)"
                  :read="isFileRead(item.key)"
                  @preview="previewPage"
                  @set-selected="setSelected"
                  @delete="deleteItem"
                  @copy-to-repo="copyToRepoItem"
                />
              </div>
            </div>
            <div v-else class="px-10 py-5 text-gray-500 flex justify-center flex-col gap-2 items-center">
              <div v-if="canAddItems">
                <fw-icon-drag-drop class="w-12 h-12" />
              </div>
              <div class="text-lg font-medium">{{ $t('empty_folder_title') }}</div>
              <div v-if="canAddItems" class="text-sm text-center">
                {{ $t('empty_folder_message') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="sidePreviewItem"
      class="h-full p-5 overflow-y-auto absolute lg:relative z-10 lg:z-0 top-0 left-0 w-full lg:w-1/3 2xl:w-1/4 bg-white bg-opacity-95 backdrop-filter backdrop-blur-sm"
    >
      <div class="flex items-start gap-2 justify-between">
        <fw-heading size="h2" class="break-all">
          <v-clamp autoresize :max-lines="2">
            {{ sidePreviewItem.title }}
          </v-clamp>
        </fw-heading>
        <div class="-mt-1">
          <fw-button label="Fechar" @click.native="sidePreviewItem = null">
            <fw-icon-close class="w-7 h-7" />
          </fw-button>
        </div>
      </div>
      <div class="my-5">
        <div
          v-if="sidePreviewItem.type == 'page'"
          class="shadow hover:shadow-xl bg-gradient-to-r from-teal-400 to-teal-600 w-full rounded-xl overflow-hidden text-teal-800 h-36 p-5 flex flex-col gap-1 text-sm font-medium items-center justify-center"
        >
          <div class="flex-1 flex flex-col items-center justify-center">
            <fw-icon-file class="w-12 h-12" /> {{ $t('content_page') }}
          </div>
        </div>
        <div
          v-else-if="sidePreviewItem.type != 'file'"
          class="shadow hover:shadow-xl bg-gradient-to-r from-teal-400 to-teal-600 w-full rounded-xl overflow-hidden text-teal-800 h-36 p-5 flex flex-col gap-1 text-sm font-medium items-center justify-center"
        >
          <div class="flex-1 flex flex-col items-center justify-center">
            <fw-icon-folder-solid class="w-12 h-12" /> {{ $t('folder') }}
          </div>
          <div
            v-if="sidePreviewItem.stats && (sidePreviewItem.stats.folders || sidePreviewItem.stats.files)"
            class="flex gap-3 items-center justify-between text-sm"
          >
            <div class="flex items-center gap-1">
              <fw-icon-scale class="w-4 h-4" /> {{ sidePreviewItem.stats.size | bytesToString }}
            </div>
            <div class="flex items-center gap-1">
              <fw-icon-folder class="w-4 h-4" /> {{ sidePreviewItem.stats.folders }}
            </div>
            <div class="flex items-center gap-1">
              <fw-icon-file class="w-4 h-4" /> {{ sidePreviewItem.stats.files }}
            </div>
          </div>
          <div v-else class="text-xs font-medium">{{ $t('empty_folder') }}</div>
        </div>
        <div
          v-else-if="
            sidePreviewItem.file && (sidePreviewItem.file.type == 'image' || sidePreviewItem.file.thumb_filename)
          "
          class="overflow-hidden rounded-2xl"
        >
          <img :src="getImageViewUrl(sidePreviewItem.file, 'max400')" class="w-full" />
        </div>
        <video-player
          v-else-if="sidePreviewItem.file && sidePreviewItem.file.type == 'video'"
          :ref="'video-' + sidePreviewItem.file.key"
          :type="sidePreviewItem.file.mimetype"
          :src="getFileViewUrl(sidePreviewItem.file)"
        />
        <div
          v-else-if="sidePreviewItem.file"
          class="h-32 p-2 flex items-center justify-center flex-col gap-1 shadow hover:shadow-xl bg-gradient-to-r from-teal-400 to-teal-600 w-full rounded-xl overflow-hidden text-teal-800"
        >
          <component :is="'fw-icon-' + fileIconKey(sidePreviewItem.file.filename)" class="w-12 h-12 opacity-40" />
        </div>
      </div>
      <div
        v-if="(sidePreviewItem.type != 'folder' && sidePreviewItem.file) || sidePreviewItem.type == 'page'"
        class="w-full"
      >
        <fw-button
          v-if="isPDF(sidePreviewItem.metadata)"
          type="white"
          expanded
          size="md"
          class="bg-gray-50 mb-2 hover:opacity-75"
          @click.native="openFile(sidePreviewItem.key, sidePreviewItem.file)"
          >{{ $t('preview') }}</fw-button
        >
        <DownloadButton
          v-if="sidePreviewItem.lock_download !== true && sidePreviewItem.type != 'page'"
          :download-total-size="sidePreviewItem.metadata.size"
          :type="'square'"
          :size="'lg'"
          :expanded="true"
          @download="downloadFile(sidePreviewItem.key, sidePreviewItem.file)"
        />
        <fw-button
          v-if="sidePreviewItem.type == 'page'"
          type="white"
          expanded
          size="md"
          class="bg-gray-50 mb-2 hover:opacity-75"
          @click.native="previewPage(sidePreviewItem)"
          >{{ $t('open_editor') }}</fw-button
        >
        <!-- <span
          v-if="sidePreviewItem.lock_download !== true"
          class="gap-1 text-white bg-primary font-bold text-sm w-full rounded-xl p-3 flex items-center justify-center cursor-pointer"
          @click="downloadFile(sidePreviewItem.key, sidePreviewItem.file)"
        >
          <fw-icon-cloud-download class="w-5 h-5" /> Download
        </span> -->
      </div>
      <div v-if="sidePreviewItem.type != 'page'" class="my-5 text-sm text-gray-500 flex flex-col gap-2">
        <fw-heading v-show="false" size="h3">{{ $t('metadata') }}</fw-heading>
        <div v-if="sidePreviewItem.metadata.filename">
          <fw-label>{{ $t('file_name') }}</fw-label>
          <div v-if="!canEditItems">{{ sidePreviewItem.title }}</div>
          <b-field
            v-else
            :type="{ 'is-danger': sidePreviewErrorKey == 'title' }"
            :message="sidePreviewErrorKey == 'title' ? sidePreviewErrorMessage : ''"
          >
            <b-input
              v-model="sidePreviewItem.title"
              size="is-small"
              class="outline-none ring-0"
              @change.native="saveItemTitle(sidePreviewItem)"
            ></b-input>
          </b-field>
        </div>
        <div v-else>
          <fw-label>{{ $t('folder_name') }}</fw-label>
          <div v-if="!canEditItems">{{ sidePreviewItem.title }}</div>
          <b-field
            v-else
            :type="{ 'is-danger': sidePreviewErrorKey == 'title' }"
            :message="sidePreviewErrorKey == 'title' ? sidePreviewErrorMessage : ''"
          >
            <b-input
              v-model="sidePreviewItem.title"
              size="is-small"
              @change.native="saveItemTitle(sidePreviewItem)"
            ></b-input>
          </b-field>
        </div>

        <div v-for="item in dcElementsPrefix" :key="item.key">
          <fw-label>{{ item.title }}</fw-label>
          <div v-if="!canEditItems" :class="{ 'opacity-70 text-xs': !sidePreviewItem.metadata[item.key] }">
            {{ sidePreviewItem.metadata[item.key] || $t('not_defined') }}
          </div>
          <b-field
            v-else
            :type="{ 'is-danger': sidePreviewErrorKey == item.key }"
            :message="sidePreviewErrorKey == item.key ? sidePreviewErrorMessage : ''"
          >
            <b-datepicker
              v-if="item.type == 'date'"
              v-model="sidePreviewItem.metadata[item.key]"
              expanded
              size="is-small"
              @input="checkMetadata(sidePreviewItem, item.key)"
            >
              <b-button label="Clear" type="is-danger" outlined @click="clearMetadata(sidePreviewItem, item.key)" />
            </b-datepicker>
            <textarea
              v-else-if="item.key === 'dc.description'"
              v-model="sidePreviewItem.metadata[item.key]"
              class="w-full bg-gray-200 p-2 text-gray-800 rounded-sm ring-0 outline-none"
              placeholder=""
              rows="3"
              @blur="checkMetadata(sidePreviewItem, item.key)"
            ></textarea>
            <b-input
              v-else
              v-model="sidePreviewItem.metadata[item.key]"
              size="is-small"
              @change.native="checkMetadata(sidePreviewItem, item.key)"
            ></b-input>
          </b-field>
        </div>

        <div v-if="sidePreviewItem.metadata.size">
          <fw-label>{{ $t('file_size') }}</fw-label>
          <div>{{ sidePreviewItem.metadata.size | bytesToString }}</div>
        </div>
        <div v-if="sidePreviewItem.metadata.image_size">
          <fw-label>{{ $t('image_dimensions') }}</fw-label>
          <div>{{ sidePreviewItem.metadata.image_size }}</div>
        </div>
        <div>
          <fw-label>{{ $t('upload_date') }}</fw-label>
          <div>{{ sidePreviewItem.created_date | formatDateTime }}</div>
        </div>

        <div v-for="item in dcElements" :key="item.key">
          <fw-label>{{ item.title }}</fw-label>
          <div v-if="!canEditItems" :class="{ 'opacity-70 text-xs': !sidePreviewItem.metadata[item.key] }">
            {{ sidePreviewItem.metadata[item.key] || $t('not_defined') }}
          </div>
          <b-field
            v-else
            :type="{ 'is-danger': sidePreviewErrorKey == item.key }"
            :message="sidePreviewErrorKey == item.key ? sidePreviewErrorMessage : ''"
          >
            <b-datepicker
              v-if="item.type == 'date'"
              v-model="sidePreviewItem.metadata[item.key]"
              expanded
              size="is-small"
              @input="checkMetadata(sidePreviewItem, item.key)"
            >
              <b-button label="Clear" type="is-danger" outlined @click="clearMetadata(sidePreviewItem, item.key)" />
            </b-datepicker>
            <textarea
              v-else-if="item.key === 'dc.description'"
              v-model="sidePreviewItem.metadata[item.key]"
              class="w-full bg-gray-200 p-2 text-gray-800 rounded-sm ring-0 outline-none"
              placeholder=""
              rows="3"
              @blur="checkMetadata(sidePreviewItem, item.key)"
            ></textarea>
            <b-input
              v-else
              v-model="sidePreviewItem.metadata[item.key]"
              size="is-small"
              @change.native="checkMetadata(sidePreviewItem, item.key)"
            ></b-input>
          </b-field>
        </div>

        <div v-if="withExifElements">
          <div class="text-xs text-gray-400 mt-5">EXIF</div>
          <div v-for="item in exifElements" :key="item.key">
            <div v-if="translateExif(item.key, sidePreviewItem.metadata[item.key])">
              <fw-label>{{ item.title }}</fw-label>
              <div>{{ translateExif(item.key, sidePreviewItem.metadata[item.key]) }}</div>
            </div>
          </div>
        </div>

        <div v-if="(canEditItems && withDcElements) || withDcElements" class="text-xs text-gray-400 mt-5">
          {{ $t('dcmi_metadata') }}
        </div>
      </div>
    </div>
    <b-modal
      v-if="showNonioImport"
      :can-cancel="true"
      trap-focus
      :active.sync="showNonioImport"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      scroll="keep"
    >
      <div class="pt-3 pb-5 flex flex-col h-full">
        <div class="flex justify-between px-5">
          <fw-label>Importação NONIO</fw-label>
          <fw-button type="link" size="sm" @click.native="toggleNonioImport">Fechar</fw-button>
        </div>
        <div class="flex-1 w-full">
          <div class="flex flex-col gap-1">
            <div
              v-if="!nonioImportLoaded"
              class="w-full flex flex-col gap-2 items-center justify-center text-gray-500 text-sm"
            >
              <fw-icon-loading class="w-7 h-7 opacity-50" />
              <span>A recolher as edições disponíveis em NONIO...</span>
            </div>
            <div v-else class="w-full">
              <div v-if="showNonioUnitClassesSelector" class="relative">
                <div class="px-5 pb-5 text-sm">Selecione a turma NONIO de onde importar:</div>
                <div class="autoScrollActive darkScrollArea shadow-inner relative" style="max-height: 500px">
                  <LoadingPlaceholder v-if="nonioLoadingUnitClasses" :list="true" class="m-5"></LoadingPlaceholder>
                  <div v-else>
                    <div v-for="(unit, u) in nonioUnitClassesOptions" :key="'unitoption_' + u" class="pb-5">
                      <div class="flex justify-start">
                        <h2 class="lg:text-md font-bold text-gray-700 text-opacity-90">
                          {{ unit.title }}
                        </h2>
                      </div>
                      <div class="flex justify-start">
                        <fw-label>{{ unit.profile }}</fw-label>
                        <fw-label v-if="unit.regime === 'semiannual'"
                          >&nbsp;- {{ unit.regime_occurrence }}º Semestre</fw-label
                        >
                        <fw-label v-else>&nbsp;- Anual</fw-label>
                      </div>
                      <div v-for="(classe, c) in unit.classes" :key="'classeoption_' + u + '_' + c">
                        <RowClass
                          :classobj="classe"
                          clickable
                          @click.native="selectNonioClass(classe, unit.title)"
                        ></RowClass>
                      </div>
                      <div v-if="unit.classes.length === 0" class="text-gray-500 text-sm">Sem turmas para mostrar</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="px-5">
                <fw-label>Selecione a turma NONIO de onde quer importar</fw-label>
                <div class="relative cursor-pointer mb-4" @click="showNonioUnitsClasses">
                  <RowClass v-if="selectedNonioClass === null" :bucket="bucket" selector></RowClass>
                  <RowClass v-else :classobj="selectedNonioClass" :unit-name="nonioUnitName" selector></RowClass>
                </div>
                <fw-label>Edições disponíveis</fw-label>
                <div v-if="!nonioEditions.length" class="text-sm text-gray-500 px-5">
                  Não existem edições associadas para apresentar.
                </div>
                <div v-else>
                  <div
                    v-for="nonioEdition in nonioEditions"
                    :key="nonioEdition.nonio_id"
                    class="bg-gray-100 rounded-lg w-full p-2 mb-1"
                  >
                    <button
                      class="bg-white p-3 flex items-center w-full shadow rounded-lg"
                      @click="toggleNonioFiles(nonioEdition.nonio_id)"
                    >
                      <div class="font-bold">
                        {{ nonioEdition.academic_year }}
                      </div>
                      <div class="flex-1">
                        <div
                          v-if="nonioEdition.keywords !== null && nonioEdition.keywords !== ''"
                          class="text-gray-400 pl-2"
                        >
                          <v-clamp autoresize :max-lines="1">
                            {{ nonioEdition.keywords }}
                          </v-clamp>
                        </div>
                      </div>
                      <div class="text-sm">
                        {{ translateRegime(nonioEdition.regime, nonioEdition.regime_occurrence) }}
                      </div>
                    </button>

                    <div v-if="nonioEdition.importing.length || showNonioFilesId == nonioEdition.nonio_id" class="p-5">
                      <div
                        v-if="nonioEdition.importing.length"
                        class="bg-teal-500 bg-opacity-25 p-2 rounded-lg flex items-center gap-2 mt-1 mb-3"
                      >
                        <fw-icon-loading class="w-5 h-5" /> A importar ficheiros...
                      </div>
                      <div v-if="showNonioFilesId == nonioEdition.nonio_id">
                        <div v-if="!nonioFilesLoaded">A carregar ...</div>
                        <div v-else-if="!nonioFiles.length">Não foram encontrados materiais de apoio nesta edição.</div>
                        <div v-else>
                          <div class="flex justify-between items-center gap-2 text-sm mb-3">
                            <div class="flex-1">
                              <b-checkbox
                                v-model="nonioImportAllFiles"
                                size="is-small"
                                :indeterminate="nonioImportAllFilesIndeterminate"
                                >Selecionar todos</b-checkbox
                              >
                            </div>
                            <div>
                              <fw-button
                                :type="nonioImportFileIds.length ? 'primary' : 'light'"
                                :disabled="!nonioImportFileIds.length"
                                :loading="importNonioFilesLoading"
                                @click.native="importNonioFiles(nonioEdition)"
                              >
                                Importar materiais ({{ nonioImportFileIds.length }})
                              </fw-button>
                            </div>
                          </div>
                          <div
                            v-for="nonioFile in nonioFiles"
                            :key="nonioFile.nonio_id"
                            class="flex p-1 rounded-lg bg-gray-300 bg-opacity-25 hover:bg-teal-100 hover:bg-opacity-40 w-full my-1"
                            :class="{ 'bg-teal-200': nonioImportFileIds.includes(nonioFile.nonio_id) }"
                          >
                            <div class="p-1">
                              <b-checkbox v-model="nonioImportFileIds" :native-value="nonioFile.nonio_id"></b-checkbox>
                            </div>
                            <div class="flex-1 flex-col">
                              <div class="flex-1 flex justify-between gap-1 w-full">
                                <div class="flex-1 line-clamp-1 font-medium">
                                  {{ nonioFile.name }}
                                </div>
                                <div class="text-xs text-gray-500">{{ nonioFile.created_date | formatDateTime }}</div>
                              </div>
                              <div
                                v-if="nonioFile.description"
                                class="w-full text-sm text-gray-700 mt-1 mb-2"
                                v-html="nonioFile.description || ''"
                              ></div>
                              <div class="text-xs text-gray-500">{{ nonioFile.filename }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-if="copyToRepoModal"
      :can-cancel="true"
      trap-focus
      :active.sync="copyToRepoModal"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      scroll="keep"
      :custom-class="'rounded-buefy-modal'"
    >
      <div class="pt-3 pb-5 flex flex-col h-full">
        <div class="flex justify-between px-5">
          <span size="lg" class="font-semibold text-gray-500 text-sm pt-2">Copiar para repositório(s)</span>
          <fw-button type="link" size="sm" class="mb-2 -mr-1.5" @click.native="toggleCopyToRepo">Fechar</fw-button>
        </div>
        <CopyToRepoModal
          :bucket-key="bucketKey"
          :selected-files="Array.from(selectedItems)"
          @close="toggleCopyToRepo"
        ></CopyToRepoModal>
      </div>
    </b-modal>

    <fw-modal
      v-if="showNewDocumentModal"
      :active.sync="showNewDocumentModal"
      :can-cancel="true"
      size="min"
      @close="closeNewDocument"
    >
      <template #default>
        <ModalNewPage :context="'bucket'" :context-key="bucketKey" @close="closeNewDocument"></ModalNewPage>
      </template>
    </fw-modal>
  </div>
</template>

<script>
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import exifUtils from '@/fw-modules/fw-core-vue/utilities/exif'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import VideoPlayer from '@/fw-modules/fw-core-vue/ui/components/video/VideoPlayer'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import CardFile from '@/fw-modules/fw-core-vue/buckets/components/cards/CardFile'
import CardPage from '@/fw-modules/fw-core-vue/buckets/components/cards/CardPage'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import ServiceBuckets from '@/fw-modules/fw-core-vue/buckets/services/ServiceBuckets'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/Uploader.vue'
import PanelBucketPermissions from './PanelBucketPermissions'
import CopyToRepoModal from '../modals/CopyToRepo'
import DownloadButton from '@/fw-modules/fw-core-vue/ui/components/buttons/DownloadButton'
import OrderToggle from '@/fw-modules/fw-core-vue/ui/components/buttons/OrderToggle'
import RowClass from '../cards/RowClass'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import ModalNewPage from '../../../pages/components/modals/ModalNewPage.vue'
//import Vue from 'vue'

const DC_ELEMENTS = [
  //{ key: 'dc.description', title: 'Descrição' },
  { key: 'dc.subject', title: 'Subject' },
  { key: 'dc.creator', title: 'Creator' },
  { key: 'dc.publisher', title: 'Publisher' },
  { key: 'dc.contributor', title: 'Contributor' },
  { key: 'dc.date', title: 'Date', type: 'date' },
  { key: 'dc.type', title: 'Type' },
  { key: 'dc.format', title: 'Format' },
  { key: 'dc.identifier', title: 'Identifier' },
  { key: 'dc.source', title: 'Source' },
  { key: 'dc.language', title: 'Language' },
  { key: 'dc.relation', title: 'Relation' },
  { key: 'dc.coverage', title: 'Coverage' },
  { key: 'dc.rights', title: 'Rights' }
]
const DC_ELEMENTS_PREFIX = [{ key: 'dc.description', title: 'Description' }]
const DC_ELEMENTS_REFERENCE = {}
const DC_DATE_FIELDS = new Set()
for (let field of DC_ELEMENTS) {
  DC_ELEMENTS_REFERENCE[field.key] = field
  if (field.type == 'date') DC_DATE_FIELDS.add(field.key)
}

const EXIF_ELEMENTS = [
  { key: 'exif.Flash', title: 'Flash' },
  { key: 'exif.ColorSpace', title: 'ColorSpace' },
  { key: 'exif.Orientation', title: 'Orientation' },
  { key: 'exif.CustomRendered', title: 'CustomRendered' },
  { key: 'exif.ISOSpeedRatings', title: 'ISOSpeedRatings' },
  { key: 'exif.Make', title: 'Make' },
  { key: 'exif.Model', title: 'Model' },
  { key: 'exif.Software', title: 'Software' },
  { key: 'exif.HostComputer', title: 'HostComputer' }
]

export default {
  components: {
    CardFile,
    CardPage,
    PanelBucketPermissions,
    Uploader,
    VideoPlayer,
    CopyToRepoModal,
    DownloadButton,
    OrderToggle,
    RowClass,
    LoadingPlaceholder,
    ModalNewPage
  },

  props: {
    propBucketKey: {
      type: String,
      default: null
    },
    folderKey: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      showMainNav: false,
      showHeader: false,
      loading: false,
      filesToUpload: [],
      answersToUpload: [],
      newFolderTitle: '',
      showNonioImport: false,
      copyToRepoModal: false,
      nonioImportLoaded: false,
      nonioEditions: [],
      showNonioFilesId: null,
      nonioFilesLoaded: false,
      nonioFiles: [],
      nonioImportFileIds: [],
      nonioImportAllFiles: false,
      importNonioFilesLoading: false,
      nonioImportClassEditionKey: null,
      nonioUnitClassesOptions: [],
      nonioLoadingUnitClasses: false,
      nonioUnitName: '',
      nonioAcademicYears: [],
      nonioSelectedAcademicYear: '',
      showNonioUnitClassesSelector: false,
      selectedNonioClass: null,
      view: 'bucket',
      bucket: null,
      folder: null,
      breadcrumbs: [],
      bucketSubscription: null,
      items: [],
      orderItemsBy: 'created_date',
      orderDirection: 'DESC',
      showFullDragZoneUploader: false,
      allSelected: false,
      selectedItems: new Set(),
      sidePreviewItem: null,
      sidePreviewErrorKey: null,
      sidePreviewErrorMessage: null,
      dragActiveItem: null,
      bucketDragInfo: {
        key: 'root'
      },
      listViewKey: 'row_expanded',
      listViewLoaded: false,
      extendedMetadataHeader: true,
      dcElements: DC_ELEMENTS,
      dcElementsPrefix: DC_ELEMENTS_PREFIX,
      exifElements: EXIF_ELEMENTS,
      readFiles: new Set(),
      downloadTotalSize: 0,
      dowloadingProtectedFiles: false,
      showDownloadButton: false,
      showMarkReadButton: false,
      showNewDocumentModal: false
      /*copyToResultUnits: [],
      copyToResultClasses: [],
      copyToResultBuckets: [],
      copyToRecipientType: 'class_edition',
      copyToSelectedUnit: '',
      copyToAcademicYears: [],
      copyToSelectedAcademicYear: '',
      copyToSelectedRecipients: [],
      loadingCopyToResults: true,
      copying: false,
      copyToFilters: [
        {
          key: 'buckets',
          label: 'Repositórios',
        },
        {
          key: 'class_edition',
          label: 'Turmas',
        },
        {
          key: 'unit_edition',
          label: 'Disciplinas',
        },
        {
          key: 'group',
          label: 'Grupos',
        },
      ],*/
    }
  },

  computed: {
    bucketKey() {
      return this.propBucketKey || this.$route.params.key
    },
    orderedItems() {
      let items = this.items
      let orderBy = this.orderItemsBy
      let direction = this.orderDirection
      return items.sort(function(a, b) {
        if (direction === 'ASC') {
          if (typeof b[orderBy] === 'undefined') {
            return -1
          } else if (a[orderBy] === 'undefined') {
            return 1
          } else {
            return a[orderBy].localeCompare(b[orderBy])
          }
        } else {
          if (typeof b[orderBy] === 'undefined') {
            return 1
          } else if (a[orderBy] === 'undefined') {
            return -1
          } else {
            return a[orderBy].localeCompare(b[orderBy]) * -1
          }
        }
      })
    },
    isMobile() {
      return window.innerWidth < 640
    },
    canEdit() {
      return this.bucket && this.bucket.permissions && this.bucket.permissions.edit
    },
    canEditPermissions() {
      return this.bucket && this.bucket.permissions && this.bucket.permissions.permissions
    },
    canDelete() {
      return this.bucket && this.bucket.permissions && this.bucket.permissions.delete
    },
    userIsTeacher() {
      let roles = this.$store.getters.getUser?.roles ?? []
      return roles.includes('teacher')
    },
    itemsPermissions() {
      if (!this.bucket) return {}

      const permissions = this.bucket.permissions
      if (!permissions || !permissions.items) return {}
      else return permissions.items
    },
    canAddItems() {
      return this.itemsPermissions.add
    },
    canEditItems() {
      return this.itemsPermissions.edit
    },
    canMoveItems() {
      return this.itemsPermissions.edit
    },
    canDeleteItems() {
      return this.itemsPermissions.delete
    },
    nonioImportAllFilesIndeterminate() {
      return Boolean(this.nonioImportFileIds.length && this.nonioImportFileIds.length != this.nonioFiles.length)
    },
    newFileContext() {
      return { bucket: this.bucketKey, folder: this.folderKey }
    },
    withDcElements() {
      if (!this.sidePreviewItem) return false

      const metadata = this.sidePreviewItem.metadata
      if (!metadata) return false

      for (let item of this.dcElements) {
        if (typeof metadata[item.key] !== 'undefined' && metadata[item.key] !== '' && metadata[item.key] !== null) {
          return true
        }
      }
      return false
    },
    withExifElements() {
      if (!this.sidePreviewItem) return false

      const metadata = this.sidePreviewItem.metadata
      if (!metadata) return false

      for (let item of this.exifElements) {
        if (metadata[item.key]) {
          return true
        }
      }
      return false
    },
    appName() {
      return this.$store.state.appName
    }
  },
  watch: {
    nonioImportFileIds(value) {
      if (this.nonioImportAllFiles && !value.length) {
        this.nonioImportAllFiles = false
      }
    },
    nonioImportAllFiles(value) {
      this.nonioImportFileIds = []
      if (value) {
        for (let file of this.nonioFiles) {
          this.nonioImportFileIds.push(file.nonio_id)
        }
      }
    },
    allSelected(value) {
      for (let item of this.$refs.bucketItem) {
        item.updateInnerSelected(value)
      }
    },
    copyToRecipientType(value) {
      this.copyToSelectedRecipients = []
      this.copyToSelectedUnit = ''
      if (value == 'class_edition') {
        this.getClassesEditions()
      } else {
        this.getBucketsWithPermissionAddItems()
      }
    }
  },

  beforeMount() {
    this.getBucket()
  },

  beforeDestroy() {
    if (this.bucketSubscription) {
      this.bucketSubscription.destroy()
      this.bucketSubscription = null
    }
    if (this.nonioImportClassEditionKey) {
      this.$store.commit('unsubscribeWS', { code: 'notifications', name: 'PanelBucket' })
    }
  },

  methods: {
    translateRegime(regime, regimeOccurrence) {
      if (regime == 'hourly') {
        return `${regimeOccurrence} horas`
      } else if (regime == 'daily') {
        return `Diário ${regimeOccurrence}`
      } else if (regime == 'weekly') {
        return `Semanal ${regimeOccurrence}`
      } else if (regime == 'monthly') {
        return `Mensal ${regimeOccurrence}`
      } else if (regime == 'semiannual') {
        return `Semestre ${regimeOccurrence}`
      } else if (regime == 'anual') {
        return 'Anual'
      } else {
        return ''
      }
    },
    translateExif(key, value) {
      return exifUtils.translate(key.substr(5), value)
    },
    clearMetadata(item, key) {
      if (item.metadata[key]) {
        item.metadata[key] = null
        this.checkMetadata(item, key)
      }
    },
    async saveItemTitle(item) {
      try {
        const data = { title: item.title }
        const updatedItem = await ServiceBuckets.updateBucketItem(this.bucket.key, item.key, data)
        Object.assign(item, updatedItem)
        this.$buefy.snackbar.open({
          message: `Alterações guardadas.`,
          position: 'is-top-right'
        })
      } catch (error) {
        this.$buefy.snackbar.open({
          message: `Ocorreu um erro ao guardar o título`,
          position: 'is-top-right'
        })
      }
    },
    async checkMetadata(item, key) {
      const data = { metadata: {} }

      let value = item.metadata[key]
      if (value && DC_DATE_FIELDS.has(key)) {
        value = Dates.toDateString(value)
      }
      data.metadata[key] = value

      try {
        const updatedItem = await ServiceBuckets.updateBucketItem(this.bucket.key, item.key, data)
        this.$buefy.snackbar.open({
          message: `Alterações guardadas.`,
          position: 'is-top-right'
        })

        if (DC_DATE_FIELDS.has(key) && typeof updatedItem.metadata[key] == 'string') {
          item.metadata[key] = Dates.toDate(updatedItem.metadata[key])
        } else {
          item.metadata[key] = updatedItem.metadata[key]
        }
      } catch (error) {
        this.sidePreviewErrorKey = key
        this.sidePreviewErrorMessage = utils.errors(error).getTranslated()
        if (!this.sidePreviewErrorMessage) {
          this.sidePreviewErrorMessage = 'Ocorreu um erro, por favor tente novamente mais tarde'
          console.error('Failed to set metadata', error)
        }
      }
    },
    async setFileListViewType(view) {
      this.listViewKey = view
      await this.saveViewSettings()
    },
    fileIconKey(filename) {
      return utils.getFileIcon(filename)
    },
    getFileViewUrl(file) {
      return ServiceStorage.getFileViewUrl(file)
    },
    getImageViewUrl(file, size) {
      return ServiceStorage.getImageViewUrl(file, size)
    },
    /*async setOrderItems(orderBy, direction) {
      this.orderItemsBy = orderBy
      this.orderDirection = direction
      await this.saveViewSettings()
    },*/
    async toogleOrderItems(orderBy) {
      if (this.orderItemsBy === orderBy) {
        if (this.orderDirection === 'ASC') {
          this.orderDirection = 'DESC'
        } else {
          this.orderDirection = 'ASC'
        }
      } else {
        this.orderDirection = 'ASC'
        this.orderItemsBy = orderBy
      }
      await this.saveViewSettings()
    },
    setSelected(itemKey, selected, preview = true) {
      let downloadTotalSize = this.downloadTotalSize
      for (let item of this.items) {
        if (item.key == itemKey) {
          if (selected) {
            downloadTotalSize +=
              item.type == 'folder' && item.stats.size > 0
                ? item.stats.size
                : item.metadata.size > 0 && item.lock_download !== true
                ? item.metadata.size
                : 0
            if (item.lock_download === true) {
              this.dowloadingProtectedFiles = true
            } else if ((item.type === 'folder' && item.stats.size > 0) || item.type === 'file') {
              this.showDownloadButton = true
            }
            if (item.unread) {
              this.showMarkReadButton = true
            }
            this.selectedItems.add(item.key)
          } else {
            downloadTotalSize -=
              item.type == 'folder' && item.stats.size > 0
                ? item.stats.size
                : item.metadata.size > 0 && item.lock_download !== true
                ? item.metadata.size
                : 0
            this.selectedItems.delete(item.key)
          }
          break
        }
      }
      this.downloadTotalSize = downloadTotalSize

      if (this.selectedItems.size === 1 && preview) {
        const openKey = this.selectedItems.values().next().value
        for (let item of this.items) {
          if (item.key == openKey) {
            for (let fieldKey of DC_DATE_FIELDS) {
              if (typeof item.metadata[fieldKey] == 'string') {
                item.metadata[fieldKey] = Dates.toDate(item.metadata[fieldKey])
              }
            }
            //this.sidePreviewItem = item
            this.preview(item)
            this.sidePreviewErrorKey = null
            this.sidePreviewErrorMessage = null
            break
          }
        }
      } else {
        this.sidePreviewItem = null
        this.sidePreviewErrorKey = null
        this.sidePreviewErrorMessage = null
      }

      if (this.selectedItems.size === 0) {
        this.dowloadingProtectedFiles = false
        this.showDownloadButton = false
        this.showMarkReadButton = false
      }

      if (this.selectedItems.size == this.items.length) {
        this.allSelected = true
      } else if (!this.selectedItems.size) {
        this.allSelected = false
      }
    },
    async saveViewSettings() {
      let settings = {
        view_type: this.listViewKey,
        view_order_type: this.orderItemsBy,
        view_order_direction: this.orderDirection
      }
      await ServiceSettings.setSetting(`bucket-${this.bucket.key}-view-settings`, JSON.stringify(settings))
    },
    markFileAsRead(key) {
      //this.readFiles.add(key)
      //await ServiceBuckets.markFileAsRead(this.bucket.key, [key], Array.from(this.readFiles))
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].key === key) {
          this.items[i].unread = false
          break
        }
      }
    },
    async markFilesAsRead() {
      //this.readFiles.add(key)
      //await ServiceBuckets.markFileAsRead(this.bucket.key, [key], Array.from(this.readFiles))
      let keys = []
      for (let i = 0; i < this.items.length; i++) {
        if (this.selectedItems.has(this.items[i].key) && this.items[i].unread) {
          keys.push(this.items[i].key)
        }
      }
      await ServiceBuckets.markFileAsRead(this.bucket.key, keys, 'read')
      this.markSelectedAsRead()
      this.showMarkReadButton = false
    },
    async downloadFile(key, file) {
      this.markFileAsRead(key)
      window.open(ServiceStorage.getFileViewUrl(file, true), '_blank').focus()
    },
    async openFile(key, file) {
      //console.log(key, file)
      //this.markFileAsRead(key)
      ServiceBuckets.markFileAsRead(this.bucket.key, [key], 'preview')
      this.markFileAsRead(key)
      //TODO: open pdf in pdfviewer
      window.localStorage.setItem('file_url_format', file.url_format)
      await this.$router.push('/view/' + file.key + '/' + file.token + '/' + encodeURI(file.filename))
    },
    async downloadZip(confirmProtected = false, confirmBigSize = false) {
      if (this.dowloadingProtectedFiles && confirmProtected === false) {
        this.$buefy.dialog.confirm({
          title: 'Aviso: repositório protegido',
          message: `Os vídeos e ficheiros pdf não serão incluídos no download.`,
          confirmText: 'Continuar',
          cancelText: 'Cancelar',
          onConfirm: () => {
            this.downloadZip(true, false)
          }
        })
        return
      }

      if (
        ((this.downloadTotalSize >= 52428800 && this.isMobile) ||
          (this.downloadTotalSize >= 1048576000 && !this.isMobile)) &&
        confirmBigSize === false
      ) {
        let size = this.$options.filters.bytesToString(this.downloadTotalSize)
        let message = 'O ficheiro que vai descarregar tem ' + size + '.'
        if (this.isMobile) {
          message += ' Assegure-se que está ligado a uma rede wifi com boa ligação.'
        } else {
          message += ' Assegure-se que tem espaço suficiente em disco.'
        }
        let title = 'Aviso: ficheiro grande'
        if (this.isMobile) {
          title = 'Aviso: consumo de dados móveis'
        }
        this.$buefy.dialog.confirm({
          title: title,
          message: message,
          confirmText: 'Descarregar',
          cancelText: 'Cancelar',
          onConfirm: () => {
            this.downloadZip(true, true)
          }
        })
        return
      }

      let directDownload = false
      let file = null
      let fileKey = null

      if (this.selectedItems.size === 1) {
        let key = Array.from(this.selectedItems)[0]
        for (let i = 0; i < this.items.length; i++) {
          let item = this.items[i]
          if (item.key === key && item['type'] !== 'folder' && item['download_lock'] !== true) {
            directDownload = true
            fileKey = key
            file = item['file']
            break
          }
        }
      }
      if (directDownload === false) {
        //download selected files
        let response = await ServiceBuckets.createZipFolder(this.bucket.key, Array.from(this.selectedItems))
        /*
        filename: "Bibliografia - 3p85rcn1.zip"
        key: "3p85rcn1-3357fe35aea41887844a07bafa88b833"
        token: "8bcd0f866acc2cba6d5801b3060455898c1c464afae2e6612b3a5e289a8fd081"
        type: "file"
        url_format: "https://storage.fw.dev.ucframework.pt/z/buckets/{TOKEN}/{KEY}/{FILENAME}"
        */

        //Mark selected as read locally
        this.markSelectedAsRead()

        let zip_url = response.url_format
          .replace('{TOKEN}', response.token)
          .replace('{KEY}', response.key)
          .replace('{FILENAME}', response.filename)
        window.open(zip_url, '_blank').focus()
      } else if (file !== null && fileKey !== null) {
        this.downloadFile(fileKey, file)
      }
    },
    isPDF(metadata) {
      return metadata && metadata.mimetype === 'application/pdf'
    },
    playerReady(player) {
      //Deactivate download and right click on the player
      player.el_.childNodes[0].setAttributeNS(null, 'controlsList', 'nodownload')
      player.el_.childNodes[0].setAttribute('oncontextmenu', 'return false;')
    },
    async preview(item) {
      this.sidePreviewItem = item
      /*if (item.unread && item.type !== 'folder') {
        console.log('mark as read')
        //ServiceBuckets.markFileAsRead(this.bucket.key, [item.key], 'preview')
        //item.unread = false
      }*/
    },
    previewPage(item) {
      this.$router.push({ name: 'content-pages-editor', params: { key: item.document_key } })
    },
    markSelectedAsRead() {
      for (let i = 0; i < this.items.length; i++) {
        if (this.selectedItems.has(this.items[i].key)) {
          this.items[i].unread = false
        }
      }
    },
    copyToRepoItem(file) {
      this.setSelected(file.key, true, false)
      this.copyToRepoModal = !this.copyToRepoModal
    },
    toggleCopyToRepo() {
      this.copyToRepoModal = !this.copyToRepoModal
    },
    editBucketTitle() {
      if (!this.canEdit || this.bucket.type == 'unit_edition' || this.bucket.type == 'unit') return
      this.$buefy.dialog.prompt({
        title: 'Título do repositório',
        inputAttrs: {
          type: 'text',
          placeholder: 'e.g. O meu repositório',
          maxlength: 150,
          min: 2,
          value: this.bucket.title
        },
        confirmText: 'Alterar',
        cancelText: 'Cancelar',
        trapFocus: true,
        onConfirm: async value => {
          this.bucket.title = value
          this.doSaveBucket(['title'])
        }
      })
    },
    goToBucket(key = null) {
      if (!key) {
        this.$router.push({ name: 'buckets' })
      } else {
        this.view = 'bucket'
        if (this.folder) this.goToFolder(null)
      }
    },
    goToFolder(folderKey = null) {
      this.allSelected = false
      /* const routeData = { params: { key: this.bucket.key } }
      if (!this.folderKey) {
        routeData.name = 'bucket'
      } else {
        routeData.name = 'bucket-folder'
        routeData.params.folderKey = this.folderKey
      }*/

      //get current route
      let routeData = this.$router.currentRoute
      console.log(routeData.path + '?folderKey=' + folderKey)
      if (folderKey == null) {
        this.$router.push(routeData.path)
      } else {
        this.$router.push(routeData.path + '?folderKey=' + folderKey)
      }
    },
    bucketDeleteCallback() {
      this.$router.push({ name: 'buckets' })
    },
    async getBucket() {
      let response
      try {
        response = await ServiceBuckets.getBucket(this.bucketKey, this.folderKey, true)
      } catch (error) {
        const errorKey = utils.errors(error).getFieldKey('folder_key')
        if (errorKey == 'InvalidKey') {
          console.warn(`Bucket ${this.bucketKey} folder ${this.folderKey} not found, going to root`)
          this.goToFolder()
          return null
        } else {
          throw error
        }
      }

      // load read files
      const readFiles = await ServiceBuckets.loadReadFiles(this.bucketKey)
      this.readFiles = new Set(readFiles)
      this.bucket = response.bucket
      this.folder = response.folder
      this.items = response.items
      this.breadcrumbs = response.breadcrumbs
      this.bucketSubscription = ServiceBuckets.createBucketSubscription(
        this.bucket,
        this.folder,
        this.items,
        this.bucketDeleteCallback,
        this.goToFolder,
        this.addItems,
        this.removeItems,
        !response.subscribed
      )

      // Setup import resources from NONIO
      if (this.bucket.type === 'class_edition') {
        this.nonioImportClassEditionKey = this.bucket.type_key
      }
      if (this.appName === 'ucteacher') {
        this.$store.commit('subscribeWS', {
          code: 'notifications',
          name: 'PanelBucket',
          callback: this.setWSNotificationsMessages
        })
      }
      // Set view & order
      if (!this.listViewLoaded) {
        this.listViewLoaded = true
        let data = await ServiceSettings.getSetting(`bucket-${this.bucket.key}-view-settings`)
        if (data && data !== null) {
          let bucketSettings = JSON.parse(data.value)
          //console.log(bucketSettings)
          //{"view_type":"row","view_order_type":"created_date","view_order_direction":"DESC"}
          if (bucketSettings && bucketSettings['view_type']) {
            const viewType = bucketSettings['view_type']
            if (viewType && ['row', 'card', 'row_expanded'].includes(viewType)) {
              this.listViewKey = viewType
            }
          }

          if (bucketSettings && bucketSettings['view_order_type'] && bucketSettings['view_order_direction']) {
            const viewOrderType = bucketSettings['view_order_type']
            const viewOrderDirection = bucketSettings['view_order_direction']
            if (viewOrderType && ['title', 'created_date'].includes(viewOrderType)) {
              this.orderItemsBy = viewOrderType
            }
            if (viewOrderDirection && ['ASC', 'DESC'].includes(viewOrderDirection)) {
              this.orderDirection = viewOrderDirection
            }
          }
        }
      }
    },
    isFileRead() {
      return true //FEATURE DEACTIVATED: this.readFiles.has(fileKey)
    },
    async setWSNotificationsMessages(messages) {
      if (this.nonioImportClassEditionKey && messages.newNotification) {
        for (let message of messages.newNotification) {
          if (
            //message.item_type == 'class_edition' &&
            this.nonioImportClassEditionKey == message.item_key &&
            message.short_data &&
            message.short_data.code === 'nonio-import'
          ) {
            const importKey = message.short_data.key
            for (let edition of this.nonioEditions) {
              let done
              for (let idx in edition.importing) {
                if (edition.importing[idx].key == importKey) {
                  edition.importing.splice(idx, 1)
                  done = true
                  break
                }
              }
              if (done) {
                break
              }
            }
          }
        }
      }
    },

    goToPrevious() {
      if (!this.folder) {
        this.goToBucket()
      } else if (this.folder.folder_key) {
        this.goToFolder(this.folder.folder_key)
      } else {
        this.goToFolder(null)
      }
    },

    addItems(items) {
      this.items.push(...items)
      ServiceBuckets.sortBucketItems(this.items)
    },
    removeItems(itemsKeys) {
      const deleteIdx = []
      for (let idx in this.items) {
        const key = this.items[idx].key
        if (itemsKeys.includes(key)) {
          this.setSelected(key, false)
          deleteIdx.push(idx)
        }
      }

      if (deleteIdx.length) {
        deleteIdx.sort().reverse()
        for (let idx of deleteIdx) {
          this.items.splice(idx, 1)
        }
        ServiceBuckets.sortBucketItems(this.items)
      }
    },

    async createFolder() {
      this.$buefy.dialog.prompt({
        title: this.$t('new_folder'),
        message: this.$t('new_folder_message'),
        inputAttrs: {
          type: 'text',
          placeholder: this.$t('example_folder_name'),
          maxlength: 150,
          min: 2
        },
        confirmText: this.$t('create'),
        cancelText: this.$t('cancel'),
        trapFocus: true,
        onConfirm: async value => {
          this.newFolderTitle = value
          const data = { folder_title: this.newFolderTitle }
          if (this.folder) data.folder_key = this.folder.key

          const response = await ServiceBuckets.addItemsToBucket(this.bucket.key, data)
          if (response.new_items) this.addItems(response.new_items)
        }
      })
    },

    createNewPage() {
      this.showNewDocumentModal = true
    },

    closeNewDocument() {
      this.showNewDocumentModal = false
    },

    async addFiles(files) {
      for (const file of files) {
        if (file.response.data) {
          const data = { files: [file.response.data.file.key] }
          if (file.context.folder) data.folder_key = file.context.folder

          const response = await ServiceBuckets.addItemsToBucket(file.context.bucket, data)
          if (file.context.bucket == this.bucket.key && file.context.folder == this.folderKey && response.new_items) {
            this.addItems(response.new_items)
            this.showFullDragZoneUploader = false
          }
        }
      }
    },

    deleteItem(item) {
      if (this.canDeleteItems) {
        this.$buefy.dialog.confirm({
          title: this.$t('delete_file'),
          message: this.$t('delete_file_message'),
          confirmText: this.$t('delete'),
          type: 'is-danger',
          onConfirm: async () => {
            const keys = [item.key]
            await ServiceBuckets.deleteItemsFromBucket(this.bucket.key, keys)
            this.removeItems(keys)
          }
        })
      }
    },
    deleteSelectedItems() {
      if (this.canDeleteItems && this.selectedItems.size >= 1) {
        const keys = [...this.selectedItems]
        this.$buefy.dialog.confirm({
          title: this.$t('delete_selected_files'),
          message: this.$t('delete_files_message'),
          confirmText: this.$t('delete'),
          type: 'is-danger',
          onConfirm: async () => {
            await ServiceBuckets.deleteItemsFromBucket(this.bucket.key, keys)
            this.removeItems(keys)
          }
        })
      }
    },

    async doSaveBucket(only = []) {
      let isValid = true
      let bucket = this.bucket
      let requestData = {}
      //we just validate the defined keys, the others we ignore
      let validations = {
        title: {
          type: 'string',
          min_length: 1
        },
        lock_download: {
          type: 'boolean'
        }
      }

      if (only.length > 0) {
        for (let i = 0; i < only.length; i++) {
          let value = bucket[only[i]]
          if (validations[only[i]]) {
            if (
              validations[only[i]]['min_length'] &&
              typeof value === validations[only[i]]['type'] &&
              value.length >= validations[only[i]]['min_length']
            ) {
              //valid
              requestData[only[i]] = value
            } else if (typeof value === validations[only[i]]['type']) {
              //valid
              requestData[only[i]] = value
            } else {
              //not valid
              isValid = false
            }
          } else {
            requestData[only[i]] = value
          }
        }
      } else {
        let bucket_keys = Object.keys(bucket)
        requestData = bucket
        for (let i = 0; i < bucket_keys.length; i++) {
          if (validations[bucket_keys[i]]) {
            let value = bucket[bucket_keys[i]]
            if (
              validations[bucket_keys[i]]['min_length'] &&
              typeof value === validations[bucket_keys[i]]['type'] &&
              value.length >= validations[bucket_keys[i]]['min_length']
            ) {
              //valid
            } else if (typeof value === validations[bucket_keys[i]]['type']) {
              //valid
            } else {
              //not valid
              isValid = false
            }
          }
        }
      }

      if (isValid) {
        console.log('saving bucket')
        this.loading = true

        if (!this.bucket.title && (only.length === 0 || only.includes('title'))) requestData.title = 'Bucket sem título'

        try {
          const bucket = await ServiceBuckets.updateBucket(this.bucket.key, requestData)
          Object.assign(this.bucket, bucket)
          this.$buefy.snackbar.open({
            message: this.$t('changes_saved'),
            position: 'is-top-right'
          })
        } finally {
          this.loading = false
        }
      } else {
        console.log('bucket data not valid')
      }
    },
    doDelete() {
      this.$buefy.dialog.confirm({
        confirmText: 'Eliminar',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Eliminar bucket',
        message: `
          <div class="has-margin-bottom-small">Tem a certeza que deseja eliminar este bucket?</div>
          <div class="has-text-small has-text-weight-bold">Todos os ficheiros serão eliminados.</div>`,
        onConfirm: async () => {
          try {
            await ServiceBuckets.deleteBucket(this.bucket.key)
            this.$buefy.snackbar.open({
              message: 'O bucket foi eliminado.',
              position: 'is-top-right',
              indefinite: false,
              duration: 4000,
              queue: false
            })
            this.goToBucket()
          } catch (error) {
            const bucketKey = this.bucket ? this.bucket.key : null
            console.error(`Removing bucket ${bucketKey}`, error)
            this.$buefy.snackbar.open({
              message: 'Ocorreu um erro a eliminar o bucket.',
              type: 'is-warning',
              position: 'is-top-right',
              indefinite: true,
              duration: 2000,
              queue: false
            })
          }
        }
      })
    },

    async toggleNonioImport() {
      if (this.showNonioImport) {
        this.hideNonioFiles()
        this.showNonioUnitClassesSelector = false
        this.showNonioImport = false
        this.nonioImportLoaded = false
        this.nonioEditions = []
      } else {
        this.showNonioImport = true
        if (this.nonioImportClassEditionKey === null) {
          this.showNonioUnitsClasses()
          setTimeout(() => {
            this.nonioImportLoaded = true
          }, 1000)
        } else {
          this.nonioImportLoaded = false
          this.showNonioUnitClassesSelector = false
          this.nonioEditions = await ServiceAcademic.getTeacherClassNonioEditionsByKey(this.nonioImportClassEditionKey)
          setTimeout(() => {
            this.nonioImportLoaded = true
          }, 1000)
        }
      }
    },
    async toggleNonioFiles(nonioId) {
      if (nonioId == this.showNonioFilesId) {
        this.hideNonioFiles()
      } else {
        this.hideNonioFiles()
        this.nonioFilesLoaded = false
        this.showNonioFilesId = nonioId
        this.nonioFiles = await ServiceAcademic.getTeacherClassNonioFiles(
          this.nonioImportClassEditionKey,
          nonioId,
          this.bucket.key
        )
        this.nonioFilesLoaded = true
      }
    },
    hideNonioFiles() {
      this.showNonioFilesId = null
      this.nonioFilesLoaded = false
      this.nonioFiles = []
      this.nonioImportFileIds = []
    },
    async showNonioUnitsClasses() {
      this.showNonioUnitClassesSelector = true
      if (this.nonioUnitClassesOptions.length === 0) {
        this.nonioLoadingUnitClasses = true
        let teacherUnits = await ServiceAcademic.getTeacherUnits(this.selectedAcademicYear, true)
        this.nonioLoadingUnitClasses = false
        this.nonioUnitClassesOptions = teacherUnits.units
        this.nonioAcademicYears = teacherUnits.academic_years
        this.nonioSelectedAcademicYear = teacherUnits.academic_year
      }
    },
    async loadNonioUnitsClasses() {
      this.nonioLoadingUnitClasses = true
      let teacherUnits = await ServiceAcademic.getTeacherUnits(this.selectedAcademicYear, true)
      this.nonioUnitClassesOptions = teacherUnits.units
      this.nonioLoadingUnitClasses = false
    },
    async selectNonioAcademicYear(academic_year) {
      this.nonioSelectedAcademicYear = academic_year
      this.loadNonioUnitsClasses()
    },
    async selectNonioClass(classe, unitTitle) {
      //console.log('selecting', classe)
      this.nonioUnitName = unitTitle
      this.showNonioUnitClassesSelector = false
      this.nonioImportLoaded = false
      this.nonioImportClassEditionKey = classe.key
      this.selectedNonioClass = classe
      this.nonioEditions = await ServiceAcademic.getTeacherClassNonioEditionsByKey(this.nonioImportClassEditionKey)
      setTimeout(() => {
        this.nonioImportLoaded = true
      }, 1000)
    },
    async importNonioFiles(edition) {
      if (this.importNonioFilesLoading || !this.nonioImportFileIds) return
      this.importNonioFilesLoading = true

      try {
        const data = await ServiceAcademic.importTeacherClassNonioFiles(
          this.nonioImportClassEditionKey,
          edition.nonio_id,
          this.bucket.key,
          this.folder ? this.folder.key : null,
          this.nonioImportFileIds
        )
        edition.importing.push(data)
        this.nonioImportFileIds = []
      } finally {
        this.importNonioFilesLoading = false
      }
    },
    // Handle uploads or move element to a folder
    filesOnDragOver(event) {
      //console.log('filesOnDragOver', event)
      event.preventDefault()

      // Deal with drag new files into the window (for upload) or elements already
      // on the bucket (which we want to move inside a folder)
      if (
        !this.dragActiveItem &&
        !this.showFullDragZoneUploader &&
        event.dataTransfer.types &&
        event.dataTransfer.types.includes('Files')
      ) {
        this.showFullDragZoneUploader = true
      }
    },

    filesOnDragLeave() {
      //console.log('filesOnDragLeave')
      this.showFullDragZoneUploader = false
    },

    styleItemToDrop(el, status) {
      if (el.firstChild.classList.contains('fw-card-file')) {
        this.$nextTick(() => {
          // Style folder icons
          var iconFolderOpened = el.getElementsByClassName('icon-folder-open-solid')
          var iconFolderClosed = el.getElementsByClassName('icon-folder-solid')
          if (iconFolderOpened.length) iconFolderOpened = iconFolderOpened[0]
          if (iconFolderClosed.length) iconFolderClosed = iconFolderClosed[0]

          if (status == 'opened') {
            el.classList.add('bg-teal-100', 'animate-pulse')
            if (iconFolderOpened) iconFolderOpened.classList.remove('hidden')
            if (iconFolderClosed) iconFolderClosed.classList.add('hidden')
          } else {
            el.classList.remove('bg-teal-100', 'animate-pulse')
            if (iconFolderOpened) iconFolderOpened.classList.add('hidden')
            if (iconFolderClosed) iconFolderClosed.classList.remove('hidden')
          }
        })
      }
    },

    dragEnter(item) {
      //console.log('dragEnter', item)
      if (!item.dragCount) item.dragCount = 1
      else item.dragCount += 1

      if (item.dragCount) {
        const el = document.getElementById('item-' + item.key)
        if (el) this.styleItemToDrop(el, 'opened')
      }
    },
    dragLeave(item) {
      //console.log('dragLeave', item)
      if (!item.dragCount) item.dragCount = 0
      else item.dragCount -= 1

      if (!item.dragCount) {
        const el = document.getElementById('item-' + item.key)
        if (el) this.styleItemToDrop(el, 'closed')
      }
      //if (item.dragCount == 0) {
      this.showFullDragZoneUploader = false
      //}
    },
    clearDragActive(key) {
      //console.log('clearDragActive', key)
      const el = document.getElementById('item-' + key)
      if (el) this.styleItemToDrop(el, 'closed')
    },
    eventPreventDefault(event) {
      event.preventDefault()
    },
    dragItemStart(item, event) {
      //console.log('dragItemStart', item, event)
      this.dragActiveItem = item
      event.dataTransfer.setData('text', event.target.id)
      event.dataTransfer.effectAllowed = 'move'
    },
    dragItemEnter(item) {
      //console.log('dragItemEnter', item)
      if (item.type == 'folder') {
        this.dragEnter(item)
      }
    },
    dragItemLeave(item) {
      //console.log('dragItemLeave', item)
      if (item.type == 'folder') {
        this.dragLeave(item)
      }
    },
    dragItemEnd(event) {
      //console.log('dragItemEnd')
      event.preventDefault()
      this.dragActiveItem = null
    },
    dropToItem(item) {
      //console.log('dropToItem', item, event)
      if (item.type == 'folder') {
        this.dropToFolder(item.key)
      }
    },

    async dropToFolder(folderKey) {
      //console.log('dropToFolder', folderKey)
      this.clearDragActive(folderKey)
      if (this.dragActiveItem && this.bucket) {
        if (folderKey == this.bucketDragInfo.key) folderKey = null
        const itemsKeys = await ServiceBuckets.moveBucketItem(this.bucket.key, folderKey, [this.dragActiveItem.key])
        this.removeItems(itemsKeys)
      }

      this.dragActiveItem = null
    }
  }
}
</script>
<style scoped>
.autoScrollActive {
  overflow-y: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.darkScrollArea {
  background-color: #efefef;
}
</style>

<i18n>
  {
    "pt": {
      "no_title_bucket": "Bucket sem título",
      "general": "GERAL",
      "bucket": "Bucket",
      "folder": "Pasta",
      "created_date": "Data de criação",
      "title": "Título",
      "size": "Tamanho",
      "type": "Tipo",
      "created_by": "Criado por",
      "created_at": "Criado em",
      "modified_at": "Modificado em",
      "modified_by": "Modificado por",
      "no_files": "Sem ficheiros",
      "no_folders": "Sem pastas",
      "no_files_folders": "Sem ficheiros ou pastas",
      "delete": "Eliminar",
      "delete_selected": "Eliminar selecionados",
      "semester": "Semestre",
      "bucket_id": "ID do repositório",
      "subject_bucket": "Repositório de disciplina",
      "subject": "Disciplina",
      "total_size": "Tamanho total",
      "resources": "Recursos",
      "more": "Mais",
      "type": "Tipo",
      "shared": "Partilhado",
      "creation_date": "Data de criação",
      "updated_date": "Data de atualização",
      "close": "Fechar",
      "select_all": "Selecionar tudo",
      "name": "Nome",
      "size": "Tamanho",
      "order_by": "Ordenar por",
      "date": "Data",
      "new_page": "Nova página",
      "new_folder": "Nova pasta",
      "upload": "Carregar",
      "delete": "Eliminar",
      "move": "Mover",
      "copy_to": "Copiar para",
      "mark_as_read": "Marcar como lido",
      "new_folder_message": "Indique o nome da nova pasta que pretende criar.",
      "example_folder_name": "'e.g. A minha pasta'",
      "create": "Criar",
      "cancel": "Cancelar",
      "delete_file": "Eliminar ficheiro",
      "delete_file_message": "Tem a certeza que deseja eliminar este ficheiro?",
      "delete_selected_files": "Eliminar ficheiros selecionados",
      "delete_files_message": "Tem a certeza que deseja eliminar os ficheiros selecionados?",
      "delete_folder": "Eliminar pasta",
      "delete_folder_message": "Tem a certeza que deseja eliminar esta pasta?",
      "delete_selected_folders": "Eliminar pastas selecionadas",
      "changes_saved": "Alterações guardadas",
      "metadata": "Metadados",
      "folder_name": "Nome da pasta",
      "file_name": "Nome do ficheiro",
      "not_defined": "Não definido.",
      "file_size": "Tamanho do ficheiro",
      "image_dimensions": "Dimensões da imagem",
      "upload_date": "Data de envio",
      "dcmi_metadata": "Metadados conforme a norma Dublin Core (DCMI)",
      "open_editor": "Abrir no editor",
      "content_page": "Página de conteúdos",
      "empty_folder": "Pasta vazia",
      "preview": "Pré-visualizar",
      "empty_folder_title": "Pasta vazia",
      "empty_folder_message": "Para adicionar novos conteúdos, arraste ficheiros para esta área."
    },
    "en": {
     "no_title_bucket": "Untitled bucket",
      "general": "GENERAL",
      "bucket": "Bucket",
      "folder": "Folder",
      "created_date": "Created date",
      "title": "Title",
      "size": "Size",
      "type": "Type",
      "created_by": "Created by",
      "created_at": "Created at",
      "modified_at": "Modified at",
      "modified_by": "Modified by",
      "no_files": "No files",
      "no_folders": "No folders",
      "no_files_folders": "No files or folders",
      "delete": "Delete",
      "delete_selected": "Delete selected",
      "semester": "Semester",
      "bucket_id": "Bucket ID",
      "subject_bucket": "Subject bucket",
      "subject": "Subject",
      "total_size": "Total size",
      "resources": "Resources",
      "more": "More",
      "type": "Type",
      "shared": "Shared",
      "creation_date": "Creation date",
      "updated_date": "Updated date",
      "close": "Close",
      "select_all": "Select all",
      "name": "Name",
      "size": "Size",
      "order_by": "Order by",
      "date": "Date",
      "new_page": "New page",
      "new_folder": "New folder",
      "upload": "Upload",
      "delete": "Delete",
      "move": "Move",
      "copy_to": "Copy to",
      "mark_as_read": "Mark as read",
      "new_folder_message": "Enter the name of the new folder you want to create.",
      "example_folder_name": "'e.g. My folder'",
      "create": "Create",
      "cancel": "Cancel",
      "delete_file": "Delete file",
      "delete_file_message": "Are you sure you want to delete this file?",
      "delete_selected_files": "Delete selected files",
      "delete_files_message": "Are you sure you want to delete the selected files?",
      "delete_folder": "Delete folder",
      "delete_folder_message": "Are you sure you want to delete this folder?",
      "delete_selected_folders": "Delete selected folders",
      "changes_saved": "Changes saved",
      "metadata": "Metadata",
      "folder_name": "Folder name",
      "file_name": "File name",
      "not_defined": "Not defined.",
      "file_size": "File size",
      "image_dimensions": "Image dimensions",
      "upload_date": "Upload date",
      "dcmi_metadata": "Metadata according to the Dublin Core (DCMI) standard",
      "open_editor": "Open in editor",
      "content_page": "Content page",
      "empty_folder": "Empty folder",
      "preview": "Preview",
      "empty_folder_title": "Empty folder",
      "empty_folder_message": "To add new content, drag files to this area."
    }
  }
</i18n>
