<template>
  <div
    class="items-center flex flex-shrink-0 font-medium"
    :class="[
      {
        'bg-primary text-white': type === 'primary',
        'bg-primary bg-opacity-20 text-primary': type === 'light-primary',
        'bg-gray-500 bg-opacity-10 text-gray-500': type === 'light-gray',
        'bg-yellow-600 bg-opacity-20 text-yellow-600': type === 'light-orange',
        'bg-red-600 bg-opacity-20 text-red-600': type === 'light-danger',
        'bg-cyan-600 bg-opacity-20 text-cyan-600': type === 'light-blue',
        'border border-yellow-600 text-yellow-600': type === 'border-warning',
        'border border-primary text-primary': type === 'border-primary',
        'border border-primary border-opacity-40 text-primary': type === 'border-light-primary',
        'border border-gray-500 text-gray-500': type === 'border-light',
        'border border-opacity-40 border-gray-800 text-gray-500 px-1.5 py-0.5 rounded-lg': type === 'light-border-box',
        'border border-opacity-40 border-primary text-primary px-1.5 py-0.5 rounded-lg':
          type === 'light-primary-border-box',
        'border border-opacity-90 border-primary text-primary px-1.5 py-0.5 rounded-lg': type === 'primary-border-box',
        'border border-opacity-90 border-red-600 text-red-600 px-1.5 py-0.5 rounded-lg': type === 'danger-border-box',
        'border border-opacity-40 border-white text-white px-1.5 py-0.5 rounded-lg': type === 'light-white-border-box',
        'bg-yellow-500 text-white': type === 'orange',
        'bg-red-600 text-white': type === 'danger',
        'bg-gray-500 text-white': type === 'medium',
        'bg-gray-500 bg-opacity-50 text-white': type === 'light',
        'bg-white text-gray-800': type === 'white',
        'text-white bg-gray-800 bg-opacity-20 backdrop-blur-sm': type === 'dark-blur',
        'text-gray-800 bg-white bg-opacity-40 backdrop-blur-sm': type === 'white-blur',
        'text-primary bg-white bg-opacity-20 backdrop-blur-sm': type === 'primary-blur',
        'bg-gray-100 text-gray-500': type === 'xlight',
        'bg-gray-800 text-white': type === 'dark',
        'border text-gray-800 border-gray-800': type === 'bordered',
        'rounded-full':
          rounded &&
          !['primary-border-box', 'light-border-box', 'light-primary-border-box', 'danger-border-box'].includes(type),
        'rounded-md': !rounded,
        'min-h-7': size !== 'xs',
        'min-h-5': size === 'xs',
        'justify-center': align === 'center',
        'w-full': expanded,
        'inline-flex': !expanded,
        'px-3 py-1': ![
          'primary-border-box',
          'light-border-box',
          'light-primary-border-box',
          'danger-border-box'
        ].includes(type)
      },
      `text-${size}`,
      `text-${align}`,
      customClass
    ]"
  >
    <div class="items-center flex flex-shrink-0" :class="{ 'flex-1': expanded && (counter || forceCounter) }">
      <slot />
    </div>
    <div v-if="counter || forceCounter" class="ml-2 text-xs font-medium pl-1.5 min-w-10 text-center">{{ counter }}</div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'medium'
    },
    size: {
      type: String,
      default: 'xs'
    },
    rounded: {
      type: Boolean,
      default: true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    forceCounter: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'center'
    },
    counter: {
      type: [String, Number]
    },
    customClass: String
  }
}
</script>
