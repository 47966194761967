import ViewBuckets from './views/ViewBuckets'
import ViewBucketAdd from './views/ViewBucketAdd'
import ViewBucket from './views/ViewBucket'
import PDFViewer from './views/PDFviewer'

export default [
  {
    path: '/buckets',
    name: 'buckets',
    component: ViewBuckets
  },
  {
    path: '/buckets/:filter',
    name: 'buckets_filtered',
    component: ViewBuckets
  },
  {
    path: '/bucket/new',
    name: 'new-bucket',
    component: ViewBucketAdd
  },
  {
    path: '/bucket/:key',
    name: 'bucket',
    component: ViewBucket
  },
  {
    path: '/bucket/:key/:folderKey',
    name: 'bucket-folder',
    component: ViewBucket
  },
  {
    path: '/view/:key/:token/:filename',
    name: 'pdf-viewer',
    component: PDFViewer
  }
]
