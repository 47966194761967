<template>
  <div class="flex flex-col gap-5 rounded-lg overflow-hidden">
    <div
      class="h-48 flex flex-col gap-4 items-center justify-center"
      :class="{
        'bg-white': !isModal,
        'bg-gray-100': isModal,
      }"
    >
      <AppLogo />
      <div class="flex gap-3 text-lg font-bold opacity-70">
        <div>Translate</div>
        <div>Review</div>
        <div>Share</div>
        <div>Disseminate</div>
      </div>
    </div>
    <div class="px-5">
      <div class="text-xl font-bold mb-2">About Mondaecus</div>
      Mondaecus aims to eliminate linguistic obstacles in a diverse scholarly communication context and to enhance
      multilingualism as an asset, rather than a limitation, for the advancement of science and the transfer of
      knowledge. By means of collaborative translation, Mondaecus introduces a human dimension to machine translation
      and sensitivity to different linguistic contexts. The platform provides a multilingual online network for the
      exchange of ideas across disciplines, thereby fostering collaboration and communication. Mondaecus aims to
      eliminate linguistic obstacles in a diverse scholarly communication context and to enhance multilingualism as an
      asset, rather than a limitation, for the advancement of science and the transfer of knowledge.
    </div>
    <div class="px-5">
      <div class="text-xl font-bold mb-2">Vision</div>
      <ul class="list-disc list-inside">
        <li>Openness to all communities during the learning stages</li>
        <li>Epistemological discussion and assessment not limited by linguistic barriers</li>
        <li>Full access to knowledge produced in diverse linguistic contexts</li>
        <li>Promotion of equality and diversity</li>
      </ul>
    </div>
    <div class="px-5">
      <div class="text-xl font-bold mb-2">Available features (alpha version)</div>
      <ul class="list-disc list-inside">
        <li>Communities based Experience</li>
        <li>Document Components Editor</li>
        <li>AI Helper Translation Service</li>
      </ul>
    </div>
    <div class="p-5">
      Should you wish to make any suggestions for improvements or report any bugs, please contact:
      <a class="text-primary hover:opacity-60" href="mailto:operas-pt@uc.pt">operas-pt@uc.pt</a>
    </div>
    <div v-if="isModal" class="px-5 pb-5">
      <fw-button type="light" expanded @click.native="$emit('close')">Close</fw-button>
    </div>
  </div>
</template>

<script>
import AppLogo from '@/components/AppLogo'
export default {
  components: {
    AppLogo,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
